import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
function UserManagement_Edit({ API_USER, API, ...props }: any) {
  const [isSiretValid, setIsSiretValid] = React.useState(true);

  const handleSiretChange = (event: any) => {
    formik.handleChange(event);
    setIsSiretValid(true);
  };

  //-------- Add User Popup ---------
  const [OpenEditUserPopup, setOpenEditUserPopup] =
    React.useState<boolean>(false);

  const phoneReg =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  //formik
  const formikSchema = yup.object({
    name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `Prenom invalide`)
      .min(2, `Prenom trop court !`)
      .max(50, `Prenom trop long !`)
      .required(`Veuillez renseigner votre prenom`),
    family_name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `Nom invalide`)
      .min(2, `Nom trop court !`)
      .max(50, `Nom trop long !`)
      .required(`Veuillez renseigner votre nom`),
    fonction: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `fonction invalide`)
      .min(2, `fonction trop courte !`)
      .max(100, `fonction trop longue !`)
      .required(`Veuillez renseigner votre fonction`),
    phone: yup
      .string()
      .matches(phoneReg, `Numéro invalide`)
      .required(`Veuillez renseigner votre numéro de téléphone`),
    siret: yup
      .string()
      .matches(/^\d{14}$/, `Siret invalide`)
      .required(`Veuillez renseigner votre siret`),
  });
  const ThisUser = props.localData?.find(
    (user: any) => user.user_id === props?.rowInfos?.row.user_id
  );
  const formik = useFormik({
    initialValues: {
      name: ThisUser?.name || ``,
      family_name: ThisUser?.family_name || ``,
      fonction: ThisUser?.fonction || ``,
      phone: ThisUser?.phone || ``,
      siret: ThisUser?.etablissement?.siret || ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      API.GET_PROBYSIRET(formValues.siret).then((res: any) => {
        if (res) {
          API_USER.PUT_USER(props.rowInfos.row.user_id, {
            name: formValues.name,
            family_name: formValues.family_name,
            fonction: formValues.fonction,
            phone: formValues.phone,
            etablissement: res.etablissement,
          }).then((res: any) => {
            if (res) {
              props.setLocalData(
                props.localData.map((user: any) =>
                  user.user_id === props?.rowInfos?.row.user_id
                    ? res.data
                    : user
                )
              );
              // props.setLocalData(props.localData.concat(res.data.user));
              handleAddUserPopupClose();
              resetForm();
            }
          });
        } else {
          setIsSiretValid(false);
          return;
        }
      });
    },
  });

  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenEditUserPopup(false);
  };

  return (
    //header de user management
    <Box>
      <GridActionsCellItem
        onClick={() => setOpenEditUserPopup(true)}
        icon={<EditIcon />}
        label='Edit'
      />
      <Dialog open={OpenEditUserPopup}>
        <Box component='form' onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Modifier un utilisateur
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `calc(100% - 40px)`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
            <TextField
              margin='dense'
              fullWidth
              id='name'
              label='Prenom'
              name='name'
              autoComplete='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name?.toString()}
            />
            <TextField
              margin='dense'
              fullWidth
              id='family_name'
              label='Nom'
              name='family_name'
              autoComplete='family_name'
              value={formik.values.family_name}
              onChange={formik.handleChange}
              error={
                formik.touched.family_name && Boolean(formik.errors.family_name)
              }
              helperText={
                formik.touched.family_name &&
                formik.errors.family_name?.toString()
              }
            />
            <TextField
              margin='dense'
              fullWidth
              id='fonction'
              label='Fonction'
              name='fonction'
              autoComplete='fonction'
              value={formik.values.fonction}
              onChange={formik.handleChange}
              error={formik.touched.fonction && Boolean(formik.errors.fonction)}
              helperText={
                formik.touched.fonction && formik.errors.fonction?.toString()
              }
            />
            <TextField
              margin='dense'
              fullWidth
              id='phone'
              label='Téléphone'
              name='phone'
              autoComplete='phone'
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={
                formik.touched.phone && formik.errors.phone?.toString()
              }
            />
            <TextField
              margin='dense'
              fullWidth
              id='siret'
              label='Siret Entreprise (sans espaces)'
              name='siret'
              autoComplete='siret'
              value={formik.values.siret}
              onChange={handleSiretChange}
              error={
                (formik.touched.siret && Boolean(formik.errors.siret)) ||
                !isSiretValid
              }
              helperText={
                (formik.touched.siret && formik.errors.siret?.toString()) ||
                (!isSiretValid && "Aucune entreprise trouvée avec ce siret")
              }
            />
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
            <Button
              type='submit'
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default UserManagement_Edit;
