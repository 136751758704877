import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogTitle } from "@mui/material";
export const DialogHeader = ({ headerText, onClose }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: { xs: "center", md: "space-between" },
        alignItems: "center",
        flexWrap: "wrap",
        margin: { xs: "2px 5px", sm: "2px 10px" },
      }}
    >
      <DialogTitle sx={{ fontSize: { xs: "15px", sm: "23px", md: "30px" } }}>
        {headerText}
      </DialogTitle>
      <CloseIcon
        sx={{
          fontSize: { xs: "18px", sm: "26px", md: "30px" },
          cursor: "pointer",
          ":hover": {
            color: "grey",
          },
        }}
        onClick={onClose}
      />
    </Box>
  );
};
