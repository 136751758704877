import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
  Link,
  Button,
  Grid,
} from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import BusinessIcon from "@mui/icons-material/Business";
import { ProjectTypeDialog } from "./ProjectTypeDialog";
import { ProjectInfosDialog } from "./ProjectInfosDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteProjectDialog } from "./DeleteProjectDialog";
import OmbriereAvec from "../../assets/types/ombriere_avec_fondation.jpg";
import OmbriereSans from "../../assets/types/ombriere_sans_fondation.png";
import ToitureSolaire from "../../assets/types/toiture_solaire.jpg";

import AddIcon from "@mui/icons-material/Add";
import { ProgressBar } from "./ProgressBar";

export const DisplayProjects = ({
  projects,
  setProjects,
  seller,
  readyFirstProj,
  selectedUser,
  headerText,
  idUser = null,
  role,
}: any) => {
  const [displayPopUpTypes, setDisplayPopUpTypes] = React.useState(false);
  const [displayPopUpInfos, setDisplayPopUpInfos] = React.useState(false);
  const [displayPopUpDelete, setDisplayPopUpDelete] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState({});
  const [infos, setInfos] = React.useState({
    project_type: null,
    name: null,
    description: null,
  });

  const projectsType = [
    {
      name: "ombriere solaire avec fondation",
      image: OmbriereAvec,
    },
    {
      name: "ombriere solaire sans fondation",
      image: OmbriereSans,
    },
    {
      name: "toiture solaire",
      image: ToitureSolaire,
    },
  ];

  const stepsText = [
    {
      step: 1,
      text: "Informations générales",
    },
    {
      step: 2,
      text: "Données de consommation",
    },
    {
      step: 3,
      text: "Etude de projet",
    },
    {
      step: 4,
      text: "Devis & contrat",
    },
    {
      step: 5,
      text: "Documents à envoyer",
    },
  ];
  useEffect(() => {
    if (readyFirstProj) {
      setDisplayPopUpTypes(true);
    }
  }, []);
  const [errorSelector, setErrorSelector] = React.useState("");
  const handleClose = (
    itemSelected: any,
    setterPopup: any,
    errorMessage: any
  ) => {
    if (itemSelected !== null) {
      setterPopup(false);
      setErrorSelector("");
      return true;
    } else {
      setErrorSelector(errorMessage);
      return false;
    }
  };
  const handleDisplay = (popUpHandler: any) => {
    popUpHandler((popup: any) => !popup);
  };

  const togglePopUpDelete = () => {
    setDisplayPopUpDelete((popup: any) => !popup);
  };

  const displayLocation = (project: any) => {
    return `${project.projectLocation?.name}, ${project.projectLocation?.citycode},
    ${project.projectLocation?.city}`;
  };
  if (!projects) return null;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "min(100% - 40px, 1142px)",
      }}
    >
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        <Typography variant='h2'>{headerText}</Typography>
        <Button
          onClick={() => {
            handleDisplay(setDisplayPopUpTypes);
          }}
          sx={{
            color: "#fff",
            height: `38px`,
          }}
          variant='contained'
          startIcon={<AddIcon />}
        >
          <Typography sx={{ color: "#fff" }} variant='body2'>
            {projects.length === 0
              ? "Commencer un projet"
              : "Ajouter un projet"}
          </Typography>
        </Button>
      </Box>
      <ProjectTypeDialog
        open={displayPopUpTypes}
        onClose={handleDisplay}
        handleClose={handleClose}
        infos={infos}
        setInfos={setInfos}
        nextPopUp={setDisplayPopUpInfos}
        setErrorSelector={setErrorSelector}
        setPopup={setDisplayPopUpTypes}
      />
      <ProjectInfosDialog
        infos={infos}
        setInfos={setInfos}
        open={displayPopUpInfos}
        setPopup={setDisplayPopUpInfos}
        setErrorSelector={setErrorSelector}
        errorSelector={errorSelector}
        onClose={handleDisplay}
        handleClose={handleClose}
        prevPopUp={setDisplayPopUpTypes}
        setProjects={setProjects}
        projects={projects}
      />
      <DeleteProjectDialog
        open={displayPopUpDelete}
        onClose={togglePopUpDelete}
        selectedProject={selectedProject}
        projects={projects}
        setProjects={setProjects}
      />

      <Grid
        container
        margin={"5px 0"}
        marginBottom={"20px"}
        rowSpacing={"35px"}
        columnSpacing={{ xs: "0", sm: "45px" }}
      >
        {/* Temporaire */}
        {projects.map((project: any) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={project._id}>
              <Card
                variant={"outlined"}
                sx={{
                  ":hover": {
                    backgroundColor: "#f5f5f5",
                  },
                  position: "relative",
                  borderRadius: "20px",
                }}
              >
                <DeleteIcon
                  sx={{
                    position: "absolute",
                    top: "18px",
                    right: "12px",
                    color: "#6C6C6C",
                    cursor: "pointer",
                    borderRadius: "50%",
                    ":hover": {
                      backgroundColor: "#EBEBEB",
                    },
                  }}
                  onClick={() => {
                    setSelectedProject(project);
                    togglePopUpDelete();
                  }}
                />

                <CardHeader title={project.name} />
                <Link
                  href={
                    role === "ADMIN"
                      ? `/dashboard/project/${project._id}/${idUser}`
                      : `/dashboard/project/${project._id}`
                  }
                  sx={{
                    textDecoration: "none",
                  }}
                  key={project?._id}
                >
                  <CardMedia
                    component='img'
                    height='194'
                    image={
                      projectsType.find(
                        (type) => type.name === project.project_type
                      )?.image
                    }
                    alt="Image d'un toit"
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      <PlaceIcon />
                      <Typography sx={{ marginLeft: "12px" }}>
                        {project.projectLocation?.name &&
                        project.projectLocation?.citycode &&
                        project.projectLocation?.city
                          ? displayLocation(project)
                          : "Informations non renseignées"}
                      </Typography>
                    </Box>
                    {/* {true && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <EngineeringIcon />
                    <Typography sx={{ marginLeft: "12px" }}>
                      test seller
                      {/* {seller.family_name} {seller.name} */}
                    {/* </Typography> */}
                    {/* </Box> */}
                    {/* )} */}

                    {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  <ContactPhoneIcon />
                  <Typography sx={{ marginLeft: "12px" }}>
                    {selectedUser.phone}
                  </Typography>
                </Box> */}
                    {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  <EmailIcon />
                  <Typography sx={{ marginLeft: "12px" }}>
                    {selectedUser.email}
                  </Typography>
                </Box> */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      <BusinessIcon />
                      <Typography sx={{ marginLeft: "12px" }}>
                        {selectedUser.etablissement?.uniteLegale
                          .denomationUniteLegale
                          ? selectedUser.etablissement?.uniteLegale
                              .denominationUniteLegale
                          : "Information non renseignée"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        marginTop: "8px",
                      }}
                    >
                      <Typography>
                        Etape :{" "}
                        {
                          stepsText.find(
                            (stepText: any) => stepText?.step === project?.step
                          )?.text
                        }
                      </Typography>
                      <ProgressBar
                        numberStep={project.step}
                        totalNumberSteps={stepsText.length}
                      />
                    </Box>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
