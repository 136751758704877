import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import { USERS } from "../api/USER";
import { CREATE_ENEDIS_DATA } from "../api/ENEDIS/ENEDIS";
import { rejects } from "assert";
function Redirect() {
  /*   -------------------- socket state ------------------------  */
  const { isLoading, error, data, refetch } = useQuery<any, Error>(
    "enedisData",
    async () => {
      const params = new URLSearchParams(new URL(window.location.href).search);
      const usage_point_id = params.get("usage_points_id");
      try {
        await USERS.PUT_USER({ usage_point_id: [usage_point_id] });
        await USERS.PUT_PROJECT(params.get("project_id"), {
          step: 3,
          usage_point_id: usage_point_id,
        });
        CREATE_ENEDIS_DATA();
        window.location.replace("/dashboard");
      } catch (err) {
        console.log(err);
      }
    }
  );

  return (
    <div className='page_redirect'>
      <h2>
        {!error
          ? "Merci pour votre consentement, vous allez être redirigé vers votre projet..."
          : "une erreur est survenue"}
      </h2>
    </div>
  );
}

export default Redirect;
