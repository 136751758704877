export default async function EtudeGetDate({
    power,
    augmentationAnnuel,
    perte,
    systemcost,
    user_id,
}: any) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/api/admin/project/pvdata?peakpower=${power}&inflateRate=${augmentationAnnuel}&loss=${perte}&systemcost=${systemcost}&user_id=${user_id}`, {
        method: 'GET',
        redirect: 'follow',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    })
    .then(response => response.text())
    .then(result => {
        return(result || {test: 'test'})
    })
    .catch(error => console.log('error', error));




}