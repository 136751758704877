import { Box, Typography, Button } from "@mui/material";

function NotFund() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "primary",
      }}
    >
      <Typography variant='h1' style={{ color: "black" }}>
        404
      </Typography>
      <Typography variant='h6' style={{ color: "black" }}>
        La page que vous avez recherché ne semble pas exister
      </Typography>
      <Button
        href='/'
        variant='contained'
        sx={{ color: "white", marginTop: "12px" }}
      >
        Retour à la page d'accueil
      </Button>
    </Box>
  );
}

export default NotFund;
