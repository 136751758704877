import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Checkbox,
  ListItem,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import Toit from "../../assets/toit_image_provisoir.jpg";
import ArdoiseFibrociment from "../../assets/tuiles/ardoise_fibrociment.jpg";
import ArdoiseNaturelle from "../../assets/tuiles/ardoise_naturelle.jpg";
import BacAcierSandwich from "../../assets/tuiles/bac_acier_sandwich.jpg";
import BacAcier from "../../assets/tuiles/bac_acier.jpg";
import ToleFibrociment from "../../assets/tuiles/tole_fibrociment.jpg";
import ToleOndule from "../../assets/tuiles/tole_ondule.jpg";
import TuileDuPays from "../../assets/tuiles/tuile_du_pays.jpg";
import TuileMecanique from "../../assets/tuiles/tuile_mecanique.jpg";
import CloseIcon from "@mui/icons-material/Close";
import { DialogHeader } from "./DialogHeader";
export const TileSelectionDialog = ({
  localData,
  setSelectionError,
  roof,
  setRoof,
  prevPopUp,
  setErrorSelector,
  handleClose,
  errorSelector,
  open,
  onClose,
  setOpenTileSelection,
  nextPopup,
}: any) => {
  const handleGoPrev = () => {
    setOpenTileSelection(false);
    prevPopUp(true);
  };
  const tiles = [
    {
      type: "Bac acier",
      image: BacAcier,
    },
    {
      type: "Bac acier Sandwich",
      image: BacAcierSandwich,
    },
    {
      type: "Tole ondulée",
      image: ToleOndule,
    },
    {
      type: "Tuile mécanique",
      image: TuileMecanique,
    },
    {
      type: "Tuile du pays",
      image: TuileDuPays,
    },
    {
      type: "Ardoise fibrociment",
      image: ArdoiseFibrociment,
    },
    {
      type: "Ardoise naturelle",
      image: ArdoiseNaturelle,
    },
    {
      type: "Tole fibrociment",
      image: ToleFibrociment,
    },
  ];
  const handleCheck = (tile: any) => {
    const tileType = tile.type;
    setRoof({ ...roof, tileType: tileType });
    setSelectionError(false);
    setErrorSelector("");
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogHeader
        headerText={"Sélectionnez votre type de tuile"}
        onClose={onClose}
      />

      <div className='roof-selector tile-selector'>
        {tiles.map((tile) => {
          return (
            <div
              className='roof-selector-wrapper card'
              onClick={() => handleCheck(tile)}
              key={tile.type}
            >
              <ListItem key={tile.type} className='roof-selector-card'>
                <img src={tile.image} alt='Toit' className='selector-img' />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#ddd",
                        "& .MuiSvgIcon-root": { fontSize: 50 },
                        ".Mui-focusVisible &": {
                          outline: "2px auto rgba(19,124,189,.6)",
                          outlineOffset: 2,
                        },
                      }}
                      value={tile.type}
                      checked={tile.type === roof.tileType}
                    />
                  }
                  label={tile.type}
                />
              </ListItem>
            </div>
          );
        })}
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          margin: "20px",
        }}
      >
        {errorSelector !== "" ? (
          <p style={{ color: "red" }}>{errorSelector}</p>
        ) : (
          ""
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer", paddingRight: "20px" }}
            onClick={() => handleGoPrev()}
          >
            <p>Précédent</p>
          </Box>
          <Button
            variant='contained'
            color='primary'
            sx={{ width: "210px", color: "white" }}
            onClick={() =>
              handleClose(
                roof.tileType,
                setOpenTileSelection,
                "Vous devez choisir un type de composant"
              )
            }
          >
            Valider mon choix
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

//
