import React, { useState } from 'react';
import {
    Box,
    Typography,
  } from "@mui/material";
  import CheckCircleIcon from '@mui/icons-material/CheckCircle';
function RadioCardItem({ children, onSelect, isSelected }:any) {
    return (
        <Box sx={{width: '50%',}}>
            <Box onClick={onSelect} sx={{
                borderColor: isSelected ? 'primary.main' : '#fff',
                cursor: isSelected ? 'auto' : 'pointer',
                padding: '20px', 
                borderRadius: '15px', 
                borderWidth: '3px', 
                borderStyle:'solid',
                backgroundColor: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 'calc(100% - 40px)',
            }}>
                <Box>
                {children}
                </Box>
                {isSelected &&  <CheckCircleIcon sx={{color: 'primary.main'}}/>}
            </Box>
        </Box>
    );
  }

function RadioCard({children, selectedCard, handleCardSelection, text, selectedCardError}: any) {
    return (
        <Box sx={{ 
            backgroundColor: 'customGrey.grey1',
            padding: '20px',
            borderRadius: '20px',
            mt:'20px',
        }}>
            {text && !selectedCardError && <Typography variant='body2' mb={'15px'}>{text}</Typography>}
            {selectedCardError && <Typography sx={{color: 'red'}} variant='body2' mb={'15px'}>{selectedCardError}</Typography>}

      <Box sx={{
        display: 'flex', 
        gap: '20px', 
       
        }}>
        {React.Children.map(children, (child, index) => {
          const isSelected = selectedCard === index;
          return React.cloneElement(child, {
            isSelected,
            onSelect: () => handleCardSelection(index)
          });
        })}
      </Box>
        </Box>
    );
  }

export { RadioCard, RadioCardItem };