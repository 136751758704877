import React, { useState } from "react";
import {
  Box,
  Typography
} from "@mui/material";

const ErrorMessage = (props: any) => {
  return (
    <Box>
        <Typography variant="h3" color="error" margin={1}>{props.message}</Typography>
    </Box>
  );
};

export default ErrorMessage;