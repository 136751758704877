import axios from 'axios';

export const POST_ENEDIS_TOKEN:()=>Promise<{access_token:String; scope:String; token_type:String}> = async function () {
    const config = {
        url: `${process.env.REACT_APP_ENEDIS_BASE_URL}/oauth2/v3/token`,
        method: 'post',
        params:{
            'grant_type': "client_credentials",
            'client_id': process.env.REACT_APP_ENEDIS_CONSUMER_KEY,
            'client_secret': process.env.REACT_APP_ENEDIS_SECRET_KEY,
        },
      };
    
      return await axios(config)
        .then(function (response) {
          localStorage.setItem('enedis_token',response.data.access_token);
          return response.data         
        })
        .catch(function (error) {
          console.log(error)
          return {}
        });
	}

  //start create enedis data on server
  export const CREATE_ENEDIS_DATA = async function () {
    const config = {
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/user/ENEDIS_data`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      axios.request(config)
      .then((response) => {
         console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // export const GET_ENEDIS_CONSUMPTION = async function (usage_point_id:String,start?:Date, end?:Date, token?:String) {
  //   const enedisToken = token || localStorage.getItem('enedis_token') || (await POST_ENEDIS_TOKEN())?.access_token; 
  //   const enedisStart = start || new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
  //   const enedisEnd = end || new Date().toISOString().split('T')[0];
  //   const config = {
  //       url: `${process.env.REACT_APP_ENEDIS_BASE_URL}/v4/metering_data/consumption_load_curve`,
  //       method: 'get',
  //       params:{
  //           'usage_point_id': usage_point_id,
  //           'start': enedisStart,
  //           'end': enedisEnd,
  //       },
  //       headers: {
  //           'Authorization': `Bearer ${enedisToken}`,
  //           'Content-Type': 'application/json',
  //       },
  //     };
    
  //     return await axios(config)
  //       .then(function (response) {
  //         console.log(response.data)
  //         return response.data         
  //       })
  //       .catch(function (error) {
  //         console.log(error)
  //         return {}
  //       });
  //     }
  // export const GET_ENEDIS_CONTRATS = async function (usage_point_id:String,token?:String) {
  //   const enedisToken = token || localStorage.getItem('enedis_token') || (await POST_ENEDIS_TOKEN())?.access_token; 
  //   const config = {
  //       url: `${process.env.REACT_APP_ENEDIS_BASE_URL}/customers_upc/v5/usage_points/contracts`,
  //       method: 'get',
  //       params:{
  //         'usage_point_id': usage_point_id,
  //     },
  //       headers: {
  //           'Authorization': `Bearer ${enedisToken}`,
  //           'Content-Type': 'application/json',
  //           'Accept': 'application/json',
  //       },
  //     };
    
  //     return await axios(config)
  //       .then(function (response) {
  //         console.log(response.data)
  //         return response.data         
  //       })
  //       .catch(function (error) {
  //         console.log(error)
  //         return {}
  //       });
  //     }



