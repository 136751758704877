import { useState } from "react";
import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { ADMIN_USER } from "../../api/ADMIN_USER";
import { SELLER_USER } from "../../api/SELLER_USER";
import { ADMIN } from "../../api/ADMIN";
import { SELLER } from "../../api/SELLER";
import Stepper from "../ui/stepper";
import Step from "./step/Step";
import jwt_decode from "jwt-decode";
import Chat from "../chat/chat";

const UserPage = (props: any) => {
  const [localData, setLocalData] = useState<any>([]);
  const [StepperStep, setStepperStep] = useState<any>();
  const storedToken = localStorage.getItem("token");
  const userType = storedToken && jwt_decode<any>(storedToken)?.user?.role;
  const admin_id = storedToken && jwt_decode<any>(storedToken)?.user?.id;

  let API_USER = SELLER_USER;
  let API = SELLER;
  if (userType === "ADMIN") {
    API_USER = ADMIN_USER;
    API = ADMIN;
  }
  const { data: result, isLoading } = useQuery(
    ["user", "project"],
    async () => {
      const userResponse = await API_USER.GET_USER(props.user_id);
      const projectResponse = await API_USER.GET_PROJECT(props.user_id);
      setLocalData({ user: userResponse.data, project: projectResponse.data });
      return { user: userResponse.data, project: projectResponse.data };
    }
  );

  const utilisateur_page_header = document.getElementById(
    "utilisateur_page_header"
  );
  if (
    utilisateur_page_header &&
    localData?.user?.name &&
    localData?.user?.family_name
  ) {
    utilisateur_page_header.innerHTML = `Utilisateur : ${localData?.user?.name} ${localData?.user?.family_name}`;
  }
  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {localData.project && (
        <Chat
          user={userType}
          name={`${localData?.user?.name} ${localData?.user?.family_name}`}
          project_id={localData?.project?._id}
          user_id={admin_id}
          API={ADMIN}
        />
      )}
      <Stepper
        setStepperStep={setStepperStep}
        variant='admin'
        mt={"0px"}
        step={localData?.project?.step}
      />
      <Step
        API={API}
        step={StepperStep || localData?.project?.step}
        localData={localData}
      />
    </Box>
  );
};

export default UserPage;
