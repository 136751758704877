import React from "react";
import {
  Dialog,
  DialogTitle,
  FormControl,
  Button,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import { USERS } from "../../api/USER";
export const DeleteProjectDialog = ({
  open,
  setPopup,
  onClose,
  selectedProject,
  setProjects,
  projects,
}: any) => {
  const handleDelete = () => {
    USERS.DELETE_PROJECT(selectedProject._id).then((res: any) => {
      setProjects(
        projects.filter((project: any) => project._id !== selectedProject._id)
      );
    });
    onClose(setPopup);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(setPopup)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        sx={{
          overflow: "hidden",
          fontSize: { xs: "17px", sm: "24px" },
          textOverflow: "ellipsis",
        }}
      >
        Voulez vous vraiment supprimer le projet {selectedProject.name} ?
      </DialogTitle>
      <DialogActions
        sx={{
          padding: `10px 20px`,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Button
          sx={{
            color: `text.disabled`,
            fontSize: { xs: "14px", sm: "18px" },
          }}
          onClick={() => {
            onClose(setPopup);
          }}
        >
          Annuler
        </Button>
        <Button
          sx={{
            color: `#fff`,
            fontSize: { xs: "14px", sm: "18px" },
          }}
          variant='contained'
          disableElevation
          onClick={() => handleDelete()}
          autoFocus
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
