import React, { useState, useEffect } from "react";
import { Box, Typography, TextField } from "@mui/material";
import DragNDrop from "../../ui/dragNdrop";
import { StateMessage } from "../../ui/stateMessage";
import { USERS } from "../../../api/USER";
import FileCard from "../../ui/FileCard";
import { SignDocs } from "../../../utils/filesList";
import { DashBoardSkeleton } from "../../ui/Skeleton";
import { ProjectDocs } from "../../../utils/filesList";
const ProjetDoc = ({ localData, setLocalData }: any) => {
  const [Files, setFiles] = useState(ProjectDocs);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const filesWithFileNameOnly = [...ProjectDocs].map(({ file_name }) => ({
      file_name,
    }));
    USERS.DOC_CHECK(localData.project._id, filesWithFileNameOnly).then(
      (res) => {
        if (res.data) {
          const updatedFiles = Files.map((file) => {
            const fileExist = res.data.find(
              (data: any) => data.file_name === file.file_name
            )?.exist;
            return { ...file, exist: fileExist };
          });
          setIsLoading(false);
          setFiles(updatedFiles);
        }
      }
    );
  }, []);

  if (!Files.some((file) => !file.exist)) {
    USERS.PUT_PROJECT(localData.project._id, {
      step: 6,
    }).then((res) => {
      res && setLocalData({ ...localData, project: res.data });
    });
  }

  const handleFileSend = (file_name: string) => {
    setFiles((prevState) =>
      prevState.map((file) =>
        file.file_name === file_name ? { ...file, exist: true } : file
      )
    );
  };

  const isAllFilesSend = !Files.some((file) => !file.exist);
  const FilesSend = () => {
    return (
      <StateMessage
        message={
          isAllFilesSend
            ? "Les documents sont en cours de création, revenez un peu plus tard pour pouvoir les consulter."
            : "Veuillez envoyer les documents si-dessous."
        }
        quote={
          isAllFilesSend
            ? "Nous prenons en général 1 à 2 jours ouvrés pour créer l'étude."
            : "Pour envoyer les documents, glissez les dans les champs prévu pour."
        }
        icon={isAllFilesSend ? "waiting" : "upload"}
        component={
          <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            {Files.map(
              (file, i1) =>
                file.exist && (
                  <Box key={i1}>
                    <FileCard
                      variant={"download"}
                      download={{
                        download_api: USERS.DOC_DOWNLOAD,
                        file_name: file.file_name,
                        project_id: localData.project._id,
                      }}
                      fileName={file.file_name}
                    />
                  </Box>
                )
            )}
          </Box>
        }
      />
    );
  };
  return (
    <Box sx={{ padding: "20px" }} className={"wrapper card"}>
      <Typography
        variant='h2'
        component='h1'
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontSize: "30px",
          margin: "10px 0 30px 0",
        }}
      >
        Documents à envoyer
      </Typography>
      {isLoading ? (
        <DashBoardSkeleton />
      ) : (
        <Box>
          <FilesSend />
          {Files.map(
            (file, i1) =>
              !file.exist && (
                <Box mb={"20px"} key={i1}>
                  <DragNDrop
                    ext={file.ext}
                    title={file.label}
                    size='small'
                    upload={{
                      upload_api: USERS.UPLOAD_DOC,
                      file_name: file.file_name,
                      project_id: localData.project._id,
                    }}
                    sendAction={handleFileSend}
                  />
                </Box>
              )
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProjetDoc;
