import React, { useState } from "react";
import { Box, Typography, Link, Grid } from "@mui/material";
import logo_enedis from "../../../assets/Enedis-signature_couleur_RVB_300-dpi.png";
import link_enedis from "../../../assets/vert-enedis.png";
import { useQuery } from "react-query";
import { POST_ENEDIS_TOKEN } from "../../../api/ENEDIS/ENEDIS";
const ConsoData = ({ localData, setLocalData }: any) => {
  return (
    <Box sx={{ padding: "20px" }} className={"wrapper card"}>
      <Typography
        variant='h2'
        component='h1'
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontSize: "30px",
          margin: "10px 0 10px 0",
        }}
      >
        Vos données de consommations
      </Typography>
      <Grid
        container
        alignItems='center'
        direction='row'
        sx={{ margin: "20px 0" }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <img
            alt='logo'
            src={logo_enedis}
            style={{ maxWidth: "160px", width: 1, objectFit: "contain" }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={10}>
          <Typography
            variant='body1'
            sx={{
              textAlign: "left",
              color: "secondary.main",
              padding: { xs: "20px 0px 0px 0px", sm: "20px" },
            }}
          >
            Pour obtenir des informations exactes, nous avons besoin de votre
            autorisation pour accéder à votre compteur Linky. Pour cela, nous
            vous dirigerons vers votre espace client ENEDIS où vous pourrez
            créer un compte si vous n'en avez pas déjà un. Après avoir collecté
            les données, nous pourrons analyser vos courbes de charge et
            calibrer avec précision votre centrale.
          </Typography>
        </Grid>
      </Grid>
      <Box className='wrap card_light flexCenter' height={100}>
        <Link
          href={`/redirect?state=XYZ&usage_points_id=22516914714270&code=134567281&project_id=${localData.project._id}`}
        >
          <img
            alt='link enedis'
            className='shadow_1 hoverOpacity'
            src={link_enedis}
            style={{ height: "60px", borderRadius: "4px" }}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default ConsoData;
