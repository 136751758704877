import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFund from "./pages/404";
import Main from "./pages/main";
import Login from "./pages/login";
import Solutions from "./pages/solutions";
import Legislatif from "./pages/legislatif";
import Ressource from "./pages/ressource";
import DashBoard from "./pages/dashboard";
import Redirect from "./pages/redirect";
import Header from "./components/ui/navigation";
import Footer from "./components/ui/footer";
import { SocketProvider } from "./hooks/sockets/index";
import { Box } from "@mui/material";
import { MentionsLegales } from "./pages/MentionsLegales";
import Projects from "./pages/projects";
import { Project as ProjectUser } from "./components/user/project/project";
import { Project as ProjectAdmin } from "./components/admin/projectChoice/project";
function AppRouter() {
  React.useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      if (window.location.pathname === "/login") {
        window.location.replace("/dashboard");
      }
    } else {
      if (window.location.pathname === "/dashboard") {
        window.location.replace("/login");
      }
    }
  }, []);

  return (
    <>
      <Router>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Header />
          <Routes>
            <Route
              path='/'
              element={
                <Box sx={{ flex: "1" }}>
                  <Main />
                </Box>
              }
            />
            <Route
              path='/login'
              element={
                <Box
                  className='login'
                  sx={{
                    flex: "1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Login />
                </Box>
              }
            />
            <Route
              path='/solutions'
              element={
                <Box sx={{ flex: "1" }}>
                  <Solutions />
                </Box>
              }
            />
            <Route
              path='/legislatif'
              element={
                <Box sx={{ flex: "1" }}>
                  <Legislatif />
                </Box>
              }
            />
            <Route
              path='/ressource'
              element={
                <Box sx={{ flex: "1" }}>
                  <Ressource />
                </Box>
              }
            />
            <Route
              path='/dashboard'
              element={
                <Box sx={{ flex: "1" }}>
                  <SocketProvider>
                    <DashBoard />
                  </SocketProvider>
                </Box>
              }
            />
            <Route
              path='/dashboard/user/:user?'
              element={
                <Box sx={{ flex: "1" }}>
                  <SocketProvider>
                    <Projects />
                  </SocketProvider>
                </Box>
              }
            />
            <Route
              path='/dashboard/project/:project?'
              element={
                <Box sx={{ flex: "1" }}>
                  <SocketProvider>
                    <ProjectUser />
                  </SocketProvider>
                </Box>
              }
            />
            <Route
              path='/dashboard/project/:project?/:user_id?'
              element={
                <Box sx={{ flex: "1" }}>
                  <SocketProvider>
                    <ProjectAdmin />
                  </SocketProvider>
                </Box>
              }
            />
            <Route
              path='/mentions-legales'
              element={
                <Box sx={{ flex: "1" }}>
                  <MentionsLegales />
                </Box>
              }
            />
            <Route
              path='/redirect'
              element={
                <Box sx={{ flex: "1" }}>
                  <Redirect />
                </Box>
              }
            />
            <Route
              path='*'
              element={
                <Box sx={{ flex: "1" }}>
                  <NotFund />
                </Box>
              }
            />
          </Routes>
          <Footer />
        </Box>
      </Router>
    </>
  );
}

export default AppRouter;
