import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ADMIN_USER } from "../../../api/ADMIN_USER";
import { useQuery } from "react-query";
import { DisplayProjects } from "../../ui/DisplayProjects";
import { LoadingPage } from "../../ui/LoadingPage";
export const ProjectChoice = () => {
  const id = useParams();
  const [usersProjects, setUsersProjects] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState<any>([]);

  const { isLoading } = useQuery<any, Error>("projectsUser", async () => {
    try {
      if (id.user) {
        ADMIN_USER.GET_PROJECT(id.user).then((res: any) => {
          setUsersProjects(res.data);
        });
        ADMIN_USER.GET_USER(id.user)
          .then((res: any) => {
            setSelectedUser(res.data);
          })
          .catch((err) => console.log(err));
      }
    } catch (err) {
      console.log(err);
    }
  });
  return (
    <>
      {/* <LoadingPage /> */}
      {isLoading ? (
        <h1>Chargement en cours...</h1>
      ) : (
        <DisplayProjects
          headerText={`Projets de : ${selectedUser.family_name} ${selectedUser.name}`}
          selectedUser={selectedUser}
          projects={usersProjects}
          setProjects={setUsersProjects}
          sellerName={"Frédéric"}
          idUser={id.user}
          role={"ADMIN"}
          // seller={seller}
        />
      )}
    </>
  );
};
