import { Box, Typography, Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

function ImportFileInput({ file, setFile, size, title, ext, icon, padding, margin, width, borderRadius, color }: any) {
  const handleSelectFile = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "white",
        padding: padding ? padding : "10px",
        margin: margin ? margin : "0 0 10px 0",
        borderRadius: borderRadius ? borderRadius : "10px",
      }}
    >
      <Button
        variant={"contained"}
        sx={{ color: "#fff",
          backgroundColor: color ? color : "primary.main",
         marginTop: size === "small" ? "0px" : "20px",
         minWidth: width ? width : "auto",
         borderRadius: borderRadius ? borderRadius : "10px",
         }}
        component="label"
      >
        {title && title}
        {icon && icon}
        <input
          type="file"
          accept={ext.map((type: any) => `.${type}`).join(",")}
          onChange={handleSelectFile}
          hidden
        />
      </Button>
      <Typography ml={"10px"}>{file && file.name}</Typography>
      {file && (
        <IconButton
          onClick={() => {
            setFile(null);
          }}
          aria-label="delete"
          color="primary"
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default ImportFileInput;
