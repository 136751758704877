
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import React from 'react';

const style = [{
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "poi.attraction",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "poi.business",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "poi.government",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "poi.medical",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "poi.park",
    "elementType": "labels",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "poi.place_of_worship",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "poi.school",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "poi.sports_complex",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "road.arterial",
    "elementType": "labels",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "road.highway",
    "elementType": "labels",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "road.local",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [{
        "visibility": "off"
    }]
},
{
    "featureType": "transit",
    "stylers": [{
        "visibility": "off"
    }]
}
]
export function HomeMap({location}: any) {
    const containerStyle = {
        width: '100%',
        height: '800px'
      };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBieiUGaIuTkf9N9uq29xU9knSpuNDbvt8"
      })
      return isLoaded ? (
        <div id='map'>
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={{
            styles: style,
            }}
          center={location}
          zoom={13}
          >
          <Marker position={location} />
        </GoogleMap>
            </div>
    ) : <></>
}
export function LocationMap({location}: any) {
    const containerStyle = {
        width: '100%',
        height: '500px'
      };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBieiUGaIuTkf9N9uq29xU9knSpuNDbvt8"
      })
      const onLoad = React.useCallback(function callback(map: any) {
        map.setMapTypeId('satellite')
      }, [])
      return isLoaded ? (
        <div id='map_pro'>
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={containerStyle}
          options={{
            styles: style,
            }}
          center={location}
          zoom={20}
        >
           <Marker position={location} />
        </GoogleMap>
        </div>
    ) : <></>
}