import React from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

function UserManagement_Add({
  API_USER,
  localData,
  setLocalData,
  ...props
}: any) {
  const [emailAlreadyExist, setEmailAlreadyExist] = React.useState<string>(``);

  //-------- Add User Popup ---------
  const [OpenAddUserPopup, setOpenAddUserPopup] =
    React.useState<boolean>(false);

  //formik
  const formikSchema = yup.object({
    name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `Prenom invalide`)
      .min(2, `Prenom trop court !`)
      .max(50, `Prenom trop long !`)
      .required(`Veuillez renseigner votre prenom`),
    family_name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `Nom invalide`)
      .min(2, `Nom trop court !`)
      .max(50, `Nom trop long !`)
      .required(`Veuillez renseigner votre nom`),
    email: yup
      .string()
      .required(`Veuillez renseigner votre email`)
      .email(`Email invalide`)
      .required(`Veuillez renseigner votre email`),
    password: yup
      .string()
      .required(`Veuillez renseigner votre mot de passe`)
      .min(7, `Mot de passe trop court !`),
  });

  const formik = useFormik({
    initialValues: {
      name: ``,
      family_name: ``,
      email: ``,
      password: ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      API_USER.POST_REGISTER(formValues).then((res: any) => {
        if (res) {
          setLocalData([...localData, res.data]);
          handleAddUserPopupClose();
          resetForm();
          setEmailAlreadyExist(``);
        } else {
          setEmailAlreadyExist(`Cet email est déjà utilisé`);
        }
      });
    },
  });

  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenAddUserPopup(false);
  };

  return (
    //header de user management
    <Stack direction='row' alignItems='center' spacing={5}>
      <Button
        onClick={() => {
          setOpenAddUserPopup(true);
        }}
        sx={{
          color: "#fff",
          height: `38px`,
        }}
        variant='contained'
        startIcon={<AddIcon />}
      >
        <Typography sx={{ color: "#fff" }} variant='body2'>
          Ajouter un utilisateur
        </Typography>
      </Button>
      <Dialog open={OpenAddUserPopup}>
        <Box component='form' onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Ajouter un utilisateur
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `calc(100% - 40px)`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
            <Stack>
              <TextField
                margin='dense'
                fullWidth
                id='name'
                label='Prenom'
                name='name'
                autoComplete='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                margin='dense'
                fullWidth
                id='family_name'
                label='Nom'
                name='family_name'
                autoComplete='family_name'
                value={formik.values.family_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.family_name &&
                  Boolean(formik.errors.family_name)
                }
                helperText={
                  formik.touched.family_name && formik.errors.family_name
                }
              />
              <TextField
                margin='dense'
                fullWidth
                id='email'
                label='Email'
                name='email'
                autoComplete='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={
                  (emailAlreadyExist && true) ||
                  (formik.touched.email && Boolean(formik.errors.email))
                }
                helperText={
                  (formik.touched.email && formik.errors.email) ||
                  (emailAlreadyExist && emailAlreadyExist)
                }
              />
              <TextField
                margin='dense'
                fullWidth
                name='password'
                label='Mot de passe'
                type='password'
                id='password'
                autoComplete='current-password'
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
            <Button
              type='submit'
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Créer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Stack>
  );
}

export default UserManagement_Add;
