import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Stack,
  Button,
  TextField,
  Link,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";
import { ADMIN_USER } from "../../../api/ADMIN_USER";
import { SELLER_USER } from "../../../api/SELLER_USER";
import { ADMIN } from "../../../api/ADMIN";
import { SELLER } from "../../../api/SELLER";
import { useQuery } from "react-query";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import UserManagementAdd from "./UserGrid_Add";
import UserManagementEdit from "./UserGrid_Edit";
import PersonIcon from "@mui/icons-material/Person";
import { ADMIN_SELLER } from "../../../api/ADMIN_SELLER";
// import UserGrid_Edit from '@/components/MenuPage/UserGridEdit';

const CustomToolbar: React.FunctionComponent<{
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  searchInputValue: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  setLocalData: React.Dispatch<React.SetStateAction<any>>;
  localData: any;
  API_USER: any;
}> = ({
  setFilterButtonEl,
  setSearchInputValue,
  searchInputValue,
  localData,
  setLocalData,
  API_USER,
}) => {
  //-------- Grid Header --------
  const SearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };
  return (
    <GridToolbarContainer
      sx={{
        padding: `0px`,
      }}
    >
      <Stack
        flex='1'
        sx={{
          backgroundColor: (theme) => theme.palette.customGrey.grey1,
          padding: `0px 10px`,
        }}
      >
        <Stack
          sx={{
            height: `60px`,
            "& .MuiFormLabel-root:not(.Mui-focused)": {
              color: "grey !important",
            },
          }}
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <TextField
            size='small'
            value={searchInputValue}
            onChange={SearchInputChange}
            label='Recherche'
            variant='outlined'
          />
          <UserManagementAdd
            API_USER={API_USER}
            setLocalData={setLocalData}
            localData={localData}
          />
          <GridToolbarColumnsButton ref={setFilterButtonEl} />
        </Stack>
        <Divider light />
      </Stack>
    </GridToolbarContainer>
  );
};

const UserManagement = ({ userType }: any) => {
  let API = SELLER;
  let API_USER = SELLER_USER;

  if (userType === "ADMIN") {
    API = ADMIN;
    API_USER = ADMIN_USER;
  }
  const [sellers, setSellers] = useState([]);

  const [localData, setLocalData] = useState<any>([]);

  const { isLoading, error, data, refetch } = useQuery<any, Error>(
    "workSpaceList",
    () => {
      return new Promise((resolve, reject) => {
        API_USER.GET_USERS().then((resultat: any) => {
          setLocalData(resultat.data);
          resolve(resultat);
        });
      });
    }
  );
  useQuery<any, Error>("getSellers", async () => {
    return await ADMIN_SELLER.GET_SELLERS().then((res): any => {
      if (res.message === "ok") {
        setSellers(res.data);
      }
    });
  });
  const [searchInputValue, setSearchInputValue] = React.useState(``);
  const [filterColumnButton, setFilterColumnButton] =
    React.useState<HTMLButtonElement | null>(null);

  //-------- Delete Popup --------
  const [OpenDeletePopup, setOpenDeletePopup] = React.useState<{
    status: boolean;
    row?: any;
  }>({ status: false });

  const SearchBarFilter = (rows: any) => {
    rows.map((row: any, i1: number) => {
      rows[i1].id = rows[i1]._id;
      rows[i1].full_name = rows[i1].name + " " + rows[i1].family_name;
    });
    return rows.filter(
      (element: any) =>
        !searchInputValue ||
        element.name?.toLowerCase().includes(searchInputValue?.toLowerCase()) ||
        element.family_name
          ?.toLowerCase()
          .includes(searchInputValue?.toLowerCase()) ||
        element.email
          ?.toLowerCase()
          .includes(searchInputValue?.toLowerCase()) ||
        element.fonction
          ?.toLowerCase()
          .includes(searchInputValue?.toLowerCase())
    );
  };

  const handleDeleteUser = (id: String) => {
    API_USER.DELETE_USER(OpenDeletePopup?.row?.user_id).then((res: any) => {
      setLocalData(
        localData.filter(function (obj: any) {
          return obj.user_id !== id;
        })
      );
      setOpenDeletePopup({ status: false });
    });
  };
  // useEffect(() => {
  //   console.log(sellers, localData);
  //   setLocalData(
  //     localData.map((user: any) => ({
  //       ...user,
  //       seller: sellers.find(
  //         (seller: any) => seller.user_id === user.seller_id[0]
  //       ),
  //     }))
  //   );
  //   console.log("localdata : ", localData);
  // }, [sellers]);

  //-------- Grid Columns --------
  const columns = [
    //show by default

    {
      field: `full_name`,
      headerName: `Nom/Prenom`,
      width: 150,
    },
    {
      field: `fonction`,
      headerName: `Fonction`,
      width: 150,
    },
    {
      field: `phone`,
      headerName: `Téléphone`,
      width: 150,
      renderCell: (cellValues: GridRenderCellParams) => {
        return (
          <Link href={`tel:${cellValues.value}`} color='inherit'>
            {cellValues.value}
          </Link>
        );
      },
    },
    {
      field: `email`,
      headerName: `Email`,
      minWidth: 250,
      renderCell: (cellValues: GridRenderCellParams) => {
        return (
          <Link href={`mailto:${cellValues.value}`} color='inherit'>
            {cellValues.value}
          </Link>
        );
      },
    },
    {
      field: `seller`,
      headerName: `Vendeur`,
      minWidth: 250,
      renderCell: (cellValues: GridRenderCellParams) => {
        return (
          <Link href={`mailto:${cellValues.value}`} color='inherit'>
            {cellValues.value}
          </Link>
        );
      },
    },
    // fixed right
    {
      field: `actions`,
      type: `actions`,
      headerName: `Actions`,
      width: 140,
      resizable: false,
      getActions: (e: { row?: any }) => [
        <UserPage user_id={e.row.user_id} />,
        <UserManagementEdit
          API={API}
          API_USER={API_USER}
          key='user-grid-edit'
          rowInfos={e}
          setLocalData={setLocalData}
          localData={localData}
        />,
        <GridActionsCellItem
          onClick={() => setOpenDeletePopup({ row: e.row, status: true })}
          icon={<DeleteIcon />}
          label='Delete'
          key='grid-actions-cell-item-1'
        />,
      ],
    },
  ];

  const UserPage = (props: any) => {
    const url = new URL(window.location.href);
    url.pathname += `/user/${props.user_id}`;
    return (
      <Link href={url.href} color='inherit'>
        <GridActionsCellItem
          icon={<PersonIcon />}
          label='Utilisateur'
          sx={{ backgroundColor: "primary.main", color: "white" }}
        />
      </Link>
    );
  };

  return (
    <Box
      className='wrapper_noPadding card'
      sx={{ height: "588px", display: "flex", alignItems: "center", gap: 2 }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <DataGridPro
          autoPageSize
          pagination
          sx={{
            '.MuiDataGrid-booleanCell[data-value="true"] ': {
              color: `#8D919A`,
            },
            borderRadius: `20px`,
            overflow: "hidden",
          }}
          rows={(localData[0] && SearchBarFilter(localData)) || []}
          columns={columns}
          disableColumnMenu
          localeText={{
            toolbarColumns: "Colonnes",
            columnsPanelTextFieldLabel: "Chercher une colonne",
            columnsPanelTextFieldPlaceholder: "Nom de la colonne",
            columnsPanelShowAllButton: "Afficher tout",
            columnsPanelHideAllButton: "Masquer tout",
          }}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: () => (
              <Stack height='100%' alignItems='center' justifyContent='center'>
                Aucune ligne à afficher
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height='100%' alignItems='center' justifyContent='center'>
                Aucun résultat
              </Stack>
            ),
          }}
          componentsProps={{
            panel: {
              anchorEl: filterColumnButton,
            },
            toolbar: {
              setFilterButtonEl: setFilterColumnButton,
              setSearchInputValue,
              searchInputValue,
              localData,
              setLocalData,
              API_USER,
            },
          }}
          initialState={{ pinnedColumns: { right: [`actions`] } }}
          // disableSelectionOnClick
        />
        <Dialog
          open={OpenDeletePopup?.status}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            sx={{
              overflow: "hidden",
              fontSize: { xs: "17px", sm: "24px" },
              textOverflow: "ellipsis",
            }}
          >
            Voulez vous vraiment supprimer {OpenDeletePopup?.row?.full_name} ?
          </DialogTitle>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={() => {
                setOpenDeletePopup({ status: false });
              }}
            >
              Annuler
            </Button>
            <Button
              sx={{
                color: `#fff`,
              }}
              variant='contained'
              disableElevation
              onClick={() => {
                OpenDeletePopup?.row?.id &&
                  handleDeleteUser(OpenDeletePopup.row.user_id);
              }}
              autoFocus
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default UserManagement;
