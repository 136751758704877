import jwt_decode from "jwt-decode";
// import UserProjectChoice from "../components/user/project/projectChoice";
import Chat from "../../chat/chat";
import { useState, useEffect } from "react";
import { ADMIN_USER } from "../../../api/ADMIN_USER";
import Step from "../step/Step";
import Stepper from "../../ui/stepper";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { ADMIN } from "../../../api/ADMIN";
export function Project() {
  const params = useParams();
  const [localData, setLocalData] = useState<any>(null);
  const [stepperStep, setStepperStep] = useState<any>();
  const { data: result, isLoading } = useQuery(
    ["user", "project"],
    async () => {
      const userResponse = await ADMIN_USER.GET_USER(params.user_id!);
      const projectResponse = await ADMIN_USER.GET_PROJECT(params.user_id!);
      let project = projectResponse.data;
      project = project.filter(
        (project: any) => project._id === params.project
      );
      setLocalData({ user: userResponse.data, project: project[0] });
    }
  );
  const StepManager = () => {
    return (
      localData && (
        <>
          <Chat
            user={"ADMIN"}
            name={`${localData?.user?.family_name} ${localData?.user?.family_name}`}
            project_id={localData?.project?._id}
            user_id={localData?.user?.user_id}
            API={ADMIN}
          />
          <Stepper
            setStepperStep={setStepperStep}
            step={localData?.project?.step}
            fullStep={localData?.project?.step}
            user_id={localData?.user?.user_id}
            variant={true}
          />
          <Step
            localData={localData}
            step={stepperStep || localData?.project?.step}
            setLocalData={setLocalData}
            API={ADMIN}
          />
        </>
      )
    );
  };
  if (!localData) return null;
  return (
    <Box
      className='page_redirect'
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100%",
        height: "100%",
        margin: "20px",
      }}
    >
      <StepManager />
    </Box>
  );
}
