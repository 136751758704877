const setDataTable = ({data}: any) => {
  const month = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const tableRows = data?.fixed?.map((moi: any, i1: number) => (
    <tr key={i1}>
      <td>{month[i1]}</td>
      <td>{moi["E_m"]}</td>
      <td>{moi["H(i)_m"]}</td>
      <td>{moi["SD_m"]}</td>
    </tr>
  ));

  return <tbody id="table_content">{tableRows}</tbody>;
};

export default setDataTable;
