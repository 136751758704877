import axios from 'axios';

export const USERS = {
	GET_PROBYSIRET: async function (SIRET:string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/BYSIRET`,
			params: {
				siret:SIRET,
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	GET_USER: async function () {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			
		}).then(function (res) {						
			return res.data;
		})).catch((err) => console.log(err))
	},
	IS_CONNECTED: async function (){
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/token/`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			
		}).then(function (res) {		
			return res.status === 200;				
		})).catch((err) => console.log(err))
	},
	GET_CHAT_BY_PROJECT_ID: async function (project_id: string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/chat`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params: {
				project_id: project_id,
			},	
		}).then(function (res) {						
			return res.data;
		}))
	},

	PUT_USER: async function (user: any) {
		return (axios({
			method: 'put',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/`,
			data: {
				user: JSON.stringify(user),
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res) {						
			return res.data;
		}))
	},
	POST_PROJECT: async function (project: any) {
		return (axios({
			method: 'post',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/project`,
			data: {
				project: JSON.stringify(project),
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res) {						
			return res.data;
		}).catch((err) => {
			console.log(err);
		}))
	},
	PUT_PROJECT: async function (project_id:any, project: any) {
		return (axios({
			method: 'put',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/myproject`,
			data: {
				project: JSON.stringify(project),
			},
			params: {
				project_id: project_id
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res) {	
			return res.data;
		}))
	},

	GET_PROJECT: async function () {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/project`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res) {						
			return res.data;
		}))
	},
	DELETE_PROJECT: async function (project_id: string) {
		return (axios({
			method: 'delete',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/project/`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params: {
				project_id: project_id,
			},	
		}).then(function (res) {						
			return res.data;
		}))
	},
	DOC_CHECK: async function (project_id: string, file_name: any) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/file/check`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			}
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	UPLOAD_DOC: async function handleSendFile(project_id: string, file_name: string, file: any){
		if(file){
			let formData = new FormData();
			formData.append('file', file);
			try {
			  const response = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/api/user/file/upload?project_id=${project_id}&file_name=${file_name}`,
				headers: {
				  'Content-Type': 'multipart/form-data',
				  'Authorization': `Bearer ${localStorage.getItem('token')}`
				},
				data: formData
			  });
			  return 'success'
			} catch (error) {
			  return 'error'
			}
		  }
		},

	UPLOAD_CHAT_DOC: async function handleSendFile(project_id: string, file_name: string, file: any){
		if(file){
			let formData = new FormData();
			formData.append('file', file);
			try {
				const response = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/api/user/chat/upload?project_id=${project_id}&file_name=${file_name}`,
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': `Bearer ${localStorage.getItem('token')}`
				},
				data: formData
				});
				return 'success'
			} catch (error) {
				return 'error'
			}
			}
		},

	DOWNLOAD_CHAT: async function (project_id: string, file_name: string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/chat/download`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			},
			responseType: 'blob',
		}).then(function (res:any) {
			return res;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	DOC_DOWNLOAD: async function (project_id: string, file_name: string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/file/download`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			},
			responseType: 'blob',
		}).then(function (res:any) {
			return res;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	DOC_DELETE: async function (project_id: string, file_name: string) {
		return (axios({
			method: 'delete',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/file/delete`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			},
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	GET_CHATBYID: async function (project_id: string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/user/chat`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
			},
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	}


	/*
    CREATE_ACCOUNT: async function (ID:string) {
		return (axios({
			method: 'post',
			url: `${process.env.REACT_APP_SERVER_URL}/auth/POST/CONSUMER/BYID`,
			params: {
				id:ID,		
			}
		}).then(function (res) {
			return res;
			//recuperer le token ici
		}))
	},*/
}
