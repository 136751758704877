import React from "react";

import {
  Dialog,
  Checkbox,
  ListItem,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import OmbriereSans from "../../assets/types/ombriere_sans_fondation.png";
import ToitureSolaire from "../../assets/types/toiture_solaire.jpg";
import { DialogHeader } from "./DialogHeader";
export const ProjectTypeDialog = ({
  infos,
  setInfos,
  open,
  onClose,
  setErrorSelector,
  errorSelector,
  handleClose,
  setPopup,
  nextPopUp,
}: any) => {
  const toggleSetPopUp = () => {
    setPopup(false);
  };
  const projectTypes = [
    {
      type: "ombriere solaire sans fondation",
      name: "Ombrière solaire",
      image: OmbriereSans,
    },
    {
      type: "toiture solaire",
      name: "Toiture solaire",
      image: ToitureSolaire,
    },
  ];
  const handleCheck = (project_type: any) => {
    setInfos({ ...infos, project_type: project_type.type });
  };
  return (
    <Dialog open={open} onClose={toggleSetPopUp} fullWidth maxWidth='lg'>
      <DialogHeader
        headerText={"Quel type de projet voulez-vous commencer ?"}
        onClose={toggleSetPopUp}
      />
      <div className='roof-selector'>
        {projectTypes.map((projectType) => {
          return (
            <div
              className='roof-selector-wrapper roof-card card'
              onClick={() => handleCheck(projectType)}
              key={projectType.type}
            >
              <ListItem className='roof-selector-card'>
                <img
                  src={projectType.image}
                  alt='Type de projet'
                  className='selector-img'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#ddd",
                        "& .MuiSvgIcon-root": { fontSize: 50 },
                        ".Mui-focusVisible &": {
                          outline: "2px auto rgba(19,124,189,.6)",
                          outlineOffset: 2,
                        },
                      }}
                      value={projectType.type}
                      checked={projectType.type === infos.project_type}
                    />
                  }
                  label={projectType.name}
                />
              </ListItem>
            </div>
          );
        })}
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          margin: "20px",
        }}
      >
        {errorSelector !== "" ? (
          <p style={{ color: "red" }}>{errorSelector}</p>
        ) : (
          ""
        )}
        <Button
          variant='contained'
          color='primary'
          sx={{ width: "210px", color: "white" }}
          onClick={() =>
            handleClose(
              infos.projectType,
              setPopup,
              "Vous devez choisir un type de projet."
            ) && nextPopUp(true)
          }
        >
          Valider mon choix
        </Button>
      </Box>
    </Dialog>
  );
};
