import { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import DragNDrop from "../../ui/dragNdrop";
import { StateMessage } from "../../ui/stateMessage";
import FileCard from "../../ui/FileCard";
import EtudeGeneratorForm from "../Etude/EtudeGeneratorForm";
const EtudeProjet = ({ API, localData }: any) => {
  const [isAlreadySend, setIsAlreadySend] = useState(false);
  API.DOC_CHECK(localData.project._id, [
    { file_name: "EtudeSolaire.pdf" },
  ]).then((res: any) => {
    if (res.data[0].exist) {
      setIsAlreadySend(true);
    }
  });

  const handleRemoveFile = (file_name: string) => {
    API.DOC_DELETE(localData.project._id, file_name).then((res: any) => {
      if (res.data) {
        setIsAlreadySend(false);
      }
    });
  };

  const EtudeSendWaitingAprove = () => {
    return (
      <StateMessage
        message={
          "L'étude à bien été envoyé, vous êtes en attente de la confirmation du client."
        }
        quote={
          "Vous pouvez encore remplacer l'étude en en glissant une autre dans la zone prévu pour."
        }
        icon={"waiting"}
        component={
          <FileCard
            variant={"both"}
            download={{
              download_api: API.DOC_DOWNLOAD,
              file_name: "EtudeSolaire.pdf",
              project_id: localData.project._id,
            }}
            fileName={"EtudeSolaire.pdf"}
            handleRemoveFile={handleRemoveFile}
          />
        }
      />
    );
  };

  const EtudeAprove = () => {
    return (
      <Box>
        <StateMessage
          message={"Le client à validée l'étude"}
          quote={"Vous pouvez la télécharger ci-dessous pour la consulter."}
          mt={"40px"}
          mb={"0px"}
          component={
            <FileCard
              variant={"download"}
              download={{
                download_api: API.DOC_DOWNLOAD,
                file_name: "EtudeSolaire.pdf",
                project_id: localData.project._id,
              }}
              fileName={"EtudeSolaire.pdf"}
            />
          }
        />
        <TextField
          fullWidth
          margin="dense"
          label={"Financement"}
          defaultValue={
            localData.project.hasOwnProperty("financement")
              ? localData.project.financement
                ? "Avec financement"
                : "Sans financement"
              : "Non renseigné"
          }
          variant="filled"
          sx={{ input: { cursor: "auto" }, mt: "20px" }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
    );
  };

  const EtudeNotSend = () => {
    return (
      <Box>
        <StateMessage
          icon={"upload"}
          message={"Veuillez envoyer l'étude de projet au client"}
          quote={
            "Glissez le fichier dans la zone prévu pour ou cliquez sur 'parcourir' pour le sélectionner."
          }
        />
        <DragNDrop
          ext={["pdf"]}
          upload={{
            upload_api: API.UPLOAD_DOC,
            project_id: localData.project._id,
            file_name: "EtudeSolaire",
          }}
          project_id={localData.project._id}
          file_name={"EtudeSolaire.pdf"}
          setIsAlreadySend={setIsAlreadySend}
        />
      </Box>
    );
  };

  const GenerateEtude = () => {
    return(
      <Box sx={{backgroundColor: 'customGrey.grey1', flexDirection: 'column', alignItems: 'center', display: 'flex', pâdding: '20px 0'}} className='wrap card_light'>
          <EtudeGeneratorForm localData={localData}/>

      </Box>
    )
  }

  return (
    <Box
      sx={{ padding: "20px" }}
      className={"wrapper card"}
    >
      <Typography
        variant="h2"
        component="h1"
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontSize: "30px",
          margin: "10px 0 30px 0",
        }}
      >
        Etude de projet
      </Typography>
      <GenerateEtude/>
      {isAlreadySend && localData.project.step <= 3 && (
        <EtudeSendWaitingAprove />
      )}
      {localData.project.step > 3 ? (
        <EtudeAprove />
      ) : (
        !isAlreadySend && <EtudeNotSend />
      )}
    </Box>
  );
};

export default EtudeProjet;
