import { useEffect, useState } from "react";
import { Box, TextField, Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import EtudeContent from "./EtudeContent";
import EtudeGetData from "./EtudeGetData";
import dayjs from "dayjs";
import ImportFileInput from "../../ui/importFileInput";

const EtudeGeneratorForm = ({ localData }: any) => {
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [EtudeData, setEtudeData] = useState<any>({
    porteur_projet: `${localData.user.name} ${localData.user.family_name}`,
    adresse_text: localData.project.projectLocation.label,
    id_text: localData.project._id.slice(0, 8),
    date_text: dayjs().format("DD/MM/YYYY"),
    text_entreprise:
      localData.user.etablissement.uniteLegale.denominationUniteLegale,
    lat_lon: {
      lat: localData.project.projectLocation.lat,
      lng: localData.project.projectLocation.lon,
    },
    roofAngle: localData.project.roofAngle,
  });

  useEffect(() => {
    setEtudeData((prevState: any) => ({
      ...prevState,
      calpinage: file && URL.createObjectURL(file),
    }));
  }, [file]);

  const formikSchema = yup.object({
    power: yup.number().required(`Veuillez renseigner la puissance`),
    augmentationAnnuel: yup
      .number()
      .required(`Veuillez renseigner l'augmentation annuel`),
    perte: yup.number().required(`Veuillez renseigner la perte`),
    systemcost: yup
      .number()
      .required(`Veuillez renseigner le prix de l'installation`),
  });
  const formik = useFormik({
    initialValues: {
      power: ``,
      augmentationAnnuel: 2.5,
      perte: 14,
      systemcost: ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      setLoading(true);
      const getEtudeData = await EtudeGetData({
        power: formValues.power,
        augmentationAnnuel: formValues.augmentationAnnuel,
        perte: formValues.perte,
        systemcost: formValues.systemcost,
        user_id: localData.user.user_id,
      });
      getEtudeData &&
        setEtudeData((prevState: any) => ({
          power_text: formValues.power,
          augmentationAnnuel: formValues.augmentationAnnuel,
          perte: formValues.perte,
          systemcost: formValues.systemcost,
          ...prevState,
          ...JSON.parse(getEtudeData.toString()),
        }));
      setTimeout(() => {
        setLoading(false);
        window.print();
      }, 1000);
    },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{ margin: "20px 0", width: "calc(100% - 40px)", padding: "0 20px" }}
    >
      <ImportFileInput
        ext={["jpg", "jpeg", "png"]}
        size="small"
        file={file}
        setFile={setFile}
        title={"Sélectionner une photo de calpinage..."}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          gap: { xs: "0px", sm: "0px", md: "20px" },
          width: "100%",
        }}
      >
        <TextField
          margin="dense"
          fullWidth
          id="power"
          label="Puissance"
          type="number"
          name="power"
          autoComplete="power"
          value={formik.values.power}
          onChange={formik.handleChange}
          error={formik.touched.power && Boolean(formik.errors.power)}
          helperText={formik.touched.power && formik.errors.power?.toString()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">KWH</InputAdornment>
            ),
          }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="augmentationAnnuel"
          type="number"
          label="Augmentation annuel"
          name="augmentationAnnuel"
          autoComplete="augmentationAnnuel"
          value={formik.values.augmentationAnnuel}
          onChange={formik.handleChange}
          error={
            formik.touched.augmentationAnnuel &&
            Boolean(formik.errors.augmentationAnnuel)
          }
          helperText={
            formik.touched.augmentationAnnuel &&
            formik.errors.augmentationAnnuel?.toString()
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          gap: { xs: "0px", sm: "0px", md: "20px" },
        }}
      >
        <TextField
          margin="dense"
          fullWidth
          type="number"
          id="perte"
          label="Perte"
          name="perte"
          autoComplete="perte"
          value={formik.values.perte}
          onChange={formik.handleChange}
          error={formik.touched.perte && Boolean(formik.errors.perte)}
          helperText={formik.touched.perte && formik.errors.perte?.toString()}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <TextField
          margin="dense"
          fullWidth
          type="number"
          id="systemcost"
          label="Coût de l'installation"
          name="systemcost"
          autoComplete="systemcost"
          value={formik.values.systemcost}
          onChange={formik.handleChange}
          error={formik.touched.systemcost && Boolean(formik.errors.systemcost)}
          helperText={
            formik.touched.systemcost && formik.errors.systemcost?.toString()
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
        />
      </Box>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 2, mb: 1, color: "white", height: "55px" }}
      >
        {loading ?
        <CircularProgress sx={{color: 'white'}} size="34px" />
        :
        `Générer l'étude`}
        
      </Button>
      {EtudeData && (
        <div className="test_root">
          <div className="etude_root_div">
              <EtudeContent EtudeData={EtudeData} />
          </div>
        </div>
      )}
    </Box>
  );
};

export default EtudeGeneratorForm;
