import React, {useRef, useEffect} from 'react'
import blabla from "../../assets/pexels-los-muertos-crew-8853502.jpg"
import { ParallaxBanner, ParallaxBannerLayer, ParallaxProvider } from 'react-scroll-parallax'
const ParallaxBox = ({imgUrl, child, aspectRatio = '3 / 1'} : any) => {

     
  return (
    <ParallaxProvider>
      <ParallaxBanner className="parallax-banner" style={{ aspectRatio: `${aspectRatio}` }} layers={[{image: blabla, speed: -20}, {
        children: child
      }]} />
    </ParallaxProvider>
  )
}

export default ParallaxBox;
