import React, { useEffect, useState } from "react";
import { Box, Typography, Skeleton, Link } from "@mui/material";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import DescriptionIcon from "@mui/icons-material/Description";
import TaskIcon from "@mui/icons-material/Task";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const Stepper = ({ setStepperStep, variant, step, mt, user_id = "" }: any) => {
  const isAdmin = variant ? true : false;
  const handleStepClick = (clickable: Boolean, step: number) => {
    if (clickable && step) {
      setStepperStep(step);
    }
  };

  const CheckPoint = (props_b: any) => {
    return (
      <Box
        className={"flexCenter"}
        onClick={() => {
          handleStepClick(
            (props_b.etat === "checked" || props_b.etat === "checking") &&
              isAdmin,
            props_b.step
          );
        }}
        sx={{
          borderRadius: "50%",
          backgroundColor:
            props_b.etat === "checked" ? "primary.main" : "white",
          border: "3px solid",
          borderColor:
            props_b.etat === "checked"
              ? "primary.main"
              : props_b.etat === "checking"
              ? "secondary.main"
              : "customGrey.grey4",
          height: "50px",
          width: "50px",
          "&:hover": {
            opacity:
              (props_b.etat === "checked" || props_b.etat === "checking") &&
              isAdmin
                ? "0.8"
                : "1",
            cursor:
              (props_b.etat === "checked" || props_b.etat === "checking") &&
              isAdmin
                ? "pointer"
                : "auto",
          },
        }}
      >
        {props_b.step === 1 && (
          <PermContactCalendarIcon
            sx={{
              fontSize: "24px",
              color:
                props_b.etat === "checked"
                  ? "white"
                  : props_b.etat === "checking"
                  ? "secondary.main"
                  : "customGrey.grey4",
            }}
          />
        )}
        {props_b.step === 2 && (
          <ElectricalServicesIcon
            sx={{
              fontSize: "24px",
              color:
                props_b.etat === "checked"
                  ? "white"
                  : props_b.etat === "checking"
                  ? "secondary.main"
                  : "customGrey.grey4",
            }}
          />
        )}
        {props_b.step === 3 && (
          <DescriptionIcon
            sx={{
              fontSize: "24px",
              color:
                props_b.etat === "checked"
                  ? "white"
                  : props_b.etat === "checking"
                  ? "secondary.main"
                  : "customGrey.grey4",
            }}
          />
        )}
        {props_b.step === 4 && (
          <TaskIcon
            sx={{
              fontSize: "24px",
              color:
                props_b.etat === "checked"
                  ? "white"
                  : props_b.etat === "checking"
                  ? "secondary.main"
                  : "customGrey.grey4",
            }}
          />
        )}
        {props_b.step === 5 && (
          <TaskIcon
            sx={{
              fontSize: "24px",
              color:
                props_b.etat === "checked"
                  ? "white"
                  : props_b.etat === "checking"
                  ? "secondary.main"
                  : "customGrey.grey4",
            }}
          />
        )}
      </Box>
    );
  };

  const Line = (props: any) => {
    return (
      <Box
        sx={{
          height: "3px",
          flex: "1",
          margin: "0 15px",
          borderRadius: "20px",
          backgroundColor:
            props.etat === "checked"
              ? "primary.main"
              : props.etat === "checking"
              ? "secondary.main"
              : "customGrey.grey4",
        }}
      ></Box>
    );
  };

  let items = [];
  const itemsLenght = step < 2 ? 2 : step;
  for (let i = 0; i <= itemsLenght; i++) {
    items.push(
      <CheckPoint
        key={`check${i}`}
        etat={i === step - 1 ? "checking" : i < step ? "checked" : "waiting"}
        step={i + 1}
      />
    );
    if (i !== itemsLenght) {
      items.push(
        <Line key={`line${i}`} etat={i < step - 1 ? "checked" : "waiting"} />
      );
    }
  }

  if (step) {
    return (
      <Box
        sx={{
          display: "flex",
          maxWidth: "1154px",
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          href={`/dashboard/${user_id === "" ? "" : `user/${user_id}`}`}
          sx={{
            height: "100px",
            borderRadius: "20px",
            marginRight: "20px",
            width: "60px",
            backgroundColor: "#41d57c",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArrowBackIosNewIcon
            sx={{
              width: "75px",
              fontSize: "35px",
              color: "white",
            }}
          />
        </Link>
        <Box
          className={"card"}
          sx={{
            margin: mt ? `${mt} 0 20px 0` : `20px 0 20px 0`,
            height: "100px",
            display: "flex",
            alignItems: "center",
            width: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            {items}
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Skeleton
        className={"wrapper"}
        variant='rectangular'
        animation='wave'
        height={100}
        sx={{ margin: "20px 0", width: "100%", borderRadius: "20px" }}
      />
    );
  }
};

export default Stepper;
