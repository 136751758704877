import {
  Box,
  Typography,
  Skeleton
} from "@mui/material";

export const DashBoardSkeleton = (props: any) => {
  return (
    <Skeleton className="wrapper" variant="rectangular"  animation="wave" height={400} sx={{width: '100%',  borderRadius: '20px'}} />
  );
};

