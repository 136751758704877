import React, { useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import AutoCompleteAdresse from "../../ui/AutoCompleteAdresse";
import { USERS } from "../../../api/USER";
import { RoofSelectionDialog } from "../../ui/RoofSelectionDialog";
import { TileSelectionDialog } from "../../ui/TileSelectionDialog";
import { ReturnSelections } from "../../ui/ReturnSelections";
import { Link } from "@mui/material";
import { ProfilePopOver } from "../../ui/ProfilePopOver";
import TipPopOver from "../../ui/TipPopOver";
const ErrorSelection = () => {
  return <p className='error'>Veuillez choisir un type de toiture</p>;
};

const MesInfos = ({ localData, setLocalData }: any) => {
  const [adresseValue, setAdresseValue] = React.useState(null);
  const [adresseError, setAdresseError] = React.useState(false);
  const [userIsLegal, setUserIsLegal] = React.useState(false);
  const [selectionError, setSelectionError] = React.useState(false);
  const [roof, setRoof] = React.useState({
    roofType: null,
    tileType: null,
    angle: 0,
  });
  const [errorSelector, setErrorSelector] = React.useState("");
  const [openRoofSelection, setOpenRoofSelection] = React.useState(false);
  const [openTileSelection, setOpenTileSelection] = React.useState(false);
  const [popOverTip, setPopOverTip] = React.useState(false);
  const [anchorTip, setAnchorTip] = React.useState(null);
  const formik: any = useFormik({
    initialValues: {
      roof: roof,
    },
    onSubmit: async (formValues, { resetForm }) => {
      if (adresseValue === null) {
        setAdresseError(true);
        return;
      }
      if (
        roof.roofType === null &&
        localData?.project?.project_type === "toiture solaire"
      ) {
        setSelectionError(true);
        return;
      }
      if (
        roof.roofType === "INCLINED" &&
        roof.tileType === null &&
        localData?.project?.project_type === "toiture solaire"
      ) {
        setSelectionError(true);
        return;
      }
      const putProjectRes = await USERS.PUT_PROJECT(localData.project._id, {
        userIsLegal: userIsLegal,
        roof: roof,
        step: 2,
        projectLocation: adresseValue,
      });
      setAdresseValue(null);
      resetForm();
      setLocalData({
        ...localData,
        project: putProjectRes.data,
      });
    },
  });
  const handleAngleChange = (event: any) => {
    setRoof({ ...roof, angle: parseInt(event.target.value) });
  };
  const handleCheckboxChange = (event: any) => {
    setUserIsLegal(event.target.checked);
  };

  const toggleOpenRoofSelection = () => {
    setOpenRoofSelection((isOpen) => !isOpen);
  };
  const toggleOpenTileSelection = () => {
    setOpenTileSelection((isOpen) => !isOpen);
  };

  const togglePopOverTip = () => {
    setPopOverTip((isOpen) => !isOpen);
  };

  const handleClickTip = (event: any) => {
    setAnchorTip(event?.currentTarget);
  };
  // Prends en paramètre l'instance de la popup ouvert, la sélection ainsi que l'éventuelle erreur à passer
  const handleClose = (
    itemSelected: any,
    setterPopup: any,
    errorMessage: any
  ) => {
    if (itemSelected !== null) {
      setterPopup(false);
      setErrorSelector("");
      return true;
    } else {
      setErrorSelector(errorMessage);
      return false;
    }
  };

  return (
    <Box sx={{ padding: "20px" }} className={"wrapper card"}>
      <Typography
        variant='h2'
        component='h1'
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontSize: "30px",
          margin: "10px 0 30px 0",
        }}
      >
        Informations Générales
      </Typography>
      <Box
        component='form'
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 2 }}
      >
        <AutoCompleteAdresse
          adresseValue={adresseValue}
          setAdresseValue={setAdresseValue}
          adresseError={adresseError}
          setAdresseError={setAdresseError}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            gap: { xs: "0px", sm: "0px", md: "20px" },
          }}
        >
          <Box sx={{ width: 1 }}>
            {localData?.project?.project_type === "toiture solaire" && (
              <Button
                variant='outlined'
                sx={{
                  width: 1,
                  marginTop: "7px",
                  borderColor: selectionError ? "red" : "",
                }}
                onClick={() => toggleOpenRoofSelection()}
              >
                <ReturnSelections
                  selectedRoof={roof.roofType}
                  selectedTile={roof.tileType}
                  selectionError={selectionError}
                  notAppliedText={"Veuillez saisir une toiture"}
                />
              </Button>
            )}
            {selectionError && <ErrorSelection />}
          </Box>
          <RoofSelectionDialog
            roof={roof}
            setRoof={setRoof}
            setOpenTileSelection={setOpenTileSelection}
            errorSelector={errorSelector}
            setErrorSelector={setErrorSelector}
            handleClose={handleClose}
            open={openRoofSelection}
            onClose={toggleOpenRoofSelection}
            setOpenRoofSelection={setOpenRoofSelection}
            nextPopup={setOpenTileSelection}
          />
          <TileSelectionDialog
            setSelectionError={setSelectionError}
            setErrorSelector={setErrorSelector}
            handleClose={handleClose}
            roof={roof}
            setRoof={setRoof}
            errorSelector={errorSelector}
            open={openTileSelection}
            setOpenTileSelection={setOpenTileSelection}
            prevPopUp={setOpenRoofSelection}
            onClose={toggleOpenTileSelection}
          />

          {roof.roofType === "INCLINED" && (
            <TextField
              sx={{ height: "50px" }}
              margin='dense'
              type='number'
              fullWidth
              id='roofAngle'
              label='Angle du toit'
              name='roofAngle'
              autoComplete='roofAngle'
              defaultValue={roof.angle}
              onChange={handleAngleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>Deg°</InputAdornment>
                ),
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "-14px",
          }}
        >
          <Checkbox
            sx={{
              color: "#ddd",
              "& .MuiSvgIcon-root": { fontSize: 48 },
              ".Mui-focusVisible &": {
                outline: "2px auto rgba(19,124,189,.6)",
                outlineOffset: 2,
              },
            }}
            checked={userIsLegal}
            onChange={handleCheckboxChange}
          />
          <Typography>
            Avez-vous rempli vos obligations légales (
            <Link
              sx={{ color: "black", cursor: "pointer" }}
              onClick={(e) => {
                togglePopOverTip();
                handleClickTip(e);
              }}
            >
              décret tertiaire
            </Link>
            ,{" "}
            <Link
              sx={{ color: "black", cursor: "pointer" }}
              onClick={(e) => {
                togglePopOverTip();
                handleClickTip(e);
              }}
            >
              loi ENR
            </Link>
            ,{" "}
            <Link
              sx={{ color: "black", cursor: "pointer" }}
              onClick={(e) => {
                togglePopOverTip();
                handleClickTip(e);
              }}
            >
              loi climat
            </Link>
            ) ?
          </Typography>
          <TipPopOver
            sx={{ position: "absolute", top: 0 }}
            open={popOverTip}
            onClose={togglePopOverTip}
            anchorEl={anchorTip}
          />
        </Box>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 4, mb: 1, color: "white", height: "55px" }}
        >
          Enregistrer
        </Button>
      </Box>
    </Box>
  );
};

export default MesInfos;
