import axios from 'axios';

export const ADMIN_SELLER = {
GET_SELLERS: async function () {
    return (axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/sellers`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},
IS_CONNECTED: async function (){
    return (axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/api/seller/token/`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        
    }).then(function (res) {						
        return res.status === 200;
    })).catch((err) => console.log(err))
},
POST_REGISTER: async function (data: any) {
    return (axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/seller`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data : {
            password: data.password,
            email: data.email,
            phone: data.phone,
            name: data.name,
            family_name: data.family_name,
        }
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},
PUT_SELLER: async function (id: string, seller: any) {
    return (axios({
        method: 'put',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/seller`,
        data: {
            user_id: id,
            user: JSON.stringify(seller),
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

DELETE_SELLER: async function (id: string) {
    return (axios({
        method: 'delete',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/seller`,
        data: {
            user_id: id,
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},
}