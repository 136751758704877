import {
  Box,
  Skeleton,
  Typography,
  Fab,
  Badge,
  IconButton,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import React, { useRef, useEffect, useState, useContext } from "react";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import Header from "../ui/header";
import ImportFileInput from "../ui/importFileInput";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCard from "../ui/FileCard";
import { SocketContext } from "../../hooks/sockets/index";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { USERS } from "../../api/USER";

function Chat({ user, name, project_id, user_id, API }: any) {
  const socket = useContext(SocketContext);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [badgeNb, setBadgeNb] = useState<number>(0);
  const [conversation, setConversation] = useState<any>(null);
  const { data: result, isLoading } = useQuery(["chat"], async () => {
    const convResponse = await API.GET_CHAT_BY_PROJECT_ID(project_id);
    socket.emit("joinRoom", { project_id: project_id }, (response: any) => {});
    setConversation(convResponse.data);
    return convResponse;
  });
  useEffect(() => {
    socket.on("newMessage", (message: any) => {
      setConversation((prevState: any) => ({
        ...prevState,
        history: prevState.history.concat(message),
      }));
      setBadgeNb((prevState: any) => prevState + 1);
    });
  }, [socket]);
  useEffect(() => {
    return () => {
      socket.emit("leaveRoom", { project_id: project_id }, (response: any) => {
        console.log(response);
      });
    };
  }, []);

  const MessageActionButton = () => {
    return (
      <Box
        sx={{
          bottom: "220px",
          position: "fixed",
          right: "30px",
          zIndex: "100",
        }}
        onClick={() => {
          setBadgeNb(0);
          setIsOpened(true);
        }}
      >
        <Badge
          color='secondary'
          overlap='circular'
          badgeContent={badgeNb}
          max={99}
        >
          <Fab
            color='primary'
            aria-label='add'
            sx={{ width: "56px", height: "56px", zIndex: "1" }}
          >
            <MessageIcon sx={{ color: "white" }} />
          </Fab>
        </Badge>
      </Box>
    );
  };

  const Header = () => {
    return (
      <Box
        sx={{
          padding: "0px 10px",
          backgroundColor: "secondary.main",
          width: 1,
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <PersonIcon sx={{ color: "white", fontSize: "32px" }} />
          <Typography sx={{ color: "white", fontSize: "16px" }}>
            {name}
          </Typography>
        </Box>
        <IconButton
          aria-label='delete'
          sx={{ color: "white" }}
          onClick={() => {
            setIsOpened(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    );
  };

  const TypeMessage = () => {
    const [file, setFile] = useState<any>(null);
    const [message, setMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const handleSendMessage = () => {
      const now = dayjs().valueOf();
      if (file) {
        setLoading(true);
        API.UPLOAD_CHAT_DOC(project_id, file.name, file).then((res: any) => {
          if (res === "success") {
            socket.emit(
              "sendMessage",
              {
                project_id: project_id,
                content: file.name,
                type: "FILE",
                date: dayjs().valueOf(),
              },
              (response: any) => {
                if (response.status === 200) {
                  setConversation((prevState: any) => ({
                    ...prevState,
                    history: prevState.history.concat({
                      user_id: user_id,
                      content: file.name,
                      user_role: user,
                      type: "FILE",
                      date: now,
                    }),
                  }));
                  setFile(null);
                  setLoading(false);
                }
              }
            );
          }
        });
      } else if (message) {
        setLoading(true);
        socket.emit(
          "sendMessage",
          {
            project_id: project_id,
            content: message,
            type: "TEXT",
            date: now,
          },
          (response: any) => {
            if (response.status === 200) {
              setConversation((prevState: any) => ({
                ...prevState,
                history: prevState.history.concat({
                  user_id: user_id,
                  user_role: user,
                  content: message,
                  type: "TEXT",
                  date: now,
                }),
              }));
              setMessage("");
              setLoading(false);
            }
          }
        );
      }
    };

    const handleSelectFile = (e: any) => {
      const selectedFile = e.target.files[0];
      setMessage("");
      setFile(selectedFile);
    };
    const handleRemoveFile = () => {
      setFile(null);
    };
    return (
      <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
        {file ? (
          <Box sx={{ margin: "10px 0 10px 10px", width: "calc(100% - 80px)" }}>
            <FileCard
              fileName={file.name}
              width={1}
              height={"101px"}
              variant={"display"}
              handleRemoveFile={() => handleRemoveFile()}
            />
          </Box>
        ) : (
          <TextField
            placeholder='écrire un message'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMessage(event.target.value);
            }}
            value={message}
            multiline
            sx={{
              width: "calc(100% - 80px)",
              margin: "10px 0 10px 10px",
              "& fieldset": {
                borderRadius: "10px",
              },
            }}
            rows={4}
          />
        )}
        <Box
          sx={{
            height: "100%",
            width: "50px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            sx={{
              borderRadius: "10px",
              margin: "10px 10px 10px 0",
              height: "calc(100% - 67px)",
              width: "20px",
              padding: "0",
              minWidth: "50px",
            }}
            variant='contained'
            onClick={handleSendMessage}
            endIcon={
              loading ? (
                <CircularProgress
                  sx={{ marginLeft: "-12px", color: "white" }}
                  size='24px'
                />
              ) : (
                <SendIcon sx={{ marginLeft: "-10px", color: "white" }} />
              )
            }
          ></Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              backgroundColor: "white",
              padding: "0px",
              margin: "0",
              borderRadius: "10px",
            }}
          >
            <Button
              variant={"contained"}
              sx={{
                color: "#fff",
                marginTop: "0px",
                minWidth: "50px",
                borderRadius: "10px",
                backgroundColor: "customGrey.grey5",
              }}
              component='label'
            >
              <AttachFileIcon sx={{}} />
              <input
                type='file'
                accept={".jpg, .png, .jpeg, .pdf, .docx, .xlsx, .pptx"}
                onChange={handleSelectFile}
                hidden
              />
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  const Conversation = () => {
    const Messages = ({ variant, from, text }: any) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: from === "me" ? "flex-end" : "flex-start",
            margin: `0 ${from === "me" ? "10px" : "30px"} 0 ${
              from === "me" ? "30px" : "10px"
            }}`,
          }}
        >
          {variant === "TEXT" ? (
            <Box
              sx={{
                backgroundColor:
                  from === "me" ? "secondary.main" : "customGrey.grey3",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <Typography
                sx={{
                  color: from === "me" ? "white" : "black",
                }}
                variant='body2'
              >
                {text}
              </Typography>
            </Box>
          ) : (
            variant === "FILE" && (
              <FileCard
                download={{
                  download_api: API.DOWNLOAD_CHAT,
                  project_id: project_id,
                  file_name: text,
                }}
                fileName={text}
                width={"80%"}
                variant={"download"}
              />
            )
          )}{" "}
        </Box>
      );
    };
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (divRef.current !== null) {
        divRef.current.scrollTop = divRef.current.scrollHeight;
      }
    }, []);

    return (
      <Box
        ref={divRef}
        sx={{
          width: "100%",
          display: "flex",
          padding: "10px 0 0 0",
          gap: "10px",
          flexDirection: "column",
          overflowY: "scroll",
          height: "calc(100% - 201px)",
        }}
      >
        {conversation ? (
          conversation.history.map((message: any, i1: number) => (
            <Box key={i1}>
              {user === "ADMIN" && (
                <Messages
                  variant={message.type}
                  text={message.content}
                  from={
                    message?.user_role
                      ? message.user_role === user
                        ? "me"
                        : "other"
                      : message.user_id === user_id
                      ? "other"
                      : "me"
                  }
                />
              )}
              {user === "USER" && (
                <Messages
                  variant={message.type}
                  text={message.content}
                  from={message.user_id === user_id ? "me" : "other"}
                />
              )}
            </Box>
          ))
        ) : (
          <Box>loading</Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {isOpened ? (
        <Box
          className='card'
          sx={{
            zIndex: "100",
            overflow: "hidden",
            width: "400px",
            height: "500px",
            bottom: "220px",
            position: "fixed",
            right: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            justifyContent: "space-between",
          }}
        >
          <Header />
          <Conversation />
          <TypeMessage />
        </Box>
      ) : (
        <MessageActionButton />
      )}
    </Box>
  );
}

export default Chat;
