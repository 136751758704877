import React,{useState,useEffect,useContext} from 'react';

function Ressource() {
/*   -------------------- socket state ------------------------  */

    return (    
      <div className='page_ressource'>
        ressource
      </div>  
    );

}

export default Ressource;