import React, { useEffect } from "react";
import {
  Drawer,
  IconButton,
  Button,
  Toolbar,
  Box,
  AppBar,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Link as MUILink } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Logo from "../../assets/optimum_solar_logo.png";
import { dark } from "@mui/material/styles/createPalette";
import { useQuery } from "react-query";
import { USERS } from "../../api/USER";
import { ProfilePopOver } from "./ProfilePopOver";
import { ADMIN } from "../../api/ADMIN";
import { ADMIN_USER } from "../../api/ADMIN_USER";
import { ADMIN_SELLER } from "../../api/ADMIN_SELLER";
export default function Navigation(props: any) {
  const Pages = [
    {
      name: "Solutions",
      link: "/solutions",
    },
    {
      name: "Législatif",
      link: "/legislatif",
    },
    {
      name: "Ressource",
      link: "https://blog.optimumsolar.fr/",
    },
    {
      name: "Connexion",
      link: "/login",
    },
  ];

  const [state, setState] = React.useState({ open: false });
  const [logged, setLogged] = React.useState({ logged: false, role: "USER" });
  const storedToken = localStorage.getItem("token");
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [popOverProfile, setPopOverProfile] = React.useState(false);
  let API: any = USERS;
  useQuery<any, Error>("isLogged", async () => {
    if (storedToken) {
      const role = jwt_decode<any>(storedToken).user.role;

      if (role === "ADMIN") {
        API = ADMIN;
      } else if (role === "SELLER") {
        API = ADMIN_SELLER;
      }
      setLogged({ logged: await API.IS_CONNECTED(), role: role });
    }
  });

  // React.useEffect(() => {
  //   storedToken &&
  //     setLogged({
  //       logged: true,
  //       role: (storedToken && jwt_decode<any>(storedToken).user.role) || "USER",
  //     });
  // }, []);

  const handleCloseProfile = () => {
    setPopOverProfile(false);
  };
  const openPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopOverProfile((pop) => !pop);
    setAnchorEl(event.target);
  };
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, open });
  };

  const handleLogin = (logged: boolean) => {
    if (
      logged &&
      !window.location.pathname.toLowerCase().startsWith("/dashboard")
    ) {
      window.location.replace("/dashboard");
    } else {
      logged && localStorage.removeItem("token");
      window.location.replace("/login");
    }
  };
  return (
    <AppBar
      position='static'
      color='inherit'
      sx={{
        width: "100%",
        paddingLeft: "20px",
        paddingRight: "20px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 8px 24px",
      }}
    >
      {logged.logged && logged.role === "USER" && (
        <ProfilePopOver
          open={popOverProfile}
          onClose={handleCloseProfile}
          anchorEl={anchorEl}
        />
      )}

      <Toolbar
        sx={{
          padding: "0 !important",
          maxWidth: "1154px",
          width: "100%",
          minHeight: "80px",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ lineHeight: 1 }}>
          <Link to='/'>
            <img alt='logo' src={Logo} width={200} />
          </Link>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NavLink to={`${Pages[0].link}`} style={{ textDecoration: "none" }}>
            <Button
              variant='text'
              sx={{
                marginRight: "50px",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              <Typography sx={{ lineHeight: 1 }} className='router-link'>
                {Pages[0].name}
              </Typography>
            </Button>
          </NavLink>
          <NavLink to={`${Pages[1].link}`} style={{ textDecoration: "none" }}>
            <Button
              variant='text'
              sx={{
                marginRight: "50px",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              <Typography
                sx={{
                  lineHeight: 1,
                  display: { xs: "none", sm: "none", md: "block" },
                }}
                className='router-link'
              >
                {Pages[1].name}
              </Typography>
            </Button>
          </NavLink>
          <NavLink to={`${Pages[2].link}`} style={{ textDecoration: "none" }}>
            <Button
              variant='text'
              sx={{
                marginRight: "50px",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              <Typography
                sx={{
                  lineHeight: 1,
                  display: { xs: "none", sm: "none", md: "block" },
                }}
                className='router-link'
              >
                {Pages[2].name}
              </Typography>
            </Button>
          </NavLink>
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Button
              variant='contained'
              color='primary'
              sx={{
                height: "40px",
                color: "white",
                display: { xs: "none", sm: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                handleLogin(logged.logged);
              }}
            >
              {logged.logged ? (
                window.location.pathname
                  .toLowerCase()
                  .startsWith("/dashboard") ? (
                  <PowerSettingsNewIcon />
                ) : logged.role === "ADMIN" ? (
                  "Administration"
                ) : (
                  "Mon Espace"
                )
              ) : (
                Pages[3].name
              )}
            </Button>
            {logged.role === "USER" && logged.logged && (
              <Button
                variant='contained'
                sx={{
                  height: "40px",
                  color: "white",
                  alignItems: "center",
                  display: { xs: "none", sm: "none", md: "flex" },
                  justifyContent: "center",
                }}
                onClick={openPopOver}
              >
                <PersonIcon />
              </Button>
            )}
          </Box>
          <IconButton
            edge='start'
            color='primary'
            aria-label='menu'
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
            }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Drawer anchor='left' open={state.open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingRight: "30px",
            width: "300px",
          }}
        >
          <Box mb={1} ml={2} pb={2}>
            <Link to='/' color='primary'>
              <img alt='logo' src={Logo} width={200} />
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: 2,
              gap: 2,
            }}
          >
            <MUILink
              href={Pages[0].link}
              color='textPrimary'
              variant='body2'
              borderColor='#DDD'
              sx={{
                marginRight: "50px",
                fontSize: "1.3rem",
                textDecoration: "none",
                borderTop: 1,
                width: 1,
                borderColor: "#DDD",
                paddingTop: "10px",
              }}
            >
              <Button variant='text'>{Pages[0].name}</Button>
            </MUILink>
            <MUILink
              href={Pages[1].link}
              color='textPrimary'
              variant='body2'
              sx={{
                marginRight: "50px",
                fontSize: "1.3rem",
                textDecoration: "none",
                borderTop: 1,
                width: 1,
                borderColor: "#DDD",
                paddingTop: "10px",
              }}
            >
              <Button variant='text'>{Pages[1].name}</Button>
            </MUILink>
            <MUILink
              href={Pages[2].link}
              color='textPrimary'
              variant='body2'
              sx={{
                marginRight: "50px",
                fontSize: "1.3rem",
                textDecoration: "none",
                borderTop: 1,
                width: 1,
                borderColor: "#DDD",
                paddingTop: "10px",
              }}
            >
              <Button variant='text'>{Pages[2].name}</Button>
            </MUILink>
          </Box>
          <Box
            ml={2}
            borderBottom={0}
            borderLeft={0}
            borderRight={0}
            borderColor='#DDD'
          >
            <MUILink>
              <Button
                variant='contained'
                color='primary'
                sx={{ color: "white", width: 0.5 }}
              >
                {logged.logged ? (
                  window.location.pathname
                    .toLowerCase()
                    .startsWith("/dashboard") ? (
                    <PowerSettingsNewIcon />
                  ) : logged.role === "ADMIN" ? (
                    "Administration"
                  ) : (
                    "Mon Espace"
                  )
                ) : (
                  Pages[3].name
                )}
              </Button>
            </MUILink>
            {logged.role === "USER" && logged.logged && (
              <>
                <Button
                  variant='contained'
                  sx={{
                    width: 0.5,
                    color: "white",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={(e) => openPopOver(e)}
                >
                  <PersonIcon />
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Drawer>
    </AppBar>
  );
}
