import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export const MyChart = ({ data }: any) => {
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef.current) {
      const myChart = new Chart(chartRef.current, {
        type: "bar",
        data: {
          labels: [
            "Jan",
            "Fév",
            "Mar",
            "Avr",
            "Mai",
            "Jui",
            "Jui",
            "Aoû",
            "Sep",
            "Oct",
            "Nov",
            "Déc",
          ],
          datasets: [
            {
              backgroundColor: "#02245b",
              data: data?.monthlyBilan.map((moi: any) => moi.prod) || [],
              label: "Production",
            },
            {
              backgroundColor: "#FFA500",
              data: data?.monthlyBilan.map((moi: any) => moi.conso) || [],
              label: "Consommation",
            },
          ],
        },
        // options: {
        //   legend: {
        //     display: true,
        //   },
        // },
      });

      return () => {
        myChart.destroy();
      };
    }
  }, [data]);

  return <canvas id='myChart' ref={chartRef} />;
};

export const MyChart2 = ({ data }: any) => {
    const chartRef = useRef(null);
  
    useEffect(() => {
      if (chartRef.current) {
        const yValues2 = data.fixed.map((moi: any) => moi["H(i)_m"]);
  
        const myChart2 = new Chart(chartRef.current, {
          type: "bar",
          data: {
            labels: [
              "Jan",
              "Fév",
              "Mar",
              "Avr",
              "Mai",
              "Jui",
              "Jui",
              "Aoû",
              "Sep",
              "Oct",
              "Nov",
              "Déc",
            ],
            datasets: [
              {
                backgroundColor: "#32c36c",
                data: yValues2,
                label: "Irridation",
              },
            ],
          },
        //   options: {
        //     legend: {
        //       display: false,
        //     },
        //   },
        });
  
        return () => {
          myChart2.destroy();
        };
      }
    }, [data]);
  
    return <canvas id='myChart' ref={chartRef} />;
  };

