import { useFormik } from "formik";
import PersonIcon from "@mui/icons-material/Person";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Link,
} from "@mui/material/";
import { USERS } from "../../../api/USER";

export const FirstProject = ({
  user,
  setUser,
  projects,
  setReadyFirstProj,
}: any) => {
  const [isSiretValid, setIsSiretValid] = useState(true);
  const [changed, setChanged] = useState(false);

  const phoneReg =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const formikSchema = yup.object({
    fonction: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-/.]+$/, `fonction invalide`)
      .min(2, `fonction trop courte !`)
      .max(100, `fonction trop longue !`)
      .required(`Veuillez renseigner votre fonction`),
    phone: yup
      .string()
      .matches(phoneReg, `Numéro invalide`)
      .required(`Veuillez renseigner votre numéro de téléphone`),
    siret: yup.string().matches(/^\d{14}$/, `Siret invalide`),
    name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-/.]+$/, `Prénom invalide`)
      .required("Veuillez saisir votre prénom"),
    family_name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-/.]+$/, `Nom de famille invalide`)
      .required("Veuillez saisir votre nom de famille"),
  });
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      fonction: user.fonction || ``,
      phone: user.phone || ``,
      siret: user.etablissement?.siret || ``,
      name: user.name || ``,
      family_name: user.family_name || ``,
      situation: user?.isPro ? "Professionnel" : "Particulier",
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      if (!formValues.siret || formik.values.situation === "Particulier") {
        // S'il a été supprimer, on l'enlève
        const putUserRes = await USERS.PUT_USER({
          fonction: formValues.fonction,
          phone: formValues.phone,
          etablissement: {},
          name: formValues.name,
          family_name: formValues.family_name,
          isPro: false,
        });
        setUser(putUserRes.data);
        setChanged(true);
        resetForm();
        goToProj();
      } else {
        const res = await USERS.GET_PROBYSIRET(formValues.siret);
        if (res) {
          setIsSiretValid(true);
          const putUserRes = await USERS.PUT_USER({
            fonction: formValues.fonction,
            phone: formValues.phone,
            etablissement: res.etablissement,
            name: formValues.name,
            family_name: formValues.family_name,
            isPro: true,
          });
          setUser(putUserRes.data);
          setChanged(true);
          setReadyFirstProj(true);
          resetForm();
          goToProj();
        } else {
          setIsSiretValid(false);
        }
      }
    },
  });
  const goToProj = () => {
    if (
      projects.length === 0 &&
      formik.values.fonction &&
      formik.values.phone
    ) {
      setReadyFirstProj(true);
    }
  };
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography variant='h2'>
        Veuillez renseigner vos informations avant de commencer un projet
      </Typography>

      <Box
        sx={{
          backgroundColor: "white",
          marginTop: "50px",
          padding: "30px",
          borderRadius: "20px",
        }}
      >
        <Box sx={{ width: "400px", margin: "30px" }}>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PersonIcon
                sx={{
                  marginRight: "10px",
                  fontSize: "25px",
                }}
              />
              <Typography variant='h2' sx={{ fontSize: "20px" }}>
                Mes informations complémentaires
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: "0px", sm: "0px", md: "20px" },
                marginTop: "15px",
              }}
              component='form'
              onSubmit={formik.handleSubmit}
            >
              <TextField
                margin='dense'
                fullWidth
                id='fonction'
                label='Fonction'
                name='fonction'
                autoComplete='fonction'
                value={formik.values.fonction}
                onChange={formik.handleChange}
                error={
                  formik.touched.fonction && Boolean(formik.errors.fonction)
                }
                helperText={
                  formik.touched.fonction && formik.errors.fonction?.toString()
                }
              />
              <TextField
                margin='dense'
                fullWidth
                id='phone'
                label='Téléphone'
                name='phone'
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={
                  formik.touched.phone && formik.errors.phone?.toString()
                }
                autoComplete='phone'
              />
              <FormControl fullWidth>
                <InputLabel id='situation-label'>Vous êtes</InputLabel>
                <Select
                  labelId='situation-label'
                  id='situation'
                  name='situation'
                  value={formik.values.situation}
                  label='situation'
                  onChange={formik.handleChange}
                  sx={{ marginTop: "8px", marginBottom: "4px" }}
                >
                  <MenuItem value={"Particulier"}>Particulier</MenuItem>
                  <MenuItem value={"Professionnel"}>Professionnel</MenuItem>
                </Select>
              </FormControl>
              {formik.values.situation === "Professionnel" && (
                <TextField
                  margin='dense'
                  fullWidth
                  id='siret'
                  label='Siret Entreprise (sans espace)'
                  name='siret'
                  autoComplete='siret'
                  value={formik.values.siret}
                  onChange={formik.handleChange}
                  error={
                    (formik.touched.siret && Boolean(formik.errors.siret)) ||
                    !isSiretValid
                  }
                  helperText={
                    (formik.touched.siret && formik.errors.siret?.toString()) ||
                    (!isSiretValid && "Aucune entreprise trouvée avec ce siret")
                  }
                />
              )}
              {changed && (
                <Typography
                  variant='h3'
                  color='primary'
                  sx={{ textAlign: "center" }}
                >
                  Vos informations ont été sauvegardées.
                </Typography>
              )}
              <Button type='submit' variant='contained' sx={{ color: "white" }}>
                Confirmer
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
