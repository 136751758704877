export const SignDocs = [
  {
    exist: false,
    file_name: "Devis",
    label: "Le Devis",
    type: "raw",
    ext: ["pdf"],
  },
  {
    exist: false,
    file_name: "Contrat_Achat",
    label: "Le Contrat d'achat",
    type: "raw",
    ext: ["pdf"],
  },
  {
    exist: false,
    file_name: "Contrat_Maintenance",
    label: "Le Contrat de maintenance",
    type: "raw",
    ext: ["pdf"],
  },
  {
    exist: false,
    file_name: "Devis_Signe",
    label: "Le Devis signé",
    type: "signed",
    ext: ["pdf"],
  },
  {
    exist: false,
    file_name: "Contrat_Achat_Signe",
    label: "Le Contrat d'achat signé",
    type: "signed",
    ext: ["pdf"],
  },
  {
    exist: false,
    file_name: "Contrat_Maintenance_Signe",
    label: "Le Contrat de maintenance signé",
    type: "signed",
    ext: ["pdf"],
  },
];

export const ProjectDocs = [
  {
    exist: false,
    file_name: "CNI_recto",
    label: "le recto de votre CNI",
    ext: ["png", "jpg", "jpeg", "pdf"],
  },
  {
    exist: false,
    file_name: "CNI_verso",
    label: "le verso de votre CNI",
    ext: ["png", "jpg", "jpeg", "pdf"],
  },
  {
    exist: false,
    file_name: "KBIS",
    label: "le KBIS de votre entreprise",
    ext: ["png", "jpg", "jpeg", "pdf"],
  },
  {
    exist: false,
    file_name: "mandat_CHM",
    label: "Le mandat CHM",
    ext: ["pdf"],
  },
  {
    exist: false,
    file_name: "mandat_ENEDIS",
    label: "Le mandat ENEDIS",
    ext: ["pdf"],
  },
  {
    exist: false,
    file_name: "plan_coupe",
    label: "le plan de coupe",
    ext: ["png", "jpg", "jpeg", "pdf"],
  },
  {
    exist: false,
    file_name: "plan_masse",
    label: "le plan de masse",
    ext: ["png", "jpg", "jpeg", "pdf"],
  },
  {
    exist: false,
    file_name: "avis_taxe_fonciere",
    label: "l'avis de taxe fonciere",
    ext: ["pdf"],
  },
  {
    exist: false,
    file_name: "photo_TGBT",
    label: "la photo TGBT",
    ext: ["png", "jpg", "jpeg", "pdf"],
  },
  {
    exist: false,
    file_name: "photo_Linky",
    label: "la photo du compteur Linky",
    ext: ["png", "jpg", "jpeg", "pdf"],
  },
];
