import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { StateMessage } from "../../ui/stateMessage";
import FileCard from "../../ui/FileCard";
import { USERS } from "../../../api/USER";
import { RadioCard, RadioCardItem } from "../../ui/RadioCard";

const EtudeProjet = ({ localData, setLocalData }: any) => {
  const [isDocExist, setIsDocExist] = useState(false);
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedCardError, setSelectedCardError] = useState("");

  USERS.DOC_CHECK(localData.project._id, [
    { file_name: "EtudeSolaire.pdf" },
  ]).then((res) => {
    if (res.data[0].exist) {
      setIsDocExist(true);
    }
  });
  const HandleValidateEtude = async () => {
    if (selectedCard !== 0 && selectedCard !== 1) {
      setSelectedCardError("Veuillez choisir un type de financement");
    } else {
      const putProjectRes = await USERS.PUT_PROJECT(localData.project._id, {
        step: 4,
        financement: selectedCard === 0 ? false : true,
      });
      putProjectRes &&
        setLocalData({ ...localData, project: putProjectRes.data });
      setSelectedCardError("");
    }
  };

  function handleCardSelection(cardIndex: any) {
    setSelectedCardError("");
    setSelectedCard(cardIndex);
  }

  const EtudeWaiting = () => {
    return (
      <StateMessage
        message={
          "L'étude est en cours de création, revenez un peu plus tard pour pouvoir la consulter."
        }
        quote={"Nous prenons en général 1 à 2 jours ouvrés pour créer l'étude."}
        icon={"waiting"}
        mt={"40px"}
        mb={"0px"}
      />
    );
  };
  const EtudeEnCours = () => {
    return (
      <Box>
        <StateMessage
          message={
            "L'étude à bien été crée, vous pouvez la télécharger ci-dessous pour la consulter."
          }
          quote={
            "N'hésitez pas à utiliser la chat si vous avez des questions ou des remarques."
          }
          mt={"40px"}
          mb={"0px"}
          component={
            <FileCard
              variant={"download"}
              download={{
                download_api: USERS.DOC_DOWNLOAD,
                file_name: "EtudeSolaire.pdf",
                project_id: localData.project._id,
              }}
              fileName={"EtudeSolaire.pdf"}
            />
          }
        />
        <RadioCard
          text={"Choisissez votre type de financement"}
          selectedCard={selectedCard}
          handleCardSelection={handleCardSelection}
          selectedCardError={selectedCardError}
        >
          <RadioCardItem>
            <Typography sx={{ color: `secondary.main` }} variant='h3'>
              Sans financement
            </Typography>
            <Typography>Paiment :</Typography>
            <Typography>- Acompte 30%</Typography>
            <Typography>- Debut travaux 40%</Typography>
            <Typography>- livraison 40%</Typography>
          </RadioCardItem>
          <RadioCardItem>
            <Typography sx={{ color: `secondary.main` }} variant='h3'>
              Avec financement
            </Typography>
            <Typography>
              API calcul avec plateforme M2M pour connaitre mensualité{" "}
            </Typography>
          </RadioCardItem>
        </RadioCard>
        <Button
          fullWidth
          variant='contained'
          sx={{ mt: 4, mb: 1, color: "white", height: "55px" }}
          onClick={() => {
            HandleValidateEtude();
          }}
        >
          Valider l'étude et passer à l'étape suivante
        </Button>
      </Box>
    );
  };

  return (
    <Box sx={{ padding: "20px" }} className={"wrapper card"}>
      <Typography
        variant='h2'
        component='h1'
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontSize: "30px",
          margin: "10px 0 10px 0",
        }}
      >
        Etude de votre projet
      </Typography>
      {isDocExist ? <EtudeEnCours /> : <EtudeWaiting />}
    </Box>
  );
};

export default EtudeProjet;
