import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import DragNDrop from "../../ui/dragNdrop";
import { StateMessage } from "../../ui/stateMessage";
import FileCard from "../../ui/FileCard";
import { SignDocs } from "../../../utils/filesList";
import { DashBoardSkeleton } from "../../ui/Skeleton";

const SignDoc = ({ API, localData }: any) => {
  const [Files, setFiles] = useState(SignDocs);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const filesWithFileNameOnly = [...SignDocs].map(({ file_name }) => ({
      file_name,
    }));
    API.DOC_CHECK(localData.project._id, filesWithFileNameOnly).then(
      (res: any) => {
        if (res.data) {
          const updatedFiles = Files.map((file) => {
            const fileExist = res.data.find(
              (data: any) => data.file_name === file.file_name
            )?.exist;
            return { ...file, exist: fileExist };
          });
          setIsLoading(false);
          setFiles(updatedFiles);
        }
      }
    );
  }, []);

  const handleFileSend = (file_name: string) => {
    setFiles((prevState) =>
      prevState.map((file) =>
        file.file_name === file_name ? { ...file, exist: true } : file
      )
    );
  };

  const isAllFilesSend = !Files.some((file) =>
    file.type === "raw" ? !file.exist : false
  );
  const isAllFilesSignSend = !Files.some((file) =>
    file.type === "signed" ? !file.exist : false
  );

  const FileNotSend = () => {
    const handleRemoveFile = (file_name: string) => {
      API.DOC_DELETE(localData.project._id, file_name).then((res: any) => {
        if (res.data) {
          setFiles((prevState) =>
            prevState.map((file) =>
              file.file_name === file_name ? { ...file, exist: false } : file
            )
          );
        }
      });
    };
    return (
      <StateMessage
        message={
          isAllFilesSend
            ? isAllFilesSignSend
              ? "Tous les documents ont été signés et envoyés."
              : "En attente de la signature du client."
            : "Veuillez envoyer le devis, le contrat d'achat et le contrat de maintenance au client."
        }
        quote={
          isAllFilesSend
            ? isAllFilesSignSend
              ? "Vous pouvez télécharger les documents signés ci-dessous."
              : "Tant que le client n'a pas envoyé le document signé, vous pouvez toujours remplacer les documents."
            : "Utilisez les zones prévu pour déposer chaque fichier."
        }
        icon={
          isAllFilesSend ? (isAllFilesSignSend ? "check" : "waiting") : "upload"
        }
        component={
          Files.some((file) => file.exist) && (
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{ display: "flex", gap: "20px", flexDirection: "column" }}
              >
                {Files.map(
                  (file, i1) =>
                    file.exist &&
                    file.type === "raw" && (
                      <Box key={i1}>
                        <FileCard
                          variant={Files[i1 + 3].exist ? "download" : "both"}
                          download={{
                            download_api: API.DOC_DOWNLOAD,
                            file_name: file.file_name,
                            project_id: localData.project._id,
                          }}
                          fileName={file.file_name}
                          handleRemoveFile={handleRemoveFile}
                        />
                      </Box>
                    )
                )}
              </Box>
              <Box
                sx={{ display: "flex", gap: "20px", flexDirection: "column" }}
              >
                {Files.map(
                  (file, i1) =>
                    file.exist &&
                    file.type === "signed" && (
                      <Box key={i1}>
                        <FileCard
                          border={"2px"}
                          icon={"sign"}
                          variant={file.exist ? "download" : "both"}
                          download={{
                            download_api: API.DOC_DOWNLOAD,
                            file_name: file.file_name,
                            project_id: localData.project._id,
                          }}
                          fileName={file.file_name}
                          handleRemoveFile={handleRemoveFile}
                        />
                      </Box>
                    )
                )}
              </Box>
            </Box>
          )
        }
      />
    );
  };
  return (
    <Box sx={{ width: 1, padding: "20px" }} className={"wrapper card"}>
      <Typography
        variant='h2'
        component='h1'
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontSize: "30px",
          margin: "10px 0 30px 0",
        }}
      >
        Devis & Contrats
      </Typography>

      {isLoading ? (
        <DashBoardSkeleton />
      ) : (
        <Box>
          {<FileNotSend />}
          {Files.map(
            (file, i1) =>
              !file.exist &&
              file.type === "raw" && (
                <Box mb={"20px"} key={i1}>
                  <DragNDrop
                    ext={file.ext}
                    title={file.label}
                    size='small'
                    upload={{
                      upload_api: API.UPLOAD_DOC,
                      file_name: file.file_name,
                      project_id: localData.project._id,
                    }}
                    project_id={localData.project._id}
                    file_name={file.file_name}
                    sendAction={handleFileSend}
                  />
                </Box>
              )
          )}
        </Box>
      )}
    </Box>
  );
};

export default SignDoc;
