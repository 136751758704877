import React from "react";
import { IconButton, Link, Typography, Box, Container } from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../../assets/optimum_solar_logo.png";
import EnedisLogo from "../../assets/enedis_logo.png";
export default function Footer() {
  return (
    <footer style={{ backgroundColor: "white" }}>
      <Container className='wrapper'>
        <Box
          pt={6}
          pb={3}
          display='flex'
          alignItems='center'
          sx={{
            flexDirection: { xs: "column", sm: "column", md: "row" },
            justifyContent: { xs: "center", sm: "center", md: "space-between" },
            gap: "20px",
          }}
        >
          <Link
            href='#'
            color='inherit'
            underline='none'
            display='flex'
            alignItems='center'
          >
            <img alt='logo' src={logo} width={200} />
          </Link>
          <Box
            component='nav'
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              width: { sm: "100%", md: "auto" },
            }}
          >
            <Link
              href='/solutions'
              variant='body1'
              color='textPrimary'
              sx={{ margin: "0 20px", textDecoration: "none" }}
            >
              Solutions
            </Link>
            <Link
              href='/legislatif'
              variant='body1'
              color='textPrimary'
              sx={{ margin: "0 20px", textDecoration: "none" }}
            >
              Législatif
            </Link>
            <Link
              href='/ressource'
              variant='body1'
              color='textPrimary'
              sx={{ margin: "0 20px", textDecoration: "none" }}
            >
              Ressource
            </Link>
          </Box>
        </Box>
        <Box
          pt={2}
          pb={4}
          display='flex'
          flexWrap='wrap'
          alignItems='center'
          sx={{
            borderTopWidth: 1,
            borderTopStyle: "solid",
            borderTopColor: "customGrey.grey3",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              color='textSecondary'
              component='p'
              variant='caption'
              gutterBottom={false}
              sx={{}}
            >
              © 2023 Optimum Solar. All rights reserved.
            </Typography>
            <Link href='/mentions-legales' sx={{ textDecoration: "none" }}>
              <Typography
                color='textSecondary'
                component='p'
                variant='caption'
                gutterBottom={false}
                sx={{ ":hover": { color: "GrayText" } }}
              >
                Mentions légales.
              </Typography>
            </Link>
          </Box>
          <Box ml='auto' sx={{ display: "flex" }}>
            <Box
              sx={{
                marginRight: "30px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img src={EnedisLogo} className='enedis-logo' />
              <Box
                sx={{
                  display: "flex",
                  gap: "3px",
                  marginTop: "4px",
                }}
              >
                <Typography sx={{ color: "#005EB8", fontWeight: "500" }}>
                  Partenaire
                </Typography>
                <Typography sx={{ color: "#93C90E", fontWeight: "500" }}>
                  Data
                </Typography>
                <Typography sx={{ color: "#93C90E", fontWeight: "500" }}>
                  Connect
                </Typography>
              </Box>
            </Box>
            <Link
              href='https://www.facebook.com/profile.php?id=100087462918765'
              target='_blank'
            >
              <IconButton
                color='primary'
                aria-label='Facebook'
                sx={{ padding: "10px" }}
              >
                <FacebookIcon />
              </IconButton>
            </Link>
            <Link href='https://www.instagram.com/chmsolar/' target='_blank'>
              <IconButton
                color='primary'
                aria-label='Instagram'
                sx={{ padding: "10px" }}
              >
                <InstagramIcon />
              </IconButton>
            </Link>
            <Link
              href='https://www.linkedin.com/company/chm-solar/'
              target='_blank'
            >
              <IconButton
                color='primary'
                aria-label='LinkedIn'
                sx={{ padding: "10px" }}
              >
                <LinkedInIcon />
              </IconButton>
            </Link>
          </Box>
        </Box>
      </Container>
    </footer>
  );
}
