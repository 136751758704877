import React, {createContext } from "react";
import io from "socket.io-client";

export const s = io(process.env.REACT_APP_SERVER_URL,{
    auth: {
     token: localStorage.getItem('token'),
    }
  });

export const SocketContext = createContext(s);

export const SocketProvider = (props:{children:React.ReactNode}) => {

  return(
      <SocketContext.Provider value={s}>
        { props.children }
      </SocketContext.Provider>
    )
  };

