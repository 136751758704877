import React,{useState,useEffect,useContext} from 'react';
import ParallaxBox from '../components/ui/parallaxBox';
import Route from "../assets/icons8-route-slow.gif";
import Graph from "../assets/icons8-graphique-combiné-slow.gif";
import Solution from "../assets/icons8-carte-mentale.gif";
import Financement from "../assets/icons8-flux-de-trésorerie.gif";
function Solutions() {
/*   -------------------- socket state ------------------------  */

  const HeaderSolutions = () => {
    return(
      <div className='parallax-wrapper-content'>
          <h1>Les Solutions</h1>
      </div>
    );
  };
  const Gestion = () => {
    return(
      <div className='parallax-wrapper-content'>
              <p className='parallax-content'>(cas pratique) Une interface de gestion complète et opérationnelle d’aide à la décision. Notre équipe vous accompagne, sur le plan technique, financier afin de réaliser dans les meilleures conditions votre projet photovoltaïque.</p>
      </div>
    );
  }

    return (    
      <div className='page_solutions'>
        <div className='solutions-wrapper'>
            <ParallaxBox aspectRatio="5 / 1" imgUrl={"soren-h-omfN1pW-n2Y-unsplash.jpg"} child={<HeaderSolutions/>}/>         
          <main>
            <div>
              <div className='solutions-wrapper-card'>
                <section className='card'>
                  <div className='card-header'>
                    <img src={Graph} />
                    {/* <img src={} /> */}
                    <h3>Visualisez le potentiel de votre projet</h3>
                  </div>
                  <p>Identifiez très rapidement votre rentabilité grâce à notre logiciel.</p>
                </section>
                <section className='card'>
                   <div className='card-header'>
                       <img src={Route} />
                       <h3>Pilotez votre investissement</h3>
                    </div>
                    <p>Avec notre outil, vous êtes sur de la faisabilité de votre projet.</p>
                </section>
              </div>
              <ParallaxBox aspectRatio="5 / 1" imgUrl={"soren-h-omfN1pW-n2Y-unsplash.jpg"} child={<Gestion/>} />
            </div>
            <div className='solutions-wrapper-card'>
              <section className='card'>
                   <div className='card-header'>
                       <img src={Solution} />
                      <h3>Structuration technique</h3>
                    </div>
                <p>Vous définissez avec notre aide à la meilleure solution technique pour votre projet.</p>
              </section>
              <section className='card'>
              <div className='card-header'>
                       <img src={Financement} />
                       <h3>Financement</h3>
                    </div>
                <p>Utilisez notre solution de LOA ainsi que notre aide pour récupérer l'ensemble de vos droits et subventions</p>
              </section>
            </div>
          </main>
        </div>
      </div>  
    );

}

export default Solutions;