import React, { useState, useEffect, useContext } from "react";
import { Box, Skeleton, Typography, Fab, Badge } from "@mui/material";
import Stepper from "../components/ui/stepper";
import Step from "../components/user/step/Step";
import { useQuery } from "react-query";
import { USERS } from "../api/USER";
import jwt_decode from "jwt-decode";
import { DashBoardSkeleton } from "../components/ui/Skeleton";
import AdminNavigation from "../components/admin/AdminNavigation";
import SellerGrid from "../components/admin/SellerGrid/SellerGrid";
import UserGrid from "../components/admin/UserGrid/UserGrid";
import { useParams } from "react-router-dom";
import UserPage from "../components/admin/UserPage";
import ProjectChoice from "../components/user/project/projectChoice";

import { relative } from "path";
import Chat from "../components/chat/chat";

function DashBoard(props: any) {
  const storedToken = localStorage.getItem("token");
  const userType = storedToken && jwt_decode<any>(storedToken)?.user?.role;
  return (
    <Box
      sx={{
        height: "100%",
        padding: "50px 0 100px 0",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 1,
      }}
    >
      {userType === "USER" ? (
        <UserDashBoard />
      ) : userType === "ADMIN" || "SELLER" ? (
        <AdminDashBoard userType={userType} />
      ) : (
        <DashBoardSkeleton />
      )}
    </Box>
  );
}

function UserDashBoard() {
  return <ProjectChoice />;
}

function AdminDashBoard({ userType }: { userType: string }) {
  const [adminNavigation, setAdminNavigation] = useState<String>("user");
  const user_id = useParams()?.user;
  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AdminNavigation
        userType={userType}
        user_id={user_id}
        adminNavigation={adminNavigation}
        setAdminNavigation={setAdminNavigation}
      />
      {user_id ? (
        <UserPage user_id={user_id} />
      ) : adminNavigation === "user" ? (
        <UserGrid userType={userType} />
      ) : adminNavigation === "seller" ? (
        <SellerGrid />
      ) : (
        <DashBoardSkeleton />
      )}
    </Box>
  );
}

export default DashBoard;
