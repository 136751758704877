import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { StateMessage } from "../../ui/stateMessage";
import FileCard from "../../ui/FileCard";
import { DashBoardSkeleton } from "../../ui/Skeleton";
import { ProjectDocs } from "../../../utils/filesList";

const ProjetDoc = ({ API, localData }: any) => {
  const [Files, setFiles] = useState(ProjectDocs);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const filesWithFileNameOnly = [...ProjectDocs].map(({ file_name }) => ({
      file_name,
    }));
    API.DOC_CHECK(localData.project._id, filesWithFileNameOnly).then(
      (res: any) => {
        if (res.data) {
          const updatedFiles = Files.map((file) => {
            const fileExist = res.data.find(
              (data: any) => data.file_name === file.file_name
            )?.exist;
            return { ...file, exist: fileExist };
          });
          setIsLoading(false);
          setFiles(updatedFiles);
        }
      }
    );
  }, []);

  const isAllFilesSend = !Files.some((file) => !file.exist);

  const handleRemoveFile = (file_name: string) => {
    API.DOC_DELETE(localData.project._id, file_name).then((res: any) => {
      if (res.data) {
        setFiles((prevState) =>
          prevState.map((file) =>
            file.file_name === file_name ? { ...file, exist: false } : file
          )
        );
      }
    });
  };
  const FilesSend = () => {
    return (
      <StateMessage
        message={
          isAllFilesSend
            ? "Les documents ont tous été envoyés."
            : "En attente de l'envoie des documents par le client."
        }
        quote={
          isAllFilesSend
            ? "Vous pouvez voir si-dessous les documents et les télécharger"
            : "Vous pouvez voir si-dessous les documents, les télécharger ou supprimer."
        }
        icon={isAllFilesSend ? "check" : "waiting"}
        component={
          <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            {Files.map(
              (file, i1) =>
                file.exist && (
                  <Box key={i1}>
                    <FileCard
                      variant={
                        localData.project.step === 5 ? "both" : "download"
                      }
                      download={{
                        download_api: API.DOC_DOWNLOAD,
                        file_name: file.file_name,
                        project_id: localData.project._id,
                      }}
                      fileName={file.file_name}
                      handleRemoveFile={handleRemoveFile}
                    />
                  </Box>
                )
            )}
          </Box>
        }
      />
    );
  };
  return (
    <Box sx={{ padding: "20px" }} className={"wrapper card"}>
      <Typography
        variant='h2'
        component='h1'
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontSize: "30px",
          margin: "10px 0 30px 0",
        }}
      >
        Documents à envoyer
      </Typography>
      {isLoading ? <DashBoardSkeleton /> : <FilesSend />}
    </Box>
  );
};

export default ProjetDoc;
