import { Box, Skeleton } from "@mui/material";
import MesInfos from "./1_MesInfos";
import ConsoData from "./2_ConsData";
import EtudeProjet from "./3_EtudeProjet";
import SignDocs from "./4_SignDocs";
import ProjetDoc from "./5_ProjetDoc";

const Step = ({ API, step, localData, setLocalData }: any) => {
  var ActualStep;
  switch (step) {
    case 1:
      ActualStep = (
        <MesInfos localData={localData} setLocalData={setLocalData} />
      );
      break;
    case 2:
      ActualStep = <ConsoData localData={localData} />;
      break;
    case 3:
      ActualStep = <EtudeProjet API={API} localData={localData} />;
      break;
    case 4:
      ActualStep = <SignDocs API={API} localData={localData} />;
      break;
    case 5:
      ActualStep = <ProjetDoc API={API} localData={localData} />;
      break;
    default:
      ActualStep = (
        <Skeleton
          variant='rectangular'
          animation='wave'
          height={400}
          sx={{ width: "100%", borderRadius: "20px" }}
        />
      );
  }

  return (
    <Box
      sx={{ width: 1, margin: "0 20px", marginBottom: "20px" }}
      className={"wrapper"}
    >
      {ActualStep}
    </Box>
  );
};

export default Step;
