import { 
  Box, 
  Typography 
} from "@mui/material";
import {StateMessage} from "../../ui/stateMessage";
import MonthChart from "../../ui/MonthChart";

const ConsoData = ({localData}: any) => {
  const ConsoWaiting = () => {
    return(
        <StateMessage
              message={
                "Nous attendons que l'utilisateur renseigne ses données de consommation."
              }
              icon={"waiting"}
              mt={"40px"}
              mb={"0px"}
            />
    )
  }

  const MonthlyConso = () => {
    return(<MonthChart 
      color={'#FFA500'} 
      label={'Consommation (KWH)'}
      data={localData?.project?.enedisData?.MonthlyConso?.map((mois: any) => mois.conso)}
      />)
  }

  return (
    <Box
      sx={{ padding: "20px" }}
      className={"wrapper card"}
    >

      <Typography
        variant="h2"
        component="h1"
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontSize: "30px",
          margin: "10px 0 30px 0",
        }}
      >
        Données de consommations
      </Typography>
      {localData.project.step > 2 ?
      <MonthlyConso />
      :
      <ConsoWaiting />
      }
    </Box>
  );
};

export default ConsoData;
