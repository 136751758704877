import axios from 'axios';


export const CREDENTIALS = {
	GET_PRO_BYSIRET: async function (data:any) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/user/BYSIRET`,
			params: {
				email: data.email,
				password: data.password,
			}
		}).then(function (res:any) {			
			return res.data;
		}))
	},

	POST_REGISTER: async function (data:any) {
		let config = {
		  method: 'post',
		  url: `${process.env.REACT_APP_SERVER_URL}/api/sign`,
		  headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		  },
		  data : {
			password: data.password,
			email: data.email,
			name: data.name,
			family_name: data.family_name,
		}
		};
		return axios.request(config)
		.then((response) => {
		  return JSON.stringify(response.data);
		})
		.catch((error) => {
		  console.log(error);
		});
	},

	GET_LOGIN: async function (data:any) {
		let config = {
		  method: 'get',
		  url: `${process.env.REACT_APP_SERVER_URL}/api/token?password=${data.password}&email=${data.email}`,
		  headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		  },
		};
		return axios.request(config)
		.then((response) => {
		  return JSON.stringify(response.data);
		})
		.catch((error) => {
		  console.log(error);
		});
	}

	/*GET_AUTH_BYTOKEN: async function (token) {

		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/auth/GET/AUTH/TOKEN`,
			params: {
				token:token
			}
		}).then(function (res) {	
				
			return res.data;

		}))
	},
	CREATE_TOKEN: async function (_id) {
		return (axios({
			method: 'post',
			url: `${process.env.REACT_APP_SERVER_URL}/auth/POST/ACCOUNT`,
			
		}).then(function (res) {
			return res;
		}))
	},
	
    CREATE_ACCOUNT: async function (data) {
            return (axios({
                method: 'post',
                url: `${process.env.REACT_APP_SERVER_URL}/auth/POST/ACCOUNT`,
                data: {
                    email:data.email,
                    name: data.name,
                    lastname: data.lastname,
                    password:data.password,				
                }
            }).then(function (res) {
                return res;
                //recuperer le token ici
            }))
    },*/
    
}
