import React, { useState } from "react";
import {
  Typography,
  Autocomplete,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const AutoCompleteAdresse = (props: any) => {
  const [Options, setOptions] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);

  let controller = new AbortController();
  let signal = controller.signal;

  function autocompleteAdresse(inputValue: String) {
    controller.abort();
    controller = new AbortController();
    signal = controller.signal;

    fetch(
      `https://api-adresse.data.gouv.fr/search/?q=${inputValue.trim()}?type=housenumber&autocomplete=1`,
      { signal }
    )
      .then(function (response) {
        response.json().then(function (data) {
          data?.features?.map((feature: any, i1: number) => {
            data.features[i1].properties.lat = feature.geometry.coordinates[1];
            data.features[i1].properties.lon = feature.geometry.coordinates[0];
          });
          setOptions(data.features);
          setLoading(false);
          props.setAdresseError(false);
          props.setAdresseValue(null);
        });
      })
      .catch(function (error) {
        return;
      });
  }
  return (
    <div>
      <Autocomplete
        value={props.adresseValue}
        onChange={(event: any, newValue: any) => {
          props.setAdresseError(false);
          props.setAdresseValue(newValue);
        }}
        onClose={() => {
          setLoading(false);
        }}
        id="combo-box-demo"
        options={Options.map((option: any) => option.properties)}
        filterOptions={(x) => x}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            margin="dense"
            {...params}
            error={props.adresseError}
            helperText={
              props.adresseError &&
              "Veuillez séléctionner une adresse parmis la liste déroulante"
            }
            onChange={(event) => {
              if (event.target.value.trim()) {
                setLoading(true);
                autocompleteAdresse(event.target.value.trim());
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label="Adresse"
          />
        )}
        renderOption={(props: any, option) => {
          const index = props["data-option-index"];
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  {Options[index].properties.label}
                  <Typography variant="body2" color="text.secondary">
                    {Options[index].properties.context}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </div>
  );
};

export default AutoCompleteAdresse;
