import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import theme from './theme';
import './style.css';
import Router from './router';
import { LicenseInfo } from '@mui/x-license-pro';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, 
    },
  },
})

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY || ``);

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  </QueryClientProvider>
);

