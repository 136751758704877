import { Box, Typography, Skeleton } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
export const StateMessage = ({
  message,
  quote,
  component,
  icon,
  mb,
  mt,
}: any) => {
  var Icon;
  switch (icon) {
    case "check":
      Icon = <CheckBoxIcon sx={{ color: "primary.main", fontSize: "35px" }} />;
      break;
    case "waiting":
      Icon = (
        <HourglassBottomIcon sx={{ color: "primary.main", fontSize: "35px" }} />
      );
      break;
    case "upload":
      Icon = (
        <CloudUploadIcon sx={{ color: "primary.main", fontSize: "35px" }} />
      );
      break;
    default:
      Icon = <CheckBoxIcon sx={{ color: "primary.main", fontSize: "35px" }} />;
  }
  return (
    <Box
      className='wrapper'
      sx={{
        width: 1,
        borderRadius: "20px",
        backgroundColor: "customGrey.grey1",
        padding: "20px",
        mb: mb || "20px",
        mt: mt || "20px",
        display: "flex",
        gap: "15px",
        alignItems: "center",
      }}
    >
      {Icon}
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Typography variant='body1'>{message}</Typography>
        {quote && <Typography variant='body2'>{quote}</Typography>}
        {component && <Box mt={"10px"}>{component}</Box>}
      </Box>
    </Box>
  );
};
