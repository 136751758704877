import React from "react";

import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material/";
export const FormInformations = ({ formik, isSiretValid, changed }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { xs: "0px", sm: "0px", md: "20px" },
        marginTop: "15px",
      }}
      component='form'
      onSubmit={formik.handleSubmit}
    >
      <Box sx={{ display: "flex", gap: "20px" }}>
        <TextField
          margin='dense'
          fullWidth
          id='name'
          label='Prénom'
          name='name'
          autoComplete='name'
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name?.toString()}
        />
        <TextField
          margin='dense'
          fullWidth
          id='family_name'
          label='Nom de famille'
          name='family_name'
          autoComplete='family_name'
          value={formik.values.family_name}
          onChange={formik.handleChange}
          error={
            formik.touched.family_name && Boolean(formik.errors.family_name)
          }
          helperText={
            formik.touched.family_name && formik.errors.family_name?.toString()
          }
        />
      </Box>

      <TextField
        margin='dense'
        fullWidth
        id='fonction'
        label='Fonction'
        name='fonction'
        autoComplete='fonction'
        value={formik.values.fonction}
        onChange={formik.handleChange}
        error={formik.touched.fonction && Boolean(formik.errors.fonction)}
        helperText={
          formik.touched.fonction && formik.errors.fonction?.toString()
        }
      />
      <TextField
        margin='dense'
        fullWidth
        id='phone'
        label='Téléphone'
        name='phone'
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone?.toString()}
        autoComplete='phone'
      />
      <FormControl fullWidth>
        <InputLabel id='situation-label'>Vous êtes</InputLabel>
        <Select
          labelId='situation-label'
          id='situation'
          name='situation'
          value={formik.values.situation}
          label='situation'
          onChange={formik.handleChange}
          sx={{ marginTop: "8px", marginBottom: "4px" }}
        >
          <MenuItem value={"Particulier"}>Particulier</MenuItem>
          <MenuItem value={"Professionnel"}>Professionnel</MenuItem>
        </Select>
      </FormControl>
      {formik.values.situation === "Professionnel" && (
        <TextField
          margin='dense'
          fullWidth
          id='siret'
          label='Siret Entreprise (sans espace)'
          name='siret'
          autoComplete='siret'
          value={formik.values.siret}
          onChange={formik.handleChange}
          error={
            (formik.touched.siret && Boolean(formik.errors.siret)) ||
            !isSiretValid
          }
          helperText={
            (formik.touched.siret && formik.errors.siret?.toString()) ||
            (!isSiretValid && "Aucune entreprise trouvée avec ce siret")
          }
        />
      )}
      {changed && (
        <Typography variant='h3' color='primary' sx={{ textAlign: "center" }}>
          Vos informations ont été sauvegardées.
        </Typography>
      )}
      <Button type='submit' variant='contained' sx={{ color: "white" }}>
        Confirmer
      </Button>
    </Box>
  );
};
