import React, { useState } from "react";
import { Box, Typography, Divider, Stack, Button, Link } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupsIcon from "@mui/icons-material/Groups";
import BadgeIcon from "@mui/icons-material/Badge";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// userType={userType} user_id={user_id} adminNavigation={adminNavigation} setAdminNavigation={setAdminNavigation}
const AdminNavigation = ({
  userType,
  user_id,
  adminNavigation,
  setAdminNavigation,
}: any) => {
  return (
    <Box className='wrapper card' sx={{ mb: "20px" }}>
      <Box
        sx={{
          height: "40px",
          display: "flex",
          alignItems: "center",
          gap: 2,
          padding: "25px 20px",
        }}
      >
        <AdminPanelSettingsIcon color='primary' />
        <Divider orientation='vertical' />
        {!user_id ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Button
              variant={adminNavigation === "user" ? "outlined" : "contained"}
              onClick={() => {
                setAdminNavigation("user");
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                spacing={1}
                margin='0 5px'
              >
                <GroupsIcon
                  sx={{
                    color: adminNavigation === "user" ? "primary" : "#fff",
                  }}
                  color='primary'
                />
                <Typography
                  sx={{
                    color: adminNavigation === "user" ? "primary" : "#fff",
                  }}
                  variant='body2'
                >
                  Gestion des utilisateurs
                </Typography>
              </Stack>
            </Button>
            {userType === "ADMIN" && (
              <Button
                variant={
                  adminNavigation === "seller" ? "outlined" : "contained"
                }
                onClick={() => {
                  setAdminNavigation("seller");
                }}
              >
                <Stack
                  direction='row'
                  alignItems='center'
                  spacing={1}
                  margin='0 5px'
                >
                  <BadgeIcon
                    sx={{
                      color: adminNavigation === "seller" ? "primary" : "#fff",
                    }}
                  />
                  <Typography
                    sx={{
                      color: adminNavigation === "seller" ? "primary" : "#fff",
                    }}
                    variant='body2'
                  >
                    Gestion des commerciaux
                  </Typography>
                </Stack>
              </Button>
            )}
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Button
              variant='contained'
              onClick={() => {
                window.location.replace("/dashboard");
              }}
            >
              <ArrowBackIosNewIcon sx={{ color: "white" }} />
            </Button>
            <Typography
              id='utilisateur_page_header'
              variant='body2'
            ></Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AdminNavigation;
