
import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton
} from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import DrawIcon from '@mui/icons-material/Draw';
import theme from './../../theme';

const FileCard = ({fileName, handleRemoveFile, variant, download, icon, mb, border, width, height}: any) => {
  const handleDownload = () => {
    download.download_api(download.project_id, download.file_name).then((res: any) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', download.file_name);
      document.body.appendChild(link);
      link.click();
    })
  }


  return (
      <Box sx={{
          display:'flex', 
          alignItems:'center', 
          justifyContent: 'space-between', 
          gap: '10px', 
          backgroundColor: 'customGrey.grey3', 
          borderRadius: '10px', 
          padding: '10px 20px', 
          width: width ? width : 'auto',
          height: height ? height : '40px',
          mb: mb ? mb : '0px',
          boxShadow: border ? `0px 0px 0px ${border} ${theme.palette.primary.main} inset` : 'none',
        }}>
        {icon === 'sign' ? 
        <DrawIcon sx={{ color: "primary.main" }}/>
        : 
        <InsertDriveFileIcon sx={{ color: "primary.main" }}/>}
        <Typography noWrap >{fileName}</Typography>
        <Box>
          {(variant === 'download' || variant === 'both') && 
          <IconButton onClick={() => {handleDownload()}} aria-label="delete" >
            <DownloadIcon sx={{color: 'customGrey.grey5'}} />
          </IconButton>
          }
          {(variant === 'display' || variant === 'both') && 
          <IconButton onClick={() => handleRemoveFile(fileName)} aria-label="delete" >
          <ClearIcon sx={{color: 'customGrey.grey5'}}/>
          </IconButton>
          }
        </Box>
    </Box>
  );
};

export default FileCard;