import React, { useState, useEffect } from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import { USERS } from "../../../api/USER";
import { useQuery } from "react-query";
import { DisplayProjects } from "../../ui/DisplayProjects";
import { FirstProject } from "./FirstProject";
const ProjectChoice = () => {
  const [projects, setProjects] = React.useState([]);
  const [user, setUser] = React.useState<any>({});
  const [readyFirstProj, setReadyFirstProj] = useState(false);
  const { isLoading: projectsLoading } = useQuery<any, Error>(
    "getUserProject",
    async () => {
      try {
        const res = await USERS.GET_USER();
        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  );
  const { isLoading: userLoading } = useQuery<any, Error>(
    "projectsProjectChoice",
    async () => {
      try {
        const res = await USERS.GET_PROJECT();
        setProjects(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  );
  if (userLoading || projectsLoading) return <h1>Loading ...</h1>;
  return (
    <Box
      sx={{
        margin: "0 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 1,
      }}
    >
      {userLoading || projectsLoading ? (
        <Typography variant='h2'>Loading...</Typography>
      ) : projects.length === 0 &&
        (!readyFirstProj || !user.fonction || !user.phone) ? (
        <FirstProject
          setReadyFirstProj={setReadyFirstProj}
          user={user}
          setUser={setUser}
          projects={projects}
        />
      ) : (
        <DisplayProjects
          readyFirstProj={readyFirstProj}
          headerText={"Mes projets"}
          projects={projects}
          setProjects={setProjects}
          selectedUser={user}
        />
      )}
    </Box>
  );
};

export default ProjectChoice;
