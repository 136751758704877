import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import Parchment from "../assets/parchment.png";
import SavingPlanet from "../assets/saving-planet.png";
import ReneweableEnergy from "../assets/reneweable-energy.png";
function Legislatif() {
  /*   -------------------- socket state ------------------------  */

  return (
    <div className='page_legislatif'>
      <div className='legislatif-content-wrapper'>
        <h2>
          Les entreprises toutes tailles confondues sont soumises à plusieurs
          décrets de loi concernant la maîtrise de l'énergie.
        </h2>
        <div className='legislatif-content'>
          <section className='card'>
            <div className='section-header' id='decret_tertiaire'>
              <img src={Parchment} alt='' />
              <h3>Le décret tertiaire :</h3>
            </div>
            <p className='list-title'>
              Est une réglementation qui impose des obligations de réduction de
              la consommation d'énergie dans les bâtiments tertiaires (bureaux,
              commerces, hôtels, etc.). Les obligations sont les suivantes :
            </p>
            <ul>
              <li>
                <span>
                  Obligation de réduction de la consommation d'énergie : Les
                  propriétaires ou gestionnaires de bâtiments tertiaires doivent
                  réduire leur consommation d'énergie de 40% d'ici 2030, par
                  rapport à leur consommation de référence de 2010.
                </span>
              </li>
              <li>
                <span>
                  Obligation de mise en place d'un plan d'action : Les
                  propriétaires ou gestionnaires de bâtiments tertiaires doivent
                  mettre en place un plan d'action pour atteindre l'objectif de
                  réduction de la consommation d'énergie. Ce plan doit être
                  établi en concertation avec les occupants du bâtiment et doit
                  être actualisé tous les quatre ans.
                </span>
              </li>
              <li>
                <span>
                  Obligation de reporting : Les propriétaires ou gestionnaires
                  de bâtiments tertiaires doivent fournir chaque année une
                  déclaration de performance énergétique, qui doit être vérifiée
                  par un tiers indépendant.
                </span>
              </li>
            </ul>

            <p className='list-title'>
              Les sanctions pour non-respect des obligations du décret tertiaire
              sont les suivantes :
            </p>
            <ul>
              <li>
                <span>
                  Pénalités financières : Les propriétaires ou gestionnaires de
                  bâtiments tertiaires qui ne respectent pas les obligations du
                  décret tertiaire peuvent être soumis à des pénalités
                  financières pouvant aller jusqu'à 75 000 euros.
                </span>
              </li>
              <li>
                <span>
                  Obligation de mise en conformité : Les propriétaires ou
                  gestionnaires de bâtiments tertiaires qui ne respectent pas
                  les obligations du décret tertiaire peuvent être mis en
                  demeure de se conformer à la réglementation dans un délai
                  déterminé.
                </span>
              </li>
              <li>
                <span>
                  Publication de la liste des contrevenants : Les propriétaires
                  ou gestionnaires de bâtiments tertiaires qui ne respectent pas
                  les obligations du décret tertiaire peuvent être inscrits sur
                  une liste de contrevenants, qui est rendue publique.
                </span>
              </li>
            </ul>
          </section>
          <section className='card'>
            <div className='section-header' id='loi_enr'>
              <img src={SavingPlanet} alt='' />
              <h3>LOI ENR :</h3>
            </div>
            <p className='list-title'>
              La loi sur les énergies renouvelables (ENR) en France impose des
              obligations aux entreprises et aux particuliers en matière de
              production et de consommation d'énergies renouvelables. Voici un
              aperçu des principales obligations et sanctions liées à cette loi
              :
            </p>
            <ul>
              <li>
                <span>
                  Obligation de production d'énergies renouvelables : La loi sur
                  les ENR impose aux fournisseurs d'électricité et de gaz
                  naturel de produire un certain pourcentage d'énergies
                  renouvelables. Ce pourcentage est fixé par décret et évolue
                  chaque année. Les fournisseurs doivent également justifier la
                  traçabilité de leur production d'énergie renouvelable.
                </span>
              </li>
              <li>
                <span>
                  Obligation d'efficacité énergétique : Les entreprises et les
                  collectivités doivent réaliser des économies d'énergie
                  conformément aux objectifs fixés par la loi. Elles doivent
                  notamment mettre en place des plans d'actions visant à réduire
                  leur consommation énergétique.
                </span>
              </li>
              <li>
                <span>
                  Obligation d'installation d'équipements économes en énergie :
                  Les anciennes et nouvelles constructions doivent respecter les
                  normes de performance énergétique en vigueur, notamment en
                  matière d'isolation et d'installation d'équipements économes
                  en énergie.
                </span>
              </li>
              <li>
                <span>
                  Sanctions : Les sanctions pour non-respect des obligations
                  liées à la loi sur les ENR peuvent varier en fonction des cas.
                  Elles peuvent notamment inclure des amendes, des mises en
                  demeure ou des interdictions d'exercer certaines activités.
                </span>
              </li>
            </ul>
            <p>
              En résumé, la loi sur les énergies renouvelables en France vise à
              encourager la production et la consommation d'énergies
              renouvelables, ainsi qu'à promouvoir l'efficacité énergétique. Les
              sanctions pour non-respect des obligations peuvent être variées et
              sont généralement déterminées au cas par cas.
            </p>
          </section>
          <section className='card'>
            <div className='section-header' id='loi_climat'>
              <img src={ReneweableEnergy} alt='' />
              <h3>LOI CLIMAT :</h3>
            </div>
            <p className='list-title'>
              La loi climat et résilience en France a pour objectif de réduire
              les émissions de gaz à effet de serre et de renforcer la
              résilience face au changement climatique. Voici un aperçu des
              principales obligations et sanctions liées à cette loi :
            </p>
            <ul>
              <li>
                <span>
                  Obligation de réduction des émissions de gaz à effet de serre
                  : La loi climat fixe des objectifs ambitieux de réduction des
                  émissions de gaz à effet de serre en France, avec notamment
                  une réduction de 40% d'ici 2030 par rapport à 1990.
                </span>
              </li>
              <li>
                <span>
                  Obligation de rénovation énergétique : La loi climat impose
                  aux propriétaires de réaliser des travaux de rénovation
                  énergétique, afin de réduire la consommation d'énergie et les
                  émissions de gaz à effet de serre. Des incitations financières
                  sont prévues pour encourager la réalisation de ces travaux.
                </span>
              </li>
              <li>
                <span>
                  Obligation de mobilité propre : La loi climat encourage la
                  transition vers une mobilité propre, avec notamment la
                  promotion des véhicules électriques et des modes de transport
                  alternatifs.
                </span>
              </li>
              <li>
                <span>
                  Sanctions : Les sanctions pour non-respect des obligations
                  liées à la loi climat peuvent inclure des amendes, des mises
                  en demeure ou des interdictions d'exercer certaines activités.
                  En outre, la loi prévoit la création d'un nouveau délit de
                  pollution de l'air, qui peut être puni de deux ans
                  d'emprisonnement et de 75 000 euros d'amende.
                </span>
              </li>
            </ul>
            <p>
              En résumé, la loi climat et résilience en France vise à lutter
              contre le changement climatique en réduisant les émissions de gaz
              à effet de serre et en favorisant la transition vers une économie
              plus sobre en carbone. Les sanctions pour non-respect des
              obligations peuvent être variées et sont généralement déterminées
              au cas par cas.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Legislatif;
