import React, { ChangeEvent, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  Checkbox,
  ListItem,
  ListItemButton,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import ToitPlat from "../../assets/toits/toit_plat.png";
import ToitTerrasse from "../../assets/toits/toit_terrasse.png";
import ToitIncline from "../../assets/toits/toit_incline.jpeg";
import { DialogHeader } from "./DialogHeader";
export const RoofSelectionDialog = ({
  localData,
  roof,
  setRoof,
  setOpenTileSelection,
  setOpenRoofSelection,
  open,
  onClose,
  handleClose,
  errorSelector,
  setErrorSelector,
  prevPopup,
}: any) => {
  const roofs = [
    {
      type: "FLAT",
      name: "TOIT PLAT",
      image: ToitPlat,
    },
    {
      type: "TERRACE",
      name: "TOIT TERRASSE",
      image: ToitTerrasse,
    },
    {
      type: "INCLINED",
      name: "TOIT INCLINE",
      image: ToitIncline,
    },
  ];
  const handleCheck = (roof: any) => {
    const roofType = roof.type;
    // On met à null tileType afin que lorsque l'on modifie notre sélection, et que l'on choisi un toit non incliné, la tuile soit à null
    setRoof((roof: any) => ({ ...roof, roofType: roofType, tileType: null }));
    setErrorSelector("");
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogHeader
        headerText={"Sélectionnez votre type de toiture"}
        onClose={onClose}
      />
      <div className='roof-selector'>
        {roofs.map((roofType) => {
          return (
            <div
              className='roof-selector-wrapper roof-card card'
              onClick={() => handleCheck(roofType)}
              key={roofType.name}
            >
              <ListItem key={roofType.type} className='roof-selector-card'>
                <img src={roofType.image} alt='Toit' className='selector-img' />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#ddd",
                        "& .MuiSvgIcon-root": { fontSize: 50 },
                        ".Mui-focusVisible &": {
                          outline: "2px auto rgba(19,124,189,.6)",
                          outlineOffset: 2,
                        },
                      }}
                      value={roofType.type}
                      checked={roofType.type === roof.roofType}
                    />
                  }
                  label={roofType.name}
                />
              </ListItem>
            </div>
          );
        })}
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          margin: "20px",
        }}
      >
        {errorSelector !== "" ? (
          <p style={{ color: "red" }}>{errorSelector}</p>
        ) : (
          ""
        )}
        <Button
          variant='contained'
          color='primary'
          sx={{ width: "210px", color: "white" }}
          onClick={() => {
            if (roof.roofType === "INCLINED") {
              const closed = handleClose(
                roof.roofType,
                setOpenRoofSelection,
                "Vous devez choisir un type de toiture."
              );
              if (closed) {
                setOpenTileSelection(true);
              }
            } else {
              handleClose(
                roof.roofType,
                setOpenRoofSelection,
                "Vous devez choisir un type de toiture."
              );
            }
          }}
        >
          Valider mon choix
        </Button>
      </Box>
    </Dialog>
  );
};
