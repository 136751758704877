import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  FormControl,
  TextField,
} from "@mui/material";

import { USERS } from "../../api/USER";
import { DialogHeader } from "./DialogHeader";

export const ProjectInfosDialog = ({
  infos,
  setInfos,
  setProjects,
  open,
  onClose,
  setErrorSelector,
  errorSelector,
  handleClose,
  setPopup,
  prevPopUp,
  projects,
}: any) => {
  const handleError = (state: string, errorMessage: string, setError: any) => {
    if (state === null || state === "") {
      setError(errorMessage);
      return false;
    }
    setError("");
    return true;
  };
  const handleChangeName = (e: any) => {
    setInfos({ ...infos, name: e.target.value });
  };
  const handleChangeDesc = (e: any) => {
    setInfos({ ...infos, description: e.target.value });
  };
  const handleSubmit = () => {
    try {
      USERS.POST_PROJECT(infos).then((res: any) => {
        setProjects([...projects, res.data.response.data]);
      });
      setInfos({
        name: null,
        description: null,
        project_type: null,
      });
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(setPopup)}
      fullWidth
      maxWidth='md'
    >
      <DialogHeader
        headerText={"Informations concernant votre projet :"}
        onClose={() => onClose(setPopup)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "20px 0",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <FormControl>
          <Box sx={{ width: "450px" }}>
            <TextField
              id='name'
              fullWidth
              label='Donnez un nom à votre projet'
              variant='filled'
              onChange={(e) => handleChangeName(e)}
            />
          </Box>
        </FormControl>
        <FormControl>
          <Box sx={{ width: "450px" }}>
            <TextField
              id='desc'
              rows={4}
              fullWidth
              label='Décrivez votre projet'
              multiline
              onChange={(e) => handleChangeDesc(e)}
              variant='filled'
            />
          </Box>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          margin: "20px",
        }}
      >
        {errorSelector !== "" ? (
          <p style={{ color: "red" }}>{errorSelector}</p>
        ) : (
          ""
        )}
        <Button
          variant='contained'
          color='primary'
          sx={{ width: "210px", color: "white" }}
          onClick={() =>
            handleError(
              infos.name,
              "Vous devez donner un nom à votre projet.",
              setErrorSelector
            ) &&
            handleError(
              infos.description,
              "Vous devez décrire votre projet.",
              setErrorSelector
            ) &&
            handleSubmit() &&
            handleClose("", setPopup, "No error")
          }
        >
          Valider mon choix
        </Button>
      </Box>
    </Dialog>
  );
};
