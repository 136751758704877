import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export const MonthChart = ({ data, label, color }: any) => {
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef.current) {
      const myChart = new Chart(chartRef.current, {
        type: "bar",
        data: {
          labels: [
            "Jan",
            "Fév",
            "Mar",
            "Avr",
            "Mai",
            "Jui",
            "Jui",
            "Aoû",
            "Sep",
            "Oct",
            "Nov",
            "Déc",
          ],
          datasets: [
            {
              backgroundColor: color,
              data: data || [],
              label: label,
            },
          ],
        },
      });
      return () => {
        myChart.destroy();
      };
    }
  }, [color, data, label]);

  return <canvas id="myChart" ref={chartRef} />;
};

export default MonthChart;
