import jwt_decode from "jwt-decode";
// import UserProjectChoice from "../components/user/project/projectChoice";
import Chat from "../../chat/chat";
import { useState, useEffect } from "react";
import { USERS } from "../../../api/USER";
import Step from "../step/Step";
import Stepper from "../../ui/stepper";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
export function Project() {
  const projectId = useParams();
  const [localData, setLocalData] = useState<any>(null);
  const storedToken = localStorage.getItem("token");
  const userType = storedToken && jwt_decode<any>(storedToken)?.user?.role;
  if (userType === "ADMIN" || (userType === "USER" && !projectId.project)) {
    window.location.replace("/dashboard");
  }
  const { data: result, isLoading } = useQuery(
    ["user", "project"],
    async () => {
      const userResponse = await USERS.GET_USER();
      const projectResponse = await USERS.GET_PROJECT();
      let project = projectResponse.data;
      project = project.filter(
        (project: any) => project._id === projectId.project
      );
      setLocalData({ user: userResponse.data, project: project[0] });
      return { user: userResponse.data, project: projectResponse.data };
    }
  );

  const StepManager = () => {
    return (
      localData && (
        <>
          <Chat
            user={"USER"}
            name={"Votre eco-technicien Optimum Solar"}
            project_id={localData?.project?._id}
            user_id={localData?.user?.user_id}
            API={USERS}
          />
          <Stepper step={localData.project.step} />
          <Step localData={localData} setLocalData={setLocalData} />
        </>
      )
    );
  };
  if (!localData) return null;
  return (
    <Box
      className='page_redirect'
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
      }}
    >
      <StepManager />
    </Box>
  );
}
