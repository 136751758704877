import React, { useState } from "react";
import { Box, Typography, Skeleton } from "@mui/material";

import MesInfos from "./1_MesInfos";
import ConsoData from "./2_ConsoData";
import EtudeProjet from "./3_EtudeProjet";
import SignDoc from "./4_SignDocs";
import ProjetDoc from "./5_ProjetDoc";

const Step = ({ localData, setLocalData }: any) => {
  var ActualStep;
  switch (localData?.project?.step) {
    case 1:
      ActualStep = (
        <MesInfos localData={localData} setLocalData={setLocalData} />
      );
      break;
    case 2:
      ActualStep = (
        <ConsoData localData={localData} setLocalData={setLocalData} />
      );
      break;
    case 3:
      ActualStep = (
        <EtudeProjet localData={localData} setLocalData={setLocalData} />
      );
      break;
    case 4:
      ActualStep = (
        <SignDoc localData={localData} setLocalData={setLocalData} />
      );
      break;
    case 5:
      ActualStep = (
        <ProjetDoc localData={localData} setLocalData={setLocalData} />
      );
      break;
    default:
      ActualStep = (
        <Skeleton
          variant='rectangular'
          animation='wave'
          height={400}
          sx={{ width: "100%", borderRadius: "20px" }}
        />
      );
  }

  return (
    <Box
      sx={{ width: 1, margin: "0 20px", marginBottom: "20px" }}
      className={"wrapper"}
    >
      {ActualStep}
    </Box>
  );
};

export default Step;
