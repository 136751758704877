import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as yup from "yup";
import { CREDENTIALS } from "../../api/CREDENTIALS";

export default function Register() {
  //formik
  const formikSchema = yup.object({
    name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `Prenom invalide`)
      .min(2, `Prenom trop court !`)
      .max(50, `Prenom trop long !`)
      .required(`Veuillez renseigner votre prenom`),
    family_name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `Nom invalide`)
      .min(2, `Nom trop court !`)
      .max(50, `Nom trop long !`)
      .required(`Veuillez renseigner votre nom`),
    email: yup
      .string()
      .required(`Veuillez renseigner votre email`)
      .email(`Email invalide`)
      .required(`Veuillez renseigner votre email`),
    password: yup
      .string()
      .required(`Veuillez renseigner votre mot de passe`)
      .min(7, `Mot de passe trop court !`),
  });

  const formik = useFormik({
    initialValues: {
      name: ``,
      family_name: ``,
      email: ``,
      password: ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      CREDENTIALS.POST_REGISTER(formValues)
        .then((res: any) => {
          if (res) {
            JSON.parse(res).accessToken &&
              localStorage.setItem("token", JSON.parse(res).accessToken);
            window.location.replace("/dashboard");
          }
        })
        .catch((err) => console.log(err));
      resetForm();
    },
  });

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Inscription
        </Typography>
        <Box
          component='form'
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin='dense'
            fullWidth
            id='name'
            label='Prenom'
            name='name'
            autoComplete='name'
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            margin='dense'
            fullWidth
            id='family_name'
            label='Nom'
            name='family_name'
            autoComplete='family_name'
            value={formik.values.family_name}
            onChange={formik.handleChange}
            error={
              formik.touched.family_name && Boolean(formik.errors.family_name)
            }
            helperText={formik.touched.family_name && formik.errors.family_name}
          />
          <TextField
            margin='dense'
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            margin='dense'
            fullWidth
            name='password'
            label='Mot de passe'
            type='password'
            id='password'
            autoComplete='current-password'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2, color: "white" }}
          >
            Connexion
          </Button>
          {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Pas de compte ? Inscrivez-vous"}
                </Link>
              </Grid>
            </Grid> */}
        </Box>
      </Box>
    </Container>
  );
}
