import axios from 'axios';

export const ADMIN_USER = {
GET_USERS: async function () {
    return (axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/users`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

GET_USER: async function (user_id: string) {
    return (axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/user`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params : {
            user_id: user_id
        }
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

GET_PROJECT: async function (user_id: string) {
    return (axios({
        method: 'get',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/project`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params : {
            user_id: user_id
        }
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

PUT_PROJECT: async function(project_id : string, project:any) {
    return (axios({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/project`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params :{
            project_id: project_id,
        },
        data: {
            project: JSON.stringify(project)
        }
    }).then((res:any) => {
        return res.data
    })).catch((err) => {
        console.log(err)
        return null;
    })
},
POST_REGISTER: async function (data: any) {
    return (axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/user`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data : {
            password: data.password,
            email: data.email,
            name: data.name,
            family_name: data.family_name,
        }
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},
PUT_USER: async function (id: string, user: any) {
    return (axios({
        method: 'put',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/user/`,
        data: {
            user_id: id,
            user: JSON.stringify(user),
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},

DELETE_USER: async function (id: string) {
    return (axios({
        method: 'delete',
        url: `${process.env.REACT_APP_SERVER_URL}/api/admin/user/`,
        data: {
            user_id: id,
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
    }).then(function (res:any) {
        return res.data;
    })).catch(function (error:any) {
        console.log(error);
        return null;
    });
},
}