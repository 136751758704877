import React, { useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileCard from "./FileCard";

function FileDropZone({ upload, setIsAlreadySend, sendAction, size, title, ext }: any) {
  const [dropZoneState, setDropZoneState] = useState("");
  const [file, setFile] = useState<any>(null);
  const [fileName, setFileName] = useState('');
  
  const handleSendFile = () => {
    if (file) {
      upload.upload_api(upload.project_id, `${upload.file_name}.${file.name.split(".").pop()}`, file).then((res: any) => {
        if (res === "success") {
          setDropZoneState("");
          setFile(null);
          setIsAlreadySend && setIsAlreadySend(true)
          sendAction && sendAction(upload.file_name)
        } 
      });
    }
  };

  const validFile = (file: any) => {
    if (ext.includes(file?.type.split("/")[1])) {
      setFile(file);
      setDropZoneState("added");
      setFileName(file.name);
    } else {
      setDropZoneState("");
      alert(`Seuls les fichiers ${ext.join(", ")} sont autorisés.`);
    }
  }

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    setDropZoneState("hover");
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setDropZoneState("hover");
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setDropZoneState("");
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    validFile(droppedFile)
  };

  const handleSelectFile = (e: any) => {
    const selectedFile = e.target.files[0];
    validFile(selectedFile)
  };

  const handleRemoveFile = () => {
    setDropZoneState("");
    setFile(null);
  };

  return (
    <Box
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      sx={{
        backgroundColor:
          dropZoneState === "hover" ? "customGrey.grey4" : "customGrey.grey1",
        padding: "20px",
        borderRadius: "20px",
      }}
    >
      <Box
        sx={{
          borderStyle: "dashed",
          borderWidth: "3px",
          borderColor: "primary.main",
          borderRadius: "10px",
          padding: '20px',
          height: size === 'small' ? "50px" : "230px",
        }}
      >
        {dropZoneState === "added" ? (
          <Box
            className="flexCenter"
            sx={{ flexDirection: size === 'small' ? "row" : "column" , height: "95%", alignItems:'center'}}
          >
            <FileCard variant={'display'} fileName={fileName} handleRemoveFile={handleRemoveFile}/>
            <Button sx={{color: '#fff', margin: size === 'small' ? '0px 0 0 20px' : '10px 0 0 0'}} variant='contained' onClick={handleSendFile} disabled={!file}>
              Envoyer
            </Button>
          </Box>
        ) : dropZoneState === "hover" ? null : (
          <Box
            className="flexCenter"
            sx={{ 
              flexDirection:  size === 'small' ? "row" : "column", 
              height: "95%",
              gap: size === 'small' ? '20px' : '0px',
              alignItems: 'center',
              justifyContent: size === 'small' ? 'space-between' : 'center'
            }}
          >
            <Box sx={{display: 'flex', gap: size === 'small' ? '15px' : '0px', alignItems: 'center', flexDirection:  size === 'small' ? 'row' : 'column'}}>
            <CloudUploadIcon
              sx={{
                fontSize: size === 'small' ? "50px" : "100px",
                color: "customGrey.grey4",
              }}
            />
            <Box sx={{display: 'flex', gap:'5px'}}>
              <Typography variant="body2" sx={{ fontSize: "18px" }}>Glisser et déposer</Typography>
              {
              title ? 
              <Typography variant="body2" sx={{ fontSize: "18px", fontWeight:'600', color:'secondary.main' }}>{title}</Typography>
               :
              <Typography variant="body2" sx={{ fontSize: "18px" }}>un fichier PDF</Typography>
              } 
              <Typography variant="body2" sx={{ fontSize: "18px" }}>ici {size !== 'small' && 'ou'}</Typography>
            </Box>
            </Box>
            <Button
              variant={"contained"}
              sx={{ color: "#fff", marginTop: size === 'small' ? "0px" : "20px" }}
              component="label"
            >
              Parcourir...
              <input
                type="file"
                accept={ext.map((type: any) => `.${type}`).join(",")}
                onChange={handleSelectFile}
                hidden
              />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FileDropZone;
