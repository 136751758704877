import React from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Checkbox
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ADMIN_SELLER } from "../../../api/ADMIN_SELLER";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import AutoCompleteAdresse from "../../ui/AutoCompleteAdresse";
function SellerManagement_Edit(props: any) {

  //-------- Add User Popup ---------
  const [OpenEditUserPopup, setOpenEditUserPopup] =
    React.useState<boolean>(false);

  const phoneReg =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  //formik
  const formikSchema = yup.object({
    name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `Prenom invalide`)
      .min(2, `Prenom trop court !`)
      .max(50, `Prenom trop long !`)
      .required(`Veuillez renseigner votre prenom`),
    family_name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-\/.]+$/, `Nom invalide`)
      .min(2, `Nom trop court !`)
      .max(50, `Nom trop long !`)
      .required(`Veuillez renseigner votre nom`),
    phone: yup
      .string()
      .matches(phoneReg, `Numéro invalide`)
      .required(`Veuillez renseigner votre numéro de téléphone`),
  });
  const ThisUser = props.localData?.find((user: any) => user.user_id === props?.rowInfos?.row.user_id)
  const formik = useFormik({
    initialValues: {
      name: ThisUser?.name || ``,
      family_name: ThisUser?.family_name || ``,
      phone: ThisUser?.phone || ``,
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
        ADMIN_SELLER.PUT_SELLER(props.rowInfos.row.user_id, {
        name: formValues.name,
        family_name: formValues.family_name,
        phone: formValues.phone,
        }).then((res) => {
        if (res) {
            props.setLocalData(
            props.localData.map((user: any) => user.user_id === props?.rowInfos?.row.user_id ? res.data : user)
            );
            // props.setLocalData(props.localData.concat(res.data.user));
            handleAddUserPopupClose();
            resetForm();
        } 
        });    
    },
  });

  const handleAddUserPopupClose = () => {
    formik.resetForm();
    setOpenEditUserPopup(false);
  };

  return (
    //header de user management
    <Box>
      <GridActionsCellItem
        onClick={() => setOpenEditUserPopup(true)}
        icon={<EditIcon />}
        label="Edit"
      />
      <Dialog open={OpenEditUserPopup}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ backgroundColor: "#f9f9f9" }}>
            Modifier un commercial
          </DialogTitle>
          <DialogContent
            sx={{
              width: `560px`,
              maxWidth: `calc(100% - 40px)`,
              padding: `20px 20px 20px 20px`,
              mt: 2,
              "& .MuiFormLabel-root:not(.Mui-focused, .Mui-error)": {
                color: "grey !important",
              },
            }}
          >
              <TextField
              margin="dense"
              fullWidth
              id="name"
              label="Prenom"
              name="name"
              autoComplete="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                  formik.touched.name && Boolean(formik.errors.name)
              }
              helperText={
                  (formik.touched.name && formik.errors.name?.toString()) 
              }
              />
              <TextField
              margin="dense"
              fullWidth
              id="family_name"
              label="Nom"
              name="family_name"
              autoComplete="family_name"
              value={formik.values.family_name}
              onChange={formik.handleChange}
              error={
                  formik.touched.family_name && Boolean(formik.errors.family_name)
              }
              helperText={
                  formik.touched.family_name && formik.errors.family_name?.toString()
              }
              />
              <TextField
                margin="dense"
                fullWidth
                id="phone"
                label="Téléphone"
                name="phone"
                autoComplete="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone?.toString()}
              />
          </DialogContent>
          <DialogActions
            sx={{
              padding: `10px 20px`,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Button
              sx={{
                color: `text.disabled`,
              }}
              onClick={handleAddUserPopupClose}
            >
              Annuler
            </Button>
            <Button
              type="submit"
              sx={{
                color: `#fff`,
              }}
              variant="contained"
              disableElevation
              onClick={() => null}
              autoFocus
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default SellerManagement_Edit;
