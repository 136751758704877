import { Box } from "@mui/material";

import Modify from "../../assets/modify.svg";

export const ModifySelections = ({ selectedRoof, selectedTile }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "6px",
      }}
    >
      <img src={Modify} alt='Modifier sélection' className='modify-img' />
      <p>
        {selectedRoof === "TERRACE"
          ? "Toit terrasse"
          : selectedRoof === "FLAT"
          ? "Toit plat"
          : "Toit incliné"}
        {selectedTile && `, ${selectedTile}`}{" "}
      </p>
    </Box>
  );
};
