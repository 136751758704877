import React from "react";
import { LinearProgress, Box, Typography } from "@mui/material";
export const ProgressBar = ({ numberStep, totalNumberSteps }: any) => {
  const progress = (numberStep / totalNumberSteps) * 100;

  return (
    <Box
      sx={{ height: "14px", width: 1, margin: "3px 0", position: "relative" }}
    >
      <LinearProgress
        variant='determinate'
        sx={{ height: 1, borderRadius: "20px", position: "relative" }}
        value={progress}
      />
      <Typography
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "13px",
        }}
      >
        {numberStep > 1 ? "Etapes" : "Etape"} {numberStep}/{totalNumberSteps}
      </Typography>
    </Box>
  );
};
