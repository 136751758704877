import { Box } from "@mui/material";
import { ModifySelections } from "./ModifySelections";
export const ReturnSelections = ({
  selectedRoof,
  selectedTile,
  selectionError,
  notAppliedText,
}: any) => {
  if (selectedRoof || (selectedRoof && selectedTile)) {
    return (
      <ModifySelections
        selectedRoof={selectedRoof}
        selectedTile={selectedTile}
      />
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: selectionError ? "red" : "",
          padding: "6px",
        }}
      >
        <p>{notAppliedText}</p>
      </Box>
    );
  }
};
