import React from "react";
import { Popover, Box, Typography } from "@mui/material/";
import PersonIcon from "@mui/icons-material/Person";
import { useFormik } from "formik";
import * as yup from "yup";
import { USERS } from "../../api/USER";
import { FormInformations } from "./FormInformations";
import { useQuery } from "react-query";
import jwt_decode from "jwt-decode";
export const ProfilePopOver = ({
  open,
  onClose,
  anchorEl,
  storedToken,
}: any) => {
  const [isSiretValid, setIsSiretValid] = React.useState(true);
  const [user, setUser] = React.useState<any>({});
  const [changed, setChanged] = React.useState(false);

  useQuery<any, Error>("getUserPopOver", async () => {
    try {
      if (localStorage.getItem("token")) {
        USERS.GET_USER().then((res: any) => {
          if (res?.message === "ok") {
            setUser(res.data);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  });
  const phoneReg =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formikSchema = yup.object({
    fonction: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-/.]+$/, `fonction invalide`)
      .min(2, `fonction trop courte !`)
      .max(100, `fonction trop longue !`)
      .required(`Veuillez renseigner votre fonction`),
    phone: yup
      .string()
      .matches(phoneReg, `Numéro invalide`)
      .required(`Veuillez renseigner votre numéro de téléphone`),
    siret: yup.string().matches(/^\d{14}$/, `Siret invalide`),
    name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-/.]+$/, `Prénom invalide`)
      .required("Veuillez saisir votre prénom"),
    family_name: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\s\-/.]+$/, `Nom de famille invalide`)
      .required("Veuillez saisir votre nom de famille"),
  });
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      fonction: user.fonction || ``,
      phone: user.phone || ``,
      siret: user.etablissement?.siret || ``,
      name: user.name || ``,
      family_name: user.family_name || ``,
      situation: user?.isPro ? "Professionnel" : "Particulier",
    },
    validationSchema: formikSchema,
    onSubmit: async (formValues, { resetForm }) => {
      if (!formValues.siret || formik.values.situation === "Particulier") {
        // S'il a été supprimer, on l'enlève
        const putUserRes = await USERS.PUT_USER({
          fonction: formValues.fonction,
          phone: formValues.phone,
          etablissement: {},
          name: formValues.name,
          family_name: formValues.family_name,
          isPro: false,
        });
        setUser(putUserRes.data);
        setChanged(true);
        resetForm();
      } else {
        const res = await USERS.GET_PROBYSIRET(formValues.siret);
        if (res) {
          setIsSiretValid(true);
          const putUserRes = await USERS.PUT_USER({
            fonction: formValues.fonction,
            phone: formValues.phone,
            etablissement: res.etablissement,
            name: formValues.name,
            family_name: formValues.family_name,
            isPro: true,
          });
          setUser(putUserRes.data);
          setChanged(true);
          resetForm();
        } else {
          setIsSiretValid(false);
        }
      }
    },
  });
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Box sx={{ width: "400px", margin: "30px" }}>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PersonIcon sx={{ marginRight: "10px", fontSize: "28px" }} />
            <Typography variant='h2'>Mes informations</Typography>
          </Box>
        </Box>
        <FormInformations
          formik={formik}
          isSiretValid={isSiretValid}
          changed={changed}
        />
      </Box>
    </Popover>
  );
};
