import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { StateMessage } from "../../ui/stateMessage";
import FileCard from "../../ui/FileCard";
import { USERS } from "../../../api/USER";
import DragNDrop from "../../ui/dragNdrop";
import { SignDocs } from "../../../utils/filesList";
import { DashBoardSkeleton } from "../../ui/Skeleton";

const SignDoc = ({ localData, setLocalData }: any) => {
  const [Files, setFiles] = useState(SignDocs);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const filesWithFileNameOnly = [...SignDocs].map(({ file_name }) => ({
      file_name,
    }));
    USERS.DOC_CHECK(localData.project._id, filesWithFileNameOnly).then(
      (res) => {
        if (res.data) {
          const updatedFiles = Files.map((file) => {
            const fileExist = res.data.find(
              (data: any) => data.file_name === file.file_name
            )?.exist;
            return { ...file, exist: fileExist };
          });
          setIsLoading(false);
          setFiles(updatedFiles);
        }
      }
    );
  }, []);

  if (!Files.some((file) => (file.type === "signed" ? !file.exist : false))) {
    USERS.PUT_PROJECT(localData.project._id, {
      step: 5,
    }).then((res) => {
      res && setLocalData({ ...localData, project: res.data });
    });
  }

  const handleFileSend = (file_name: string) => {
    setFiles((prevState) =>
      prevState.map((file) =>
        file.file_name === file_name ? { ...file, exist: true } : file
      )
    );
  };

  const isAllFilesSend = !Files.some((file) =>
    file.type === "raw" ? !file.exist : false
  );

  const FilesSend = () => {
    return (
      <StateMessage
        message={
          !isAllFilesSend
            ? "Les documents sont en cours de création, revenez un peu plus tard pour pouvoir les consulter."
            : "Veuillez télécharger et envoyez les documents signés."
        }
        quote={
          !isAllFilesSend
            ? "Nous prenons en général 1 à 2 jours ouvrés pour créer l'étude."
            : "Pour envoyer les documents, veuillez les glisser dans les champs prévu pour."
        }
        icon={!isAllFilesSend ? "waiting" : "upload"}
        component={
          isAllFilesSend && (
            <Box sx={{ display: "flex", gap: "20px" }}>
              <Box
                sx={{ display: "flex", gap: "20px", flexDirection: "column" }}
              >
                {Files.map(
                  (file, i1) =>
                    file.exist &&
                    file.type === "raw" && (
                      <Box key={i1}>
                        <FileCard
                          variant={"download"}
                          download={{
                            download_api: USERS.DOC_DOWNLOAD,
                            file_name: file.file_name,
                            project_id: localData.project._id,
                          }}
                          fileName={file.file_name}
                        />
                      </Box>
                    )
                )}
              </Box>
              <Box
                sx={{ display: "flex", gap: "20px", flexDirection: "column" }}
              >
                {Files.map(
                  (file, i1) =>
                    file.exist &&
                    file.type === "signed" && (
                      <Box key={i1}>
                        <FileCard
                          border={"2px"}
                          variant={"download"}
                          icon={"sign"}
                          download={{
                            download_api: USERS.DOC_DOWNLOAD,
                            file_name: file.file_name,
                            project_id: localData.project._id,
                          }}
                          fileName={file.file_name}
                        />
                      </Box>
                    )
                )}
              </Box>
            </Box>
          )
        }
      />
    );
  };
  return (
    <Box sx={{ padding: "20px" }} className={"wrapper card"}>
      <Typography
        variant='h2'
        component='h1'
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontSize: "30px",
          margin: "10px 0 10px 0",
        }}
      >
        Devis & Contrats
      </Typography>
      {isLoading ? (
        <DashBoardSkeleton />
      ) : (
        <Box>
          <FilesSend />
          {isAllFilesSend &&
            Files.map(
              (file, i1) =>
                !file.exist &&
                file.type === "signed" && (
                  <Box mb={"20px"} key={i1}>
                    <DragNDrop
                      ext={file.ext}
                      title={file.label}
                      size='small'
                      upload={{
                        upload_api: USERS.UPLOAD_DOC,
                        file_name: file.file_name,
                        project_id: localData.project._id,
                      }}
                      sendAction={handleFileSend}
                    />
                  </Box>
                )
            )}
        </Box>
      )}
    </Box>
  );
};

export default SignDoc;
