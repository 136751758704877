import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import UserProjectChoice from "../components/user/project/projectChoice";
import { ProjectChoice as AdminProjectChoice } from "../components/admin/projectChoice/ProjectChoice";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { ADMIN_USER } from "../api/ADMIN_USER";
import { USERS } from "../api/USER";
import NotFund from "./404";
import { Box } from "@mui/material";
function Projects() {
  const storedToken = localStorage.getItem("token");
  const userType = storedToken && jwt_decode<any>(storedToken)?.user?.role;
  const id = useParams();

  // Si l'utilisateur précise pas d'user, alors retour au dashboard
  if (id.user === undefined && userType === "ADMIN") {
    window.location.replace("/dashboard");
  }
  return (
    <Box
      className='page_redirect'
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {userType === "USER" && <NotFund />}
      {userType === "ADMIN" && <AdminProjectChoice />}
    </Box>
  );
}

export default Projects;
