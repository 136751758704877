import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Link,
  Checkbox,
} from "@mui/material";
import TooltipTextCopy from "../../ui/TooltipCopyText";
import { ReturnSelections } from "../../ui/ReturnSelections";
import { useFormik } from "formik";
import { ADMIN } from "../../../api/ADMIN";
import { ADMIN_SELLER } from "../../../api/ADMIN_SELLER";
import { ADMIN_USER } from "../../../api/ADMIN_USER";
import { RoofSelectionDialog } from "../../ui/RoofSelectionDialog";
import { TileSelectionDialog } from "../../ui/TileSelectionDialog";
import AutoCompleteAdresse from "../../ui/AutoCompleteAdresse";
import { ModifySelections } from "../../ui/ModifySelections";

const ErrorSelection = () => {
  return <p className='error'>Veuillez choisir un type de toiture</p>;
};

const MesInfos = ({ localData, setLocalData }: any) => {
  const [adresseValue, setAdresseValue] = React.useState(
    localData.project?.projectLocation
  );
  const [hasFoundation, setHasFoundation] = useState(
    localData?.project.project_type === "ombriere solaire avec fondation"
  );
  const [adresseError, setAdresseError] = React.useState(false);
  const [userIsLegal, setUserIsLegal] = React.useState(
    localData.project.userIsLegal
  );
  const [selectionError, setSelectionError] = React.useState(false);
  const [roof, setRoof] = React.useState({
    roofType: localData.project?.roof?.roofType,
    tileType: localData.project?.roof?.tileType,
    angle: localData.project?.roof?.angle,
  });
  const [errorSelector, setErrorSelector] = React.useState("");
  const [openRoofSelection, setOpenRoofSelection] = React.useState(false);
  const [openTileSelection, setOpenTileSelection] = React.useState(false);

  const formik: any = useFormik({
    initialValues: {},
    onSubmit: async () => {
      if (adresseValue === null) {
        setAdresseError(true);
        return;
      }
      if (
        roof.roofType === null &&
        localData?.project?.project_type === "toiture solaire"
      ) {
        setSelectionError(true);
        return;
      }
      if (
        roof.tileType === null &&
        localData?.project?.project_type === "toiture solaire"
      ) {
        setSelectionError(true);
        return;
      }
      if (
        localData?.project?.project_type ===
          "ombriere solaire avec fondation" ||
        localData?.project?.project_type === "ombriere solaire sans fondation"
      ) {
      }
      const putProjectRes = await ADMIN_USER.PUT_PROJECT(
        localData.project._id,
        {
          userIsLegal: userIsLegal,
          roof: roof,
          step: localData.project?.step,
          projectLocation: adresseValue,
          project_type: localData.project?.project_type,
        }
      );
      setAdresseValue(null);
      setLocalData({
        ...localData,
        project: putProjectRes.data,
      });
    },
  });
  const handleFoundationChange = (event: any) => {
    setHasFoundation(event.target.value);
    setLocalData({
      ...localData,
      project: {
        ...localData.project,
        project_type: event.target.value
          ? "ombriere solaire avec fondation"
          : "ombriere solaire sans fondation",
      },
    });
  };
  const handleAngleChange = (event: any) => {
    setRoof({ ...roof, angle: parseInt(event.target.value) });
  };
  const handleChangeUserIsLegal = (event: any) => {
    setUserIsLegal(event.target.value);
  };

  const toggleOpenRoofSelection = () => {
    setOpenRoofSelection((isOpen) => !isOpen);
  };
  const toggleOpenTileSelection = () => {
    setOpenTileSelection((isOpen) => !isOpen);
  };

  // Prends en paramètre l'instance de la popup ouvert, la sélection ainsi que l'éventuelle erreur à passer
  const handleClose = (
    itemSelected: any,
    setterPopup: any,
    errorMessage: any
  ) => {
    if (itemSelected !== "INCLINED") {
    }

    if (itemSelected !== null) {
      setterPopup(false);
      setErrorSelector("");
      return true;
    } else if (itemSelected !== "INCLINED") {
    } else {
      setErrorSelector(errorMessage);
      return false;
    }
  };
  return (
    <Box sx={{ padding: "20px" }} className={"wrapper card"}>
      <Typography
        variant='h2'
        component='h1'
        sx={{
          textAlign: "center",
          color: "secondary.main",
          fontSize: "30px",
          margin: "10px 0 30px 0",
        }}
      >
        Informations Générales
      </Typography>
      <Box
        component='form'
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 2 }}
      >
        <AutoCompleteAdresse
          adresseValue={adresseValue}
          setAdresseValue={setAdresseValue}
          adresseError={adresseError}
          setAdresseError={setAdresseError}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            alignItems: "center",
            marginTop: "20px",
            gap: { xs: "0px", sm: "0px", md: "20px" },
          }}
        >
          <Box sx={{ width: 1 }}>
            {localData?.project?.project_type === "toiture solaire" && (
              <Button
                variant='outlined'
                sx={{
                  width: 1,
                  borderColor: selectionError ? "red" : "",
                }}
                onClick={() => toggleOpenRoofSelection()}
              >
                <ModifySelections
                  selectedRoof={roof.roofType}
                  selectedTile={roof.tileType}
                />
              </Button>
            )}

            {selectionError && <ErrorSelection />}
          </Box>
          <RoofSelectionDialog
            roof={roof}
            setRoof={setRoof}
            setOpenTileSelection={setOpenTileSelection}
            errorSelector={errorSelector}
            setErrorSelector={setErrorSelector}
            handleClose={handleClose}
            open={openRoofSelection}
            onClose={toggleOpenRoofSelection}
            setOpenRoofSelection={setOpenRoofSelection}
            nextPopup={setOpenTileSelection}
          />
          <TileSelectionDialog
            setSelectionError={setSelectionError}
            setErrorSelector={setErrorSelector}
            handleClose={handleClose}
            roof={roof}
            setRoof={setRoof}
            errorSelector={errorSelector}
            open={openTileSelection}
            setOpenTileSelection={setOpenTileSelection}
            prevPopUp={setOpenRoofSelection}
            onClose={toggleOpenTileSelection}
          />

          {roof.roofType === "INCLINED" && (
            <TextField
              sx={{ height: "50px", marginTop: "0px" }}
              margin='dense'
              type='number'
              fullWidth
              id='roofAngle'
              label='Angle du toit'
              name='roofAngle'
              autoComplete='roofAngle'
              defaultValue={roof.angle}
              onChange={handleAngleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>Deg°</InputAdornment>
                ),
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "24px",
          }}
        >
          <Box sx={{ width: 1, display: "flex", gap: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id='legal-obligations-label'>
                L'utilisateur a rempli ses obligations légales ?
              </InputLabel>
              <Select
                sx={{ width: 1 }}
                labelId='legal-obligations-label'
                id='legal-obligations'
                name='legal-obligations'
                label="L'utilisateur a rempli ses obligations légales ?"
                value={userIsLegal}
                onChange={handleChangeUserIsLegal}
              >
                <MenuItem value={true as any}>Oui</MenuItem>
                <MenuItem value={false as any}>Non</MenuItem>
              </Select>
            </FormControl>

            {(localData?.project?.project_type ===
              "ombriere solaire sans fondation" ||
              localData?.project?.project_type ===
                "ombriere solaire avec fondation") && (
              <FormControl fullWidth>
                <InputLabel id='foundation-label'>
                  L'utilisateur a-t-il une ombrière avec fondation ?
                </InputLabel>
                <Select
                  sx={{ width: 1 }}
                  labelId='foundation-label'
                  id='foundation'
                  name='foundation'
                  label="L'utilisateur a-t-il une ombrière avec fondation ?"
                  value={hasFoundation}
                  onChange={handleFoundationChange}
                >
                  <MenuItem value={true as any}>Oui</MenuItem>
                  <MenuItem value={false as any}>Non</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
        </Box>

        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 4, mb: 1, color: "white", height: "55px" }}
        >
          Enregistrer
        </Button>
      </Box>
    </Box>
  );
};

export default MesInfos;
