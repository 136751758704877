import axios from 'axios';

export const SELLER = {
	GET_PROBYSIRET: async function (SIRET:string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/seller/BYSIRET`,
			params: {
				siret:SIRET,
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
		}).then(function (res:any) {
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	UPLOAD_DOC: async function handleSendFile(project_id: string, file_name: string, file: any){
		if(file){
			let formData = new FormData();
			formData.append('file', file);
			try {
			  await axios({
				method: 'post',
				url: `${process.env.REACT_APP_SERVER_URL}/api/seller/file/upload?project_id=${project_id}&file_name=${file_name}`,
				headers: {
				  'Content-Type': 'multipart/form-data',
				  'Authorization': `Bearer ${localStorage.getItem('token')}`
				},
				data: formData
			  });
			  return 'success'
			} catch (error) {
			  return 'error'
			}
		  }
		},

	DOC_CHECK: async function (project_id: string, file_name: any) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/seller/file/check`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			}
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
	DOC_DOWNLOAD: async function (project_id: string, file_name: string) {
		return (axios({
			method: 'get',
			url: `${process.env.REACT_APP_SERVER_URL}/api/seller/file/download`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			},
			responseType: 'blob',
		}).then(function (res:any) {						
			return res;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},

	DOC_DELETE: async function (project_id: string, file_name: string) {
		return (axios({
			method: 'delete',
			url: `${process.env.REACT_APP_SERVER_URL}/api/seller/file/delete`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${localStorage.getItem('token')}`
			},
			params : {
				project_id: project_id,
				file_name: file_name
			},
		}).then(function (res:any) {						
			return res.data;
		})).catch(function (error:any) {
			console.log(error);
			return null;
		});
	},
}
