/* eslint-disable jsx-a11y/alt-text */
import './etude.css';
import cover from './assets_energy/cover.jpeg';
import calendrier from './assets_energy/calendrier.png';
import energie from './assets_energy/energie.png';
import rge_icon from './assets_energy/rge.png';
import logo_rs_synerciel from './assets_energy/logo_rs_synerciel.png';
import {HomeMap, LocationMap} from './EtudeComponents/Map';
import {MyChart, MyChart2} from './EtudeComponents/Charts';
import DataTable from './EtudeComponents/DataTable';
import { memo } from "react";

const EtudeContent = ({EtudeData}: any) => {
    const {
        porteur_projet,
        date_text,
        power_text,
        adresse_text,
        text_entreprise,
        id_text,
        calpinage,
        simulate,
        monthly,
        perte,
        lat_lon,
        roofAngle,
        totals,
        subvention,
        etude_eco,
    } = EtudeData;
    const HeaderPages = ({
      index,
      title,
      lineHeight,
      padding,
      variant,
    }: any) => {
      return (
        <div className="header_pages">
          <h1
            style={{
              lineHeight: lineHeight ? `${lineHeight}` : "auto",
              padding: padding ? `0 0 0 ${padding}` : "auto",
            }}
          >
            {index}
            {index ? ". " : " "}
            {title}
          </h1>
          {variant === "mid" && <div id="header_pages_trapeze_blanc"></div>}
          {variant !== "mid" && (
            <div id="header_pages_titre_logo">
              <h2>KOVEA</h2>
            </div>
          )}
          {variant !== "mid" && <div id="header_pages_trapeze"></div>}
        </div>
      );
    };
    const FooterPages = ({ index, quote }: any) => {
      return (
        <div className="footer_pages">
          <h1 className="id_text" id="numero_id">
            {id_text || '0000000'}
          </h1>
          {quote && <p>{quote}</p>}
          <div className="pagination">
            <h1>
              <b style={{ color: "#32c36c" }}>KOVEA</b> &#160; &#160; &#160;
              {index}.
            </h1>
          </div>
        </div>
      );
    };

    return (
    <div id="pdf" style={{display: "block"}}>
        <div className="page">
            <div id="header">
                <div style={{display: 'flex'}} id="header_logo_kovea">
                    <div className="relative_cont">
                        <div id="trapeze">          
                        </div>
                    </div>
                    <div id='titre_marque'>
                        <h1>KOVEA</h1>
                    </div>
                </div>
                <img id='header_cover' src={cover}/>
                <div className="relative_cont">
                    <div id="trapeze2">          
                    </div>
                </div>
                <div id='titre_pdf'>
                    <h1>Etude de potentiel production et rentabilité photovoltaïque</h1>
                    <h4 id="porteur_projet">Porteur du projet : {porteur_projet}</h4>
                </div>
                <div id='first_infos_pdf'>
                    <div className="first_infos_cont">
                        <div className="info_icon_circle">
                            <img src={calendrier}/>
                        </div>
                        <div className="info_icon_cont_content">
                            <div className="info_icon_title">Date</div>
                            <div id="date_text" className="info_icon_text">{date_text || "--/--/----"}</div>
                        </div>
                    </div>
                    <div className="first_infos_cont">
                        <div className="info_icon_circle">
                            <img src={energie}/>
                        </div>
                        <div className="info_icon_cont_content">
                            <div className="info_icon_title">Puissance</div>
                            <div id="power_text" className="info_icon_text">{power_text || "----"}KWC</div>
                        </div>
                    </div>
                </div>
                <div id='adresse_pdf'>
                    <h2 id='adresse_text'>{adresse_text || "--"}</h2>
                </div>
                <div className="relative_cont">
                    <div id="trapeze3">          
                    </div>
                </div>
                {/* <div id="map"></div> */}
                <HomeMap location={lat_lon}/>
                <div id="first_footer">
                    <h1 className="id_text" id="numero_id">{id_text || '0000000'}</h1>
                    <div id="rge_icon">
                        <img src={rge_icon}/>
                    </div>
                </div>
            </div>
        </div>
        {/* sommaire  */}
        <div className="page" style={{position: 'relative', backgroundColor: 'white'}}>
            <div className="organize_page">
                <HeaderPages title={"Sommaire"} lineHeight={'140px'} padding={'60px'}/>
                <div className="page_content">
                    <div className="sommaire">
                        <div className="sommaire_line"><h1>1.</h1><h4>GLOSSAIRE</h4><div className="som_line"></div><div className="sommaire_page">Page 2</div></div>
                        <div className="sommaire_line"><h1>2.</h1><h4>INTRODUCTION AU PHOTOVOLTAÏQUE</h4><div className="som_line"></div><div className="sommaire_page">Page 3</div></div>
                        <div className="sommaire_line"><h1>3.</h1><h4>LA PRIME À L’AUTOCONSOMMATION</h4><div className="som_line"></div><div className="sommaire_page">Page 4</div></div>
                        <div className="sommaire_line"><h1>4.</h1><h4>ENGAGEMENTS</h4><div className="som_line"></div><div className="sommaire_page">Page 5</div></div>
                        <div className="sommaire_line"><h1>5.</h1><h4>LOCALISATION DU PROJET</h4><div className="som_line"></div><div className="sommaire_page">Page 6</div></div>
                        <div className="sommaire_line"><h1>6.</h1><h4>MONTAGE ET RACCORDEMENT</h4><div className="som_line"></div><div className="sommaire_page">Page 7</div></div>
                        <div className="sommaire_line"><h1>7.</h1><h4>SERVICES ET GARANTIES</h4><div className="som_line"></div><div className="sommaire_page">Page 7</div></div>
                        <div className="sommaire_line"><h1>8.</h1><h4>PRODUCTION ET CONSOMMATION</h4><div className="som_line"></div><div className="sommaire_page">Page 8</div></div>
                        <div className="sommaire_line"><h1>9.</h1><h4>RÉSULTATS</h4><div className="som_line"></div><div className="sommaire_page">Page 9</div></div>
                        <div className="sommaire_line"><h1>10.</h1><h4>BILAN ÉCONOMIQUE</h4><div className="som_line"></div><div className="sommaire_page">Page 10</div></div>
                        <div className="sommaire_line"><h1>11.</h1><h4>BILAN ÉCOLOGIQUE</h4><div className="som_line"></div><div className="sommaire_page">Page 11</div></div>
                        <div className="sommaire_line"><h1>12.</h1><h4>MAINTENANCE ET NETTOYAGE DES MODULES</h4><div className="som_line"></div><div className="sommaire_page">Page 12</div></div>
                        <div className="sommaire_line"><h1>13.</h1><h4>ASSURANCES</h4><div className="som_line"></div><div className="sommaire_page">Page 13</div></div>
                        <div className="sommaire_line"><h1>14.</h1><h4>LE RECYCLAGE DES PANNEAUX SOLAIRES PHOTOVOLTAÏQUES</h4><div className="som_line"></div><div className="sommaire_page">Page 14</div></div>
                    </div>  
                    <div className='text_cont' style={{marginTop: '-30px'}}>
                        <div className="text info_line">
                            <h2>Date de rédaction :</h2>
                            <p id="date_text2">{date_text || '00/00/0000'}</p>
                        </div>
                        <div className="text info_line">
                            <h2>Entreprise : </h2>
                            <p id="text_entreprise">{text_entreprise || '----'}</p>
                        </div>
                        <div className="text info_line">
                            <h2>Porteur du projet : </h2>
                            <p id="porteur_projet2">{porteur_projet || '----'}</p>
                        </div>
                        <div className="text info_line">
                            <h2>Rédaction :</h2>
                            <div>
                                <p>KOVEA Bureau d’études, 49 rue de Ponthieu, 75008 PARIS contact@kovea.fr</p>                   
                            </div>
                        </div>
                        <div className="text">
                            <p>Les informations contenues dans ce document revêtent un caractère confidentiel et ne sauraient être utilisées, reproduites, représentées, transmises ou divulguées sans l’accord préalable et explicite de <span className="nomMarqueCont"></span>.</p>
                            <p>Tout contrevenant à la dite règle de confidentialité s’expose à des sanctions.</p>
                        </div>
                    </div>
                </div>
                <FooterPages index={1} />
            </div>
        </div>

        {/* page 1 */}
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={1} title={"Glossaire"} lineHeight={'140px'} padding={'60px'}/>
                <div className="page_content">
                    <div className='text_cont glossaire'>
                        <div className="text_page ">
                            <ul>
                                <li>
                                  <h2>Productivité :</h2>
                                  <p>Énergie fournie en sortie de l'installation</p>
                                </li>
                                <li>
                                  <h2>Production consommée :</h2>
                                  <p>Part de la production de l’installation qui est absorbée par le site</p>
                                </li>
                                <li>
                                  <h2>Soutirage :</h2>
                                  <p>Part de la consommation qui n’est pas couverte par la production de l’installation</p>
                                </li>
                                <li>
                                  <h2>Surplus :</h2>
                                  <p>Part de la production de l'installation qui n’est pas absorbée par le site et qui est injecté sur le réseau</p>
                                </li>
                                <li>
                                  <h2>Taux d’autoconsommation :</h2>
                                  <p>Rapport entre la production consommée et la production totale de l’installation</p>
                                </li>
                                <li>
                                  <h2>Taux de compensation :</h2>
                                  <p>Rapport entre la production totale de l'installation et la consommation totale du site</p>
                                </li>
                                <li>
                                  <h2>Emission de GES évitées :</h2>
                                  <p>Sur la base d"un contenu carbone du kWh livré par ENEDIS de 54 gCO2eq/kWh</p>
                                </li>
                                <li>
                                  <h2>Investissement :</h2>
                                  <p>Estimation de de l’investissement du projet (raccordement, générateur PV, onduleurs PV, structure support, frais d’ingénierie & développement)</p>
                                </li>
                                <li>
                                  <h2>Économies Charges électricité :</h2>
                                  <p>Economies annuelles moyennes réalisées sur la facture d’électricité</p>
                                </li>
                                <li>
                                  <h2>Revenus de la vente du surplus :</h2>
                                  <p>Revenus annuels moyens provenant de l"injection du surplus sur le réseau de distribution public</p>
                                </li>
                                <li>
                                  <h2>Coût moyen pondéré kWh économisé :</h2>
                                  <p>Prix du kWh obtenu par l’installation photovoltaique</p>
                                </li>
                                <li>
                                  <h2>ROI :</h2>
                                  <p>Retour sur investissement</p>
                                </li>
                              </ul>
                        </div>
                    </div>
                </div>
                <FooterPages index={2} />
            </div>
        </div>

        {/* page 2 */}
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={2} title={"Introduction au photovoltaïque"} />
                <div className="page_content">
                    <div className='text_cont'>
                        <div className="text_page">
                            <p>Vous avez choisi de faire appel à nos conseils pour votre projet d’énergie solaire et nous vous en remercions.</p>
                            <p>Il est important de réfléchir aujourd'hui compte tenu de la crise énergétique, aux possibilités d'apport ou de soutien électrique pour votre entreprise ou votre maison.</p>
                            <p>Que votre démarche soit économique ou écologique.
                                Tout au long de ce dossier nous allons vous présenter les différentes étapes, les matériels et les acteurs qui vont intervenir dans l'analyse, l'élaboration, la construction et la mise en service de votre système photovoltaïque.
                                </p>
                            <p>Il faut savoir que tout générateur solaire photovoltaïque produit de l'énergie qui doit être analysée et quantifiée à travers un programme officiel, (reconnu notamment par les établissements financiers) calculant la productivité des panneaux simulés sur vos toitures, leurs orientations et surtout à votre adresse.
                            </p>
                            <p>A savoir également : Il existe 3 types de cellules composant les panneaux, le
                                monocristallin, le polycristallin et l 'amorphe.
                                Notre préférence va vers le Monocristallin qui offre une meilleure performance sur l'irradiation solaire. L'unité de mesure des panneaux est le Watt-crête ou Wc. C'est une unité de mesure qui permet de comparer les panneaux entre eux, c'est une référence calculée en laboratoire. Et non au M2.
                                Il est le véritable indice de comparaison des panneaux solaires et de leurs prix.
                                A ne pas confondre avec les kWh (Kilowattheure) qui est l’unité de mesure de production de l’électricité.
                                </p>
                            <p>Une fois que vos panneaux seront posés et raccordés à votre ou vos onduleurs,
                            </p>
                            <p>Qu'allez-vous faire de cette électricité verte produite ?
                            </p>
                            <p><b>Il y a trois possibilités :</b>
                            </p>
                            <h2>A - Vente totale</h2>
                            <p>Toute la production photovoltaïque est vendue, quelle que soit la consommation électrique
                                de votre maison.
                                </p>
                            <p>Vous continuez à acheter toute votre électricité.
                                Le tarif de l’électricité photovoltaïque vendu est fixé par un contrat de vente 20 ans signé avec EDF OA.
                            </p>
                            <p>
                                Les gains de ce type d'installations sont faciles à calculer sur les 20 ans du contrat avec EDF-OA car on peut estimer à 10% près la production annuelle. Et comme le prix du kWh est fixe et majoré sur indice INSEE, on peut facilement estimer le gain sur 20 ans.
                            </p>
                        </div>
                    </div>
                </div>
                <FooterPages index={3} />
            </div>
        </div>

        {/* page 3 */}  
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={2} title={"Introduction au photovoltaïque"} />
                <div className="page_content">
                    <div className='text_cont'>
                        <div className="text_page">
                            
                            <h2>
                                B - Autoconsommation AVEC Vente du surplus
                            </h2>
                            <p>Dans ce cas, une partie seulement de la production photovoltaïque est vendue et vous achetez votre électricité si vous avez besoin de soutirer au réseau en cas de besoin ou sous dimensionnement.</p>
                            <h2>C - Autoconsommation SANS Vente du surplus (sites isolés ou non)
                            </h2>
                            <p>On est dans un cas pratiquement identique à l"autoconsommation avec vente du surplus.</p>
                            <p><b>Les différences sont :</b>
                            </p>
                            <ul>
                                <li>L’électricité non consommée est « perdue » ou servira à être accumulée en batteries</li>
                                <li>Pas de contrat de vente mais une convention de raccordement avec Enedis (sauf sites isolés)</li>
                                <li>Possibilité de mettre des batteries pour le stockage.</li>
                            </ul>
                        </div>
                    </div>
                    <HeaderPages variant='mid' index={3} title={"La prime à l’autoconsommation"} lineHeight={'140px'} padding={'60px'}/>
                    <div className='text_cont'>
                        <div className="text_page">
                            <p>Il faut que soient indiquées les prestations suivantes :</p>
                            <ul>
                                <li><b>Déclaration des travaux :</b> Obligatoire dès que l'on installe des panneaux sur son toit ou autre.</li>
                                <li><b>Demande raccordement :</b> Obligatoire pour obtenir le contrat de vente</li>
                                <li><b>Convention raccordement :</b> Obligatoire dans le cas d"autoconsommation sans vente du surplus</li>
                                <li><b>Demande Consuel :</b> Permet d'obtenir la certification électrique de l’installation. Obligatoire même en autoconsommation sans vente du surplus</li>
                                <li><b>Demande de contrat avec EDF-OA :</b> Cela concerne l'ensemble des tâches
                                    administratives à effectuer.
                                    Sans cela : pas de contrat de vente d’électricité.
                                    Nous allons donc vous accompagner pour faire votre choix de revente d’énergie et dans toutes les démarches administratives auprès des services (obtention de l’autorisation et du contrat de raccordement ENEDIS), jusqu'à la mise en route de votre système et l'obtention de votre contrat de rachat avec EDF Obligation d'Achat. (EDF OA)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <FooterPages index={4} />
            </div>
        </div>

        {/* page 4 */}
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={4} title={"Engagements"} lineHeight={'140px'} padding={'60px'}/>
                <div className="page_content">
                    <div className='text_cont'>
                        <div className="text_page">
                            <h2>L'analyse :</h2>
                            <p>Le choix du matériel et de la solution de revente ou d'autoconsommation est effectué avec le plus grand soin en fonction de vos besoins et des résultats financiers. Ce qui nous permet de vous proposer un matériel performant et au meilleur rapport qualité/prix en fonction de la solution choisie.
                                Les services techniques mandatés par nos soins vérifient attentivement la conformité des performances pour chaque générateur.
                                Les garanties, assurances et la maintenance :
                                Nos partenaires veillent attentivement à la pérennité et au bon fonctionnement de ses générateurs photovoltaïques. Pour chacun des composants, <span className="nomMarqueCont"></span> apporte les meilleures garanties du marché à ses clients.
                                </p>
                            <h2>Les démarches administratives sont nécessaires pour :</h2>
                            <ul>
                                <li>Avoir le droit d'installer un système photovoltaïque (autorisation d'urbanisme)</li>
                                <li>Avoir le droit de raccorder un système photovoltaïque (déclaration ou demande de raccordement au réseau)</li>
                                <li>Bénéficier d'un contrat d'achat, le cas échéant.</li>
                            </ul>
                            <p>Nous réalisons l’ensemble des démarches administratives auprès des différents intervenants. Une délégation de pouvoir nous permet d'intervenir efficacement à votre place.</p>
                            <h2>Les travaux d"installation :</h2>
                            <p>Quelques soit la puissance du générateur à installer, <span className="nomMarqueCont"></span> délègue
                                systématiquement un conducteur de travaux expérimenté afin d'encadrer l’équipe formée, labellisée, RGE et confirmé QUALIPV , QUALIBAT.
                                </p>
                            <div className="image_cert_cont">
                                <img alt='logo synerciel' src={logo_rs_synerciel}/>
                                <img alt='logo rge' src={rge_icon}/>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterPages index={5} />
            </div>
        </div> 

        {/* page 5 */}  
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={5} title={"Localisation du projet"} lineHeight={'140px'} padding={'60px'}/>
                <div className="page_content">
                    <div className='text_cont'>
                        <div className="text_page">
                            <LocationMap location={lat_lon}/>
                            <div className="mini_table_contenaire">
                                <table className="mini_table">
                                    <thead>
                                        <tr><td >Puissance crête</td></tr>
                                    </thead>
                                    <tbody>
                                        <tr><td id="local_kwc">{power_text ||'--'} kWc</td></tr>
                                    </tbody>
                                </table>
                                <table className="mini_table">
                                    <thead>
                                        <tr><td>Latitude</td></tr>
                                    </thead>
                                    <tbody>
                                        <tr><td id="local_latt">{lat_lon.lat || '--,----'}</td></tr>
                                    </tbody>
                                </table>
                                <table className="mini_table">
                                    <thead>
                                        <tr><td>Longitude</td></tr>
                                    </thead>
                                    <tbody>
                                        <tr><td id="local_long">{lat_lon.lng || '--,----'}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                           { calpinage && 
                                <img id='photo_cont' alt='calpinage' src={calpinage}/>
                          }
                        </div>              
                    </div>
                </div>
                <FooterPages index={6} />
            </div>
        </div>

        {/* page 6 */}  
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={6} title={"Montage et raccordement"} lineHeight={'140px'} padding={'60px'}/>
                <div className="page_content">
                    <div className='text_cont'>
                        <div className="text_page">
                            <p style={{margin:"0"}}>Le prestataire choisi assure le montage et la mise en service de votre installation.</p>
                            <h2>
                                Les prestations assurées sont :
                            </h2>
                            <ul>
                                <li>Pose et raccordement d'une centrale photovoltaïque sur la toiture en sur imposition.</li>
                                <li>Suivi de raccordement au réseau domestique, tirage de câbles, chemin de câble, pose des équipements de protection, des onduleurs DC/AC et des panneaux solaires</li>
                                <li>Démarches administratives : attestation de conformité Consuel ou autre (visite) + déclaration</li>
                                <li>Suivi du dossier déposé chez ENEDIS</li>
                            </ul>
                            <h2 style={{marginTop: "5px"}}>
                                En détail :
                            </h2>
                            <ul>
                                <li>Assemblage et pose du générateur photovoltaïque
                                </li>
                                <li>Implantation des onduleurs 
                                </li>
                                <li>Raccordement au réseau domestique, tirage de câbles, chemin de câble,
                                    pose des équipements de protection, des onduleurs DC/AC et des panneaux solaires.
                                    </li>
                            </ul>
                            <p>
                                Le prestataire dispose de toutes les assurances responsabilité civile et décennale couvrant les risques liés à l"installation.  La demande de raccordement sera effectuée par vos conseils dûment  mandaté par vos soins.
                            </p>
                        </div>
                    </div>
                    <HeaderPages variant='mid' index={7} title={"Services et garanties"} lineHeight={'140px'} padding={'60px'}/>
                    <div className='text_cont' style={{marginTop: "0px"}}>
                        <div className="text_page">
                            <div className="double_col">
                                <div>
                                    <h2>Services :</h2>
                                    <ul>
                                        <li>Dossier administratif Entièrement pris en charge par vos conseils
                                        </li>
                                        <li>Dossier général, Rédaction, analyse, assistance et conseil
                                        </li>
                                        <li>Dossier technique Entièrement pris en charge par <span className="nomMarqueCont"></span> et effectué par le prestataire RGE                                    </li>
                                        <li>Maintenance monitorée
                                        </li>
                                        <li>Déclaration des travaux 
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h2>Garanties :</h2>
                                    <ul>
                                        <li>Modules 20 ans de Garantie conventionnelle, et 25 ans de Puissance garantie constructeur.</li>
                                        <li>Micro-onduleurs Garantie  20 ans garantie constructeur.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterPages index={7} />
            </div>
        </div>

        {/* page 7 */}
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={8} title={"Production et consommation"} lineHeight={'140px'} padding={'60px'}/>
                <div className="page_content">
                    <h2 className="mini_titre" style={{margin: '60px 60px 0px 60px'}}>Production et consommation énergétique mensuelle du système PV fixe :</h2>
                    <div id="chart_cont">
                        <div id="chart_cont_legende">Énergie PV (kWh)</div>
                        <div id="myChart_cont">
                        {simulate && <MyChart data={simulate} />}
                        </div>
                    </div>
                    <h2 className="mini_titre" style={{margin: '60px 60px 0px 60px'}}>Irradiation mensuelle sur plan fixe:
                    </h2>

                    <div id="chart_cont">
                        <div id="chart_cont_legende">Irridation sur plan (kWh/m2)</div>
                        <div id="myChart2_cont">
                        {simulate && <MyChart2 data={monthly} />}
                        </div>
                    </div>
                </div>
                <FooterPages index={7} />
            </div>
        </div>

        {/* page 8 */}
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={8} title={"Production et consommation"} lineHeight={'140px'} padding={'60px'}/>
                <div className="page_content">
                    <h2 className="mini_titre" style={{margin: '20px 60px 0px 60px'}}>Énergie PV et irradiation solaire mensuelle :</h2>
                    <div id="table_cont">
                        <table className="styled_table">
                            <thead>
                                <tr>
                                    <th>Mois</th>
                                    <th>E_m</th>
                                    <th>H(i)_m</th>
                                    <th>SD_m</th>
                                </tr>
                            </thead>
                            <DataTable data={monthly}/>
                        </table>
                        <div>
                            <div className="legend_infos">
                                <h3>E_m</h3>
                                <p>Production éléctrique moyenne mensuelle du système defini (kWh).</p>
                            </div>
                            <div className="legend_infos">
                                <h3>H(i)_m</h3>
                                <p>Montant total mensuel moyen de l'irridation globale reçue par metre carré sur les panneaux du système defini (kWh/m²).</p>
                            </div>
                            <div className="legend_infos">
                                <h3>SD_m</h3>
                                <p>Déviation standard de la production éléctrique mensuelle à cause de la variation interannuelle (kWh)</p>
                            </div>
                        </div>
                    </div>
                    <HeaderPages variant='mid' index={9} title={"Résultats"} lineHeight={'140px'} padding={'60px'}/>
                    <div id="table_cont" className="mini_table_cont_cont">
                        <div className='mini_table_cont'>
                            <h2 className="mini_titre" style={{margin: '0px 0px 0px 0px', fontSize: '19px'}}>Énergie PV et irradiation solaire mensuelle :</h2>
                            <table className="styled_table_2">
                                <tbody>
                                <tr>
                                    <td>Position</td><td id="lat_lon">{
                                    lat_lon.lat + ' ' + lat_lon.lng || '000.00 00.000'}</td>
                                </tr>
                                <tr>
                                    <td>Base de données</td><td>PVGIS-SARAH2</td>
                                </tr>
                                <tr>
                                    <td>Technologie PV</td><td>Silicium cristalin</td>
                                </tr>
                                <tr>
                                    <td>PV installée</td><td id="power_table">{power_text || '0.000'} KWC</td>
                                </tr>
                                <tr>
                                    <td>Pertes du système</td><td id="perte_text">{perte || '14'}%</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                       
                        <div className='mini_table_cont'>
                            <h2 className="mini_titre"  style={{margin: '0px 60px 0px 0px'}}>Énergie PV et irradiation solaire mensuelle :</h2>
                            <table className="styled_table_2">
                            <tbody>
                                <tr>
                                    <td>Angle d'inclinaison</td><td id="angle_text">{roofAngle || '30°'}</td>
                                </tr>
                                <tr>
                                    <td>Angle d'azimut</td><td>0°</td>
                                </tr>
                                <tr>
                                    <td>Production annuelle PV</td><td id="prod_annuel">{totals?.fixed['E_y'] || '0.00'} kWh</td>
                                </tr>
                                <tr>
                                    <td>Irradiation annuelle</td><td id="irradiation_annuel">{totals?.fixed['H(i)_y'] || '0.00'} kWh/m²</td>
                                </tr>
                                <tr>
                                    <td>Variabilité interannuelle</td><td id="variabilite_annuel">{totals?.fixed['SD_y'] || '0.00'} kWh</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Changement de production à cause de</td>
                                </tr>
                                <tr className="spe_td">
                                    <td >Angle d'incidence</td><td id="angle_incidence">{totals?.fixed['l_aoi'] || '0.00'}%</td>
                                </tr>
                                <tr className="spe_td">
                                    <td >Effets spectraux</td><td id="effets_spectraux">{totals?.fixed['l_spec'] || '0.00'}%</td>
                                </tr>
                                <tr className="spe_td">
                                    <td >Température et irradiance faible</td><td id="temp_irradiance">{totals?.fixed['l_tg'] || '0.00'}%</td>
                                </tr>

                                <tr>
                                    <td>Pertes totales</td><td id="perte_total">{totals?.fixed['l_total'] || '-20.28'}%</td>
                                </tr>
                                <tr id="cout_elec_cont">
                                    <td>Coût de l'électricité PV (par kWh)</td><td id="cout_elec">{totals?.fixed['LCOE_pv'] || '0.000'} par kWh</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <FooterPages index={8} />
            </div>
        </div>

        {/* page 9 */}                          
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={10} title={"Bilan économique"} lineHeight={'140px'} padding={'60px'}/>
                <div className="page_content">   
                    <div style={{marginBottom: "0"}}  id="table_cont" className="mini_table_cont_cont">
                        <div className='mini_table_cont'>
                            <h2 className="mini_titre" style={{margin: '0px 45px 0px 0px'}}>Bilan :</h2>
                            <table className="styled_table_2">
                                <tbody>
                                <tr id='investissement_cont'>
                                    <td>Investissement total</td><td id='investissement'>{simulate?.financialBilan?.total['TotalSystemcost'] || '----'} €</td>
                                </tr>
                                <tr id='economie_cont'>
                                    <td>Economie sur la factured’électricité sur                  
                                        20 ans
                                        </td><td id='economie'>{simulate?.financialBilan?.total['economie'] || '----'} €</td>
                                </tr>
                                {/* <!-- <tr id='surplus_cont'>
                                    <td>Revenus de la revente du surplus sur
                                        20 ans
                                        </td><td id='surplus'>----€</td>
                                </tr> --> */}
                                <tr id='prime_cont'>
                                    <td>Primes et Subventions</td><td id='prime'>{subvention || '----'} €</td>
                                </tr>
                                <tr id='prime_cont'>
                                    <td>Taux d'auto production</td><td id='autoProductionRate'>{simulate?.financialBilan?.total['autoProductionRate']*100 || '----'} %</td>
                                </tr>
                                <tr id='prime_cont'>
                                    <td>Taux d'auto consommation</td><td id='autoConsommationRate'>{simulate?.financialBilan?.total['autoConsommationRate']*100 || '----'} %</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                       
                        <div className='mini_table_cont'>
                            <h2 className="mini_titre" style={{margin: "0px 60px 0px 0px"}}>Indicateurs économiques :</h2>
                            <table className="styled_table_2">
                            <tbody>
                                <tr id='prix_achat_cont'>
                                    <td>Prix de revient KWH sur 20 ans</td><td  id='prix_achat'>{simulate?.financialBilan?.total['PriceKWH'] || '----'} €</td>
                                </tr>
                                <tr id="roi_cont">
                                    <td>ROI</td><td id='roi'>{simulate?.financialBilan?.total['ROI'] || '----'} €</td>
                                </tr>
                                <tr id="roi_cont">
                                    <td>Prix de revente</td><td id='sellPrice'>{simulate?.financialBilan?.total['sellPrice'] || '----'} KWH</td>
                                </tr>
                                <tr id="roi_cont">
                                    <td>Revente sur 20 ans</td><td id='SellSurplus'>{simulate?.financialBilan?.total['surplus'] || '----'} €</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{marginTop: '0'}} className='text_cont'>
                        <div className="text_page">
                            <h2 style={{marginTop: '0'}}>Et dans 20 ans ?</h2>
                            <p>Bien évidemment votre générateur sera toujours en fonction et il vous sera toujours possible d'utiliser l'Énergie produite, et certainement en revendre le surplus.</p>
                            <p>N'oubliez pas que vos panneaux solaire ont une garantie de fonctionnement
                                de 25 ans et généralement ils assurent une production sur 30 ans sans trop de
                                problèmes.
                                </p>
                            <p>Bon à savoir : Les entreprises financières proposant la location de toitures
                                programment d'ailleurs leurs analyses de rendement sur 30 ans !
                                </p>
                            <p>Puisque les tarifs sont fixés pour 20 ans, il est légitime de se demander ce qu'il adviendra de la vente de votre électricité solaire une fois cette période passée.</p>
                            <p>Il n'y aura pas moins de possibilités pour gagner de l’argent avec son installation photovoltaïque.
                                Conséquence directe de la croissance du photovoltaïque, le nombre de producteurs
                                d’électricité solaire augmente et la rencontre entre eux paraît évidente et productive !
                                Ainsi, plusieurs scénarios se profilent et vous pourrez bientôt vendre votre électricité à de nouveaux acheteurs, comme :
                                </p>
                            <ul style={{margin: "-15px 0"}}>
                                <li>De nouveaux fournisseurs d’énergies spécialisés dans les énergies
                                    renouvelables
                                    </li>
                                <li>Votre voisinage direct en favorisant l'autoconsommation collective
                                </li>
                                <li>Des acheteurs indépendants, grâce au développement des registres distribués qui permettent de revendre son électricité au niveau National et notamment grâce à la technologie Blockchain.
                                </li>
                            </ul>
                            <p>Ainsi, les tarifs fixés entre les différents partis pourront évoluer dans le temps et s'adapter aux demandes spécifiques.</p>
                            <p>Second point à prendre en compte : l’évolution du prix de l’électricité. Alors que le prix de l’électricité (majoritairement nucléaire) que vous achetez à votre
                                fournisseur est amenée à augmenter considérablement, l’électricité solaire que vous produirez restera toujours gratuite. Ainsi, en la consommant directement, vous ferez donc des économies de plus en plus importantes au fil du temps sur vos factures d’électricité.
                                </p>                                   
                        </div>
                    </div>
                </div>
                <FooterPages index={9} />
            </div>
        </div>

        {/* page 10 */}                     
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={11} title={"BILAN écologique"}  lineHeight={'140px'} padding={'60px'}/>
                <div className="page_content">   
                    <div className="text_cont">
                        <div className="text_page">
                            <h2>Les résultats environnementaux estimés correspondent aux indicateurs suivants :</h2>
                            <ul>
                                <li>L'empreinte carbone totale de l'installation photovoltaïque sur tout le cycle de vie</li>
                                <li>La quantité annuelle d'émissions de CO2 que votre installation photovoltaïque fait éviter par rapport à une production électrique issue du réseau
                                </li>
                                <li>Le temps de retour énergétique de votre installation photovoltaïque, c'est-à- dire le temps nécessaire à l’installation pour produire autant d’énergie qu'utilisée pour sa fabrication et son fonctionnement.
                                </li>
                            </ul>
                            <div className="space" style={{height: "50px"}}></div>
                            <div className="bubble_cont">
                                <div className="bubble" style={{width: "400px"}}>
                                    <div className="bubble_text"><p>Empreinte carbone sur le cycle de vie</p></div>
                                    <div  className="bubble_content"><p id='empreinteCarbone'>{etude_eco?.empreinteCarbone || '--,--'} g CO2 éq/kWh</p></div>
                                </div>
                                <div className="bubble" style={{width: "400px"}}>
                                    <div className="bubble_text"><p>Quantité annuelle de CO2 économisée</p></div>
                                    <div  className="bubble_content"><p id='co2Economise'>{etude_eco?.co2Economise || '----'} g CO2 éq/an</p></div>
                                </div>
                            </div>
                            <div className="bubble_cont">
                                <div className="bubble" style={{width: "400px"}}>
                                    <div className="bubble_text"><p>Temps de retour énergétique  
                                        (EPBT - Energy Payback Time) </p></div>
                                    <div  className="bubble_content"><p id='EPBT'>{etude_eco?.EPBT || '----'} ans</p></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <FooterPages index={10} />
                 </div>
        </div>

        {/* page 11 */}
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={12} title={"MAINTENANCE ET NETTOYAGE DES MODULES"}/>
                <div className="page_content">   
                    <div className='text_cont' style={{marginTop: "30px"}}>
                        <div className="text_page">
                            <p>La maintenance préventive est une visite technique de l'installation photovoltaïque réalisée par un professionnel du photovoltaïque.
                            </p>              
                            <p>Toute intervention de maintenance doit respecter les consignes de sécurité énumérées dans contrat de maintenance fourni au dossier.
                                Le mainteneur doit disposer de toutes les habilitations nécessaires aux interventions sur des installations photovoltaïques (travail en hauteur, installations électriques en courant continu et courant alternatif...).
                                </p>              
                            <p>Il doit aussi disposer d'une garantie décennale dans le cas des installations intégrées au bâti, car dès qu'il touche aux panneaux photovoltaïques, il touche à l'étanchéité du bâtiment et c'est sa garantie décennale qui entre alors en jeu.</p>              
                            <p>La fréquence des opérations de maintenance préventive est annuelle voire pluriannuelle pour les parcs photovoltaïques de grande puissance, tandis qu'elles ne sont réalisées qu'une fois tous les 1 à 10 ans pour les petits systèmes en toiture.
                            </p>              
                            <h2 style={{marginTop: "10px"}}>POUR LES INSTALLATIONS DE PUISSANCE ≤ 36 KWC, AVEC LE PRODUCTEUR PRÉSENT SUR LE SITE :</h2>              
                            <p>Il est recommandé d'effectuer une intervention au bout de 3 à 4 ans de fonctionnement pour resserrer les vis de raccordement des câbles et vérifier qu'il n'y a aucun échauffement dans les coffrets de raccordement.
                            </p>              
                            <p>Par la suite, une visite peut se programmer à une fréquence variable (de 1 à 10 ans) selon les besoins du site et le contrat éventuellement mis en place avec le mainteneur. Cette intervention ne doit pas coûter plus de 10% du revenu annuel généré par l'installation.
                            </p> 
                            <p><b>pour les installations supérieures à 36 kWC et/ou avec un producteur non présent sur le site et/ou avec plusieurs installations</b> Il convient de mettre en place un contrat de maintenance préventive annuelle avec le détail de toutes les vérifications à effectuer et de consigner les rapports d'interventions</p>  
                            <h2 style={{marginTop: "10px"}}>Les différents points de vérifications et de mesures sont :</h2>       
                            <ul className="li_no_marge">
                                <li>Vérification visuelle du champ photovoltaïque et des câbles
                                </li>
                                <li>Relevé de mesures Strings : Vco, Impp, Riso (Résistance d’isolement)
                                </li>
                                <li>Vérification visuelle des onduleurs et des filtres de ventilation
                                </li>
                                <li>Vérification des connexions de fixation et de serrage des câbles AC et DC, à l'intérieur des placards techniques onduleurs conformément aux consignes de sécurité du fournisseur
                                </li>
                                <li>Contrôle des organes de sécurité (incendie, EPI, BAES, BAPI, etc.)
                                </li>
                                <li>Nettoyage des aérations (VB et VH) des locaux techniques
                                </li>
                                <li>Dépoussiérage des locaux techniques
                                </li>
                                <li>Réparation ou changement de tout élément défectueux (câble, bornier, fusible...)
                                </li>
                                <li>Vérification de la conformité des alentours des installations
                                </li>
                            </ul>              
                        </div>
                    </div>
                </div>
                <FooterPages index={12} />
              </div>
        </div>

        {/* page 12 */}
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={12} title={"MAINTENANCE ET NETTOYAGE DES MODULES"}/>
                <div className="page_content">   
                    <div className='text_cont' style={{marginTop: "30px"}}>
                        <div className="text_page">
                                <h2>
                                    UN NETTOYAGE DES PANNEAUX NON SYSTÉMATIQUE
                                </h2>
                                <p>
                                    Avant de déterminer une périodicité fixe de nettoyage, il est préférable d'observer le niveau de salissure des panneaux les deux premières années pour évaluer la fréquence et la nécessité des opérations de nettoyage.
Le nettoyage des modules dépend en effet beaucoup de l'environnement et de l'inclinaison du système.
                                </p>
                                <p>
                                    On peut dire qu'un champ incliné à plus de 15° pourra être nettoyé par les intempéries s'il se situe dans un environnement peu poussiéreux et agressif. Dans ce cas, le nettoyage n'a pas besoin d'être planifié et pourra avoir lieu uniquement si l'on constate un état de surface sale (accumulation de poussières, déjections d'oiseaux...).
                                </p>
                                <h2>Consignes à suivre en cas de nettoyage des modules</h2>
                                <p>Le nettoyage des modules peut se faire sans monter sur le toit et doit être réalisé lorsque les panneaux sont froids (s'ils sont trop chauds, l'eau risque de s'évaporer trop rapidement, ne pouvant alors agir pour nettoyer tout en laissant les minéraux qu'elle contenait sur la surface) de la façon suivante :
                                </p>
                                <ul>
                                    <li>Utiliser une perche télescopique avec une éponge douce ou microfibre                                    </li>
                                    <li>Privilégier une eau tiède faiblement concentrée en calcaire (afin d’éviter les traces), sans
                                        détergents ou solvants
                                        </li>
                                    <li>Éviter les jets à haute pression afin de ne pas endommager l'installation</li>
                                    <li>Ne pas gratter les panneaux en cas de tache tenace : privilégier une raclette en mousse.
                                    </li>
                                </ul>
                                {/* <!-- <p style="margin-top: 240px;">
                                    Cette étude a pour but de vous présenter de manière objective le potentiel relatif à la production d'énergie et  à la rentabilité d' une installation photovoltaique. 
Nous utilisons pour ce faire des instrument officiels tel que le PVGIS que nous complétons par <a style="color: rgb(86, 86, 86);" href="https://monsimulateur.org">monsimulateur.org</a> afin d'obtenir une analyse la plus conforme possible. 
Bien entendu une production optimale ne pourrait être réalisée que sur une installation exposée plein sud agréé d'un dégagement total de la surface des panneaux photovoltaiques. 
Il est donc utile de prendre en compte que le résultat de cette étude exclut tout problème d' ombrage et que son impacte  minimise le bilan de production certe  pas en totalité mais  en fonction de l'exposition de la toiture.
                                </p> --> */}
                        </div>
                    </div>
                    <HeaderPages index={13} title={"ASSURANCES"} variant={'mid'} lineHeight={'140px'} padding={'60px'}/>
                    <div className='text_cont' style={{marginTop: "30px"}}>
                        <div className="text_page">
                            <h2>
                                ASSURANCE RESPONSABILITÉ CIVILE
                            </h2>
                            <p>
                                Parce que votre installation photovoltaïque est connectée à un réseau public de distribution qui peut « transporter » en dehors de chez vous un risque d'accident lié à votre activité de production d’électricité, il vous faut être couvert par une assurance responsabilité civile. C’est ce que vous demande le gestionnaire du réseau (ENEDIS, EDFSEI ou l'ELD) dans son contrat de raccordement. Cette assurance est obligatoire, l’attestation fournie doit mentionner explicitement la responsabilité civile de l’activité de production d’électricité par panneaux photovoltaïques. Elle est applicable en phase d'exploitation de votre système PV.
                            </p>
                        </div>
                    </div>
                </div>
                <FooterPages index={13} />
            </div>
        </div>

        {/* page 13 */}
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={14} title={"LE RECYCLAGE DES PANNEAUX SOLAIRES PHOTOVOLTAÏQUES"} />
                <div className="page_content">   
                    <div className='text_cont' style={{marginTop: "30px"}}>
                        <div className="text_page">
                                <p>
                                    Le recyclage des panneaux solaires est un enjeu majeur pour la filière du photovoltaïque en France. La loi sert de référence en matière d’installation et de reprise des panneaux solaires.
                                </p>
                                <p>
                                    Qu’impose-t-elle, dans la filière, en matière de recyclage ? De quoi se compose précisément un panneau solaire photovoltaïque ? L'ensemble de ses matériaux peuvent-ils prétendre à une seconde vie et si oui, qui s"en charge ? Notre guide pour tout comprendre sur les actions menées pour favoriser l’économie circulaire de l’énergie solaire photovoltaïque en France.
                                </p>
                                <h2>
                                    Un panneau solaire photovoltaïque, c’est quoi ?
                                </h2>
                                <p>
                                    Un panneau solaire photovoltaïque est un maillage complexe de plusieurs éléments qui, assemblés, se nomme « module photovoltaïque ». La mission principale de ces panneaux est de produire de l’électricité grâce au soleil ou à la lumière. Si l’on regarde de plus près un panneau solaire photovoltaïque,
                                </p>
                                <p>
                                    On observe :
                                </p>
                                <ul>
                                    <li>
                                        Un cadre métallique en aluminium qui assure la bonne solidité de la structure.
                                    </li>
                                    <li>
                                        Des câbles de sorties.
                                    </li>
                                    <li>
                                        Une jointure étanche en caoutchouc pour bloquer l"infiltration de l’eau de pluie.
                                    </li>
                                    <li>
                                        Une plaque de verre solide qui protège l’intérieur du panneau à l’avant.
                                    </li>
                                    <li>
                                        Une membrane en Tedlar pour protéger la face arrière.
                                    </li>
                                    <li>
                                        Une boite de raccordement des câbles.
Et plus spécifiquement, à l’intérieur du panneau se trouvent : Une matrice cellulaire composée de cellules photovoltaïques, qui captent la lumière, de diodes, qui régulent le courant transmis par les cellules et d"un conducteur, qui porte le courant continu ; Une feuille de fond en poly fluorure de vinyle, qui a un rôle de protection aux propriétés chimiques, électriques et mécaniques ; Un boîtier de dérivation en plastique qui lie les fils électriques entre eux et agit contre la surintensité du courant.
                                    </li>
                                </ul>
                                <p>
                                    Des connecteurs qui agissent comme une grosse pile : branchés à l’onduleur, l’un est la borne positive et l’autre est la borne négative pour activer le courant continu du module photovoltaïque.
                                </p>
                                <h2>
                                    Qu'est-ce qu"une cellule photovoltaïque ? On vous dit tout ici.
                                </h2>
                                <p>
                                    On trouve donc différents types de matériaux : du verre, principalement, de l'aluminium, des composites, du silicium, du cuivre et de l"argent. Chaque matière utilisée répond alors à des techniques de recyclage propres. 
                                </p>
                                <p>
                                    Source : https://www.soren.eco/
                                </p>                          
                        </div>
                    </div>               
                </div>
                <FooterPages index={14} />
            </div>
        </div>

        {/* page 14 */}
        <div className="page">
            <div className="organize_page">
                <HeaderPages index={14} title={"Le recyclage des panneaux solaires photovoltaïques"}/>
                <div className="page_content">   
                    <div className='text_cont' style={{marginTop: "0px"}}>
                        <div className="text_page">
                            <h2>
                                Recyclage des panneaux solaires : ce que dit la loi
                            </h2>           
                            <p>
                                L’installation des panneaux solaires photovoltaïques en France est soumise à de nombreuses réglementations: respect des règles d’urbanisme, mise en place par un professionnel reconnu du secteur, demande d’un permis de construire selon la puissance des panneaux installés...
                            </p>           
                            <p>
                                Mais l’entreprise qui installe des panneaux chez vous est elle aussi tenue à des règles strictes. L’article 11 de l’arrêté du 9 mai 2017 fixe des conditions d'achat de l’électricité des installations produisant de l’énergie solaire photovoltaïque. Il prévoit en effet que « le producteur est tenu de récupérer les éléments de son installation (système photovoltaïque et éléments assurant la transmission et la transformation du courant électrique) lors du démantèlement et à les confier à un organisme spécialisé dans le recyclage de ces dispositifs. Le cas échéant, il acquitte les frais de recyclage demandés par cet organisme. »
                            </p>           
                            <p>
                                L’objectif des opérations de traitement consiste à séparer les différentes fractions de matériaux composant les panneaux photovoltaïques, afin de les réinjecter dans le circuit productif, et constituer une véritable économie circulaire.
                            </p>           
                            <h2 style={{marginTop: "0px"}}>
                                Qui se charge de recycler les panneaux photovoltaïques en France ? !
                            </h2>           
                            <p>
                                L’entreprise SOREN, anciennement appelée PV Cycle, assure le traitement des panneaux solaires photovoltaïques en fin de vie en France. Elle collecte, traite et valorise ensuite les matériaux pour les réutiliser à hauteur de 94%. Cette démarche répond à une exigence environnementale et à des enjeux de proximité territoriale avec les producteurs de la filière photovoltaïque. Cela a été rendu possible grâce à Véolia qui, en 2018, a ouvert un centre de traitement de valorisation des déchets issus des panneaux solaires. Avant cela, seul le verre des panneaux solaires installés en France pouvait avoir une seconde vie, soit 70% du produit final. Aujourd’hui, l'entreprise SOREN s'accompagne de trois acteurs pour cette collecte et revalorisation des panneaux : Galloo, ENVIE 2E Aquitaine et ENVIE 2E Midi-Pyrénées
                            </p>           
                            <p>
                                Pour les professionnels disposant de plus de 40 panneaux solaires photovoltaïques, il est nécessaire de réaliser une demande d’enlèvement en ligne sur soren.eco. Si votre installation comporte moins de 40 panneaux, il faudra alors les déposer dans un point de collecte agréé.
                            </p>           
                            <p>
                                Une fois récupérés, les panneaux sont séparés de leur cadre aluminium et de leur boîtier de jonction, puis broyés afin d"obtenir des fractions. Les matériaux sont ensuite triés à l’aide de différentes méthodes : vibration, tamisage, courant de Foucault, tri optique...
                            </p>           
                            <p>
                                Sont alors triés et séparés : Le verre,l’aluminium, les plastiques et les métaux conducteurs.94% des matières premières récoltées seront alors réutilisées pour de nouveaux usages.
                            </p>           
                            <p>
                                En 2021, ce sont en tout 3 463 tonnes de panneaux photovoltaïques qui ont pu être collectés par ce système de revalorisation.
                            </p>           
                        </div>
                    </div>               
                </div>
                <FooterPages index={3} quote={'Les données officielles en vigueur : source PVGIS'}/>
            </div>
        </div>
       </div>
    )
}

export default memo(EtudeContent);