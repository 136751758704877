import React from "react";
import { Popover, Typography, Box, Link } from "@mui/material";
const TipPopOver = ({ open, onClose, anchorEl }: any) => {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ padding: "20px", maxWidth: "450px" }}>
        {/* <Typography variant='h2'>truc machin</Typography> */}
        <Typography>
          <Link>Le décret tertiaire</Link>, aussi appelé décret de rénovation
          tertiaire, détaille les modalités d'application de l'article 17 paru
          dans la Loi de Transition énergétique pour les bâtiments à usage
          tertiaire.
        </Typography>
        <Typography sx={{ paddingTop: "10px" }}>
          Ainsi, <Link>le décret de rénovation tertiaire</Link> prévoyait une
          réduction des consommations de 25 % pour les bâtiments tertiaires
          avant 2020 et de 40 % à horizon 2030.
        </Typography>
        <Typography sx={{ paddingTop: "10px" }}>
          <Link>La loi ENR</Link> impose aux parcs de stationnement extérieurs
          d’une superficie supérieur à 1500m2 l’équipement en ombrière sur au
          moins la moitié de cette superficie intégrant un procédé de production
          d’énergies renouvelables sur la totalité de leur partie.
        </Typography>
      </Box>
    </Popover>
  );
};

export default TipPopOver;
