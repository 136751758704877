import "./main.css";
import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import ParallaxBox from "../components/ui/parallaxBox";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
function Main() {
  /*   -------------------- socket state ------------------------  */
  const LayerHeaderText = () => {
    return (
      <div className='parallax-wrapper-content'>
        <div>
          <h2>Optimum Solar</h2>
          <p className='parallax-content'>
            Cartographiez votre besoin en énergie photovoltaïque par rapport à
            vos factures d'électricité sur les fonciers concernés, regroupé
            l’ensemble du dossier administratif, technique et financier sur une
            seule et même plateforme.
          </p>
        </div>
      </div>
    );
  };
  const LayerMockupText = () => {
    return (
      <div className='parallax-wrapper-content'>
        <p className='parallax-content'>
          Optimisez la gestion de vos projets en élaborant un plan solide et
          efficace. Avec Optimum Solar, développez une stratégie qui combine une
          efficacité énergétique élevée et une rentabilité financière en
          anticipant les coûts opérationnels ainsi que les aides éligibles pour
          chaque opération.
        </p>
      </div>
    );
  };

  const steps = [
    {
      label: "Qualification de votre projet",
      description:
        "blabla blablablabla blablablabla blablablabla blablablabla blablablabla blabla",
      step: 1,
    },
    {
      label: "Etude de votre projet",
      description:
        "blabla blablablabla blablablabla blablablabla blablablabla blablablabla blabla",
      step: 1,
    },
    {
      label: "Réalisation de votre projet",
      description:
        "blabla blablablabla blablablabla blablablabla blablablabla blablablabla blabla",
      step: 1,
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <main>
          <ParallaxBox
            imgUrl={"soren-h-omfN1pW-n2Y-unsplash.jpg"}
            child={<LayerHeaderText />}
          />
          <Box className='parallax-content-section' sx={{ maxHeight: "100vh" }}>
            {/*<Box sx={{ height: "100vh" }}>
              <h2>Etapes du projet</h2>
              <Box
                sx={{
                  width: 1,
                  height: 1,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "space-evenly",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "85px",
                  }}
                  className='item-left'
                >
                <AutoStoriesIcon sx={{ fontSize: "65px" }} />
                  <Box>
                    <Box>
                      <Typography variant='h2'>1. Step one</Typography>
                      <ul>
                        <li>
                          Lorem ipsum dolor sit, amet consectetur adipisicing
                          elit. Repudiandae
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui eaque animi
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui eaque animi
                        </li>
                      </ul>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: -90,
                          right: 0,
                        }}
                      ></Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "50px",
                  }}
                  className='item-right'
                >
                  <AutoStoriesIcon sx={{ fontSize: "65px" }} />
                  <Box>
                    <Box>
                      <Typography variant='h2'>2. Step two</Typography>
                      <ul>
                        <li>
                          Lorem ipsum dolor sit, amet consectetur adipisicing
                          elit. Repudiandae
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui eaque animi
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui eaque animi
                        </li>
                      </ul>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "50px",
                  }}
                  className='item-left'
                >
                  <AutoStoriesIcon sx={{ fontSize: "65px" }} />
                  <Box>
                    <Box>
                      <Typography variant='h2'>3. Step three</Typography>
                      <ul>
                        <li>
                          Lorem ipsum dolor sit, amet consectetur adipisicing
                          elit. Repudiandae
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui eaque animi
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Qui eaque animi
                        </li>
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>*/}
          </Box>
          {/* Mockup à mettre ici */}
          <ParallaxBox
            imgUrl={"soren-h-omfN1pW-n2Y-unsplash.jpg"}
            child={<LayerMockupText />}
          />
          <section className='parallax-content-section'>
            <div>
              <h2>Votre plateforme de pilotage de projets photovoltaïque</h2>
              <p>
                Regroupez la gestion de votre projet pour mieux le contrôler en
                utilisant une interface de gestion intégrée qui couvre toutes
                les étapes nécessaires à la gestion de projets photovoltaïques,
                y compris l'audit, la structuration technique et financière.
                Vous pouvez ainsi identifier si votre projet est rentable et
                maximiser l'efficacité de celui-ci.
              </p>
            </div>
          </section>
        </main>
      </Box>
    </>
  );
}

export default Main;
